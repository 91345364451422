/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducersapp accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const PROMOTIONS = 'PROMOTIONS';
export const MORE_PROMOTIONS = 'MORE_PROMOTIONS';
export const CLIENT = 'CLIENT';
export const CLEAN_CLIENT = 'CLEAN_CLIENT';
export const DNI_CLIENT = 'DNI_CLIENT';
export const SELECT_PROMOTION = 'SELECT_PROMOTION';
export const EDIT_PROMOTION = 'EDIT_PROMOTION';
export const SET_CLIENT_PROGRAM = 'SET_CLIENT_PROGRAM';

export const USER_PROGRAM = 'USER_PROGRAM';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const PROMOTIONS_CLEAN = 'PROMOTIONS_CLEAN';
export const APPLIED = 'APPLIED';
export const APPLIED_POINTS = 'APPLIED_POINTS';
export const STAMP_PROGRAM_PROMOTION = 'STAMP_PROGRAM_PROMOTION';
export const SEARCH_PROGRAM = 'SEARCH_PROGRAM';
export const CLEAN_PROGRAM = 'SEARCH_PROGRAM';
export const MESSAGE = 'MESSAGE';
export const CLEAN_APPLIED = 'CLEAN_APPLIED';

export const GET_PLACES = 'GET_PLACES';
export const SELECT_PLACE = 'SELECT_PLACE';


export const SET_CURRENT_COMPANY_UID_PLACES = 'SET_CURRENT_COMPANY_UID_PLACES';
export const SET_CURRENT_SEARCH_TERM_PLACES = 'SET_CURRENT_SEARCH_TERM_PLACES';
export const SET_FIRST_PAGE_PLACES = 'SET_FIRST_PAGE_PLACES';
export const SET_NUMBER_PAGE_PLACES = 'SET_NUMBER_PAGE_PLACES';


