/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import logo from "../../images/logo.png";
import { Whitelabel } from "../../utils/generic";
import "./style.scss";
import { getPlaces, setDataLocal } from "./actions";
import { getPrograms } from "../Bederr/actions";
import { selectPlace } from "../ListPlaces/actions";
import Redemption from "../../images/Redemption.svg";
import Arrow from "../../images/Arrow";
import Manual from "../../images/Manual.svg";
import ArrowMenu from "../../images/ArrowMenu";
import Hamburger from "../../images/hamburger.png";
import { Fragment } from "react";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "VALIDADOR",
      place: null,
      uidPlace: "",
      menuIcon: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getPlaces());
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { places, dataLocalArray } = nextProps;
    let uidPlace = localStorage.getItem("placeIdRedeem");
    if (uidPlace !== null) {
      if (this.state.uidPlace !== uidPlace) {
        this.setPlace(uidPlace);
      }
    }
    if (dataLocalArray !== null) {
      for (let i = 0; i < dataLocalArray.length; i++) {
        if (
          dataLocalArray[i].company.uid === localStorage.getItem("companyUid")
        ) {
          this.props.dispatch(setDataLocal(dataLocalArray[i]));
          break;
        }
      }
    }
    if (places.length !== 0 && this.state.uidPlace === "") {
      this.setPlace(places[0].uid, places[0].address);
    }
  }

  changePlace(e, placeId, address) {
    e.preventDefault();
    //this.props.dispatch(getPlace(placeId));
    this.setPlace(placeId, address);
  }

  closeSession = async (event) => {
    event.preventDefault();
    const NAME = Whitelabel.name;
    window.localStorage.removeItem(`token${NAME}`);
    window.localStorage.removeItem("placeIdRedeem");
    window.localStorage.removeItem("placeAddressRedeem");
    window.localStorage.removeItem("companyUid");
    window.localStorage.removeItem("companyIdRedeem");
    window.localStorage.removeItem("subdomain");
    window.localStorage.clear();
    document.location.href = "/";
  };

  setPlace(uidPlace, address) {
    this.setState({ uidPlace: uidPlace });
    window.localStorage.setItem("placeIdRedeem", uidPlace);
    if (address !== undefined)
      this.props.dispatch(selectPlace(uidPlace, address));
    this.props.dispatch(getPrograms(uidPlace));
  }

  renderPlaces() {
    const { places, isFetching } = this.props;
    if (places != null && places.length > 0) {
      return places.map(
        (place, index) =>
          index < 5 && (
            <button
              key={`place${index}`}
              onClick={(e) => this.changePlace(e, place.uid, place.address)}
              className="dropdown-item dropdown-type select-type select-place"
            >
              {place.address}
            </button>
          )
      );
    }
    if (isFetching) {
      return <p> Cargando...</p>;
    }
    return <p> No se encontraron locales </p>;
  }

  render() {
    const { history } = this.props;
    //console.log(this.props,"main");
    return (
      <Fragment>
        <nav className="navbar-main">
          <div
            className="mobile-menu-nav"
            onClick={() => this.setState({ menuIcon: !this.state.menuIcon })}
          >
            <img src={Hamburger} alt="mobile" />
          </div>
          <div className="logo-places">
            <a href="/">
              <img src={logo} className="logo-img" alt="logo" />
            </a>
            <div className="places-container">
              <div className="menu-dropdown">
                <div
                  className="form-control"
                  id="places"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div>
                    <h6>Escoger local</h6>
                    <h5>
                      {localStorage.getItem("placeAddressRedeem") &&
                        localStorage.getItem("placeAddressRedeem")}
                      {localStorage.getItem("placeAddressRedeem") === null &&
                        "Seleccionar Local"}
                    </h5>
                  </div>
                  <div>
                    <Arrow />
                  </div>
                </div>
                <div
                  className="dropdown-menu "
                  style={{ width: "100%" }}
                  aria-labelledby="places"
                >
                  {this.renderPlaces()}
                  <div className="dropdown-divider"></div>
                  <button
                    onClick={() => history.push("/places")}
                    className="dropdown-item dropdown-type select-type select-place"
                  >
                    Más locales
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-menu-container">
            <div className="option-svg d-none">
              <Link to={"/historial"}>
                <img src={Redemption} alt="redemption" />
              </Link>
            </div>
            <div className="option-svg">
              <a
                rel="noopener noreferrer"
                href={
                  "https://docs.google.com/presentation/d/e/2PACX-1vR62rTjX5aTIgEuZYnVVf6lWYNTsyzuyACnoySedWJJSuqeokZs7TtUAWvKVSSCVjVdGKnt8TNyjqsr/pub?start=true&loop=false&delayms=30000"
                }
                target="_blank"
                className={"d-flex"}
              >
                <img src={Manual} alt="redemption" />
                <span className={"ml-2"}>Manual</span>
              </a>
            </div>
            <div className="menu-dropdown">
              <div
                className="form-control"
                id="menu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/* <span> {menu} </span> */}
                <span>
                  {" "}
                  {localStorage.getItem("subdomain")
                    ? localStorage.getItem("subdomain")
                    : "Empresa"}
                </span>
                <div>
                  <ArrowMenu />
                </div>
              </div>
              <div className="dropdown-menu" aria-labelledby="menu">
                <Link
                  to={"/"}
                  className="dropdown-item dropdown-type select-type first-section"
                >
                  Validador
                  <hr />
                </Link>
                <Link
                  to={"/orders"}
                  className="dropdown-item dropdown-type select-type d-none "
                >
                  Ordenes
                  <hr />
                </Link>
                {/* <Link
                  to={"/transferred"}
                  className="dropdown-item dropdown-type select-type"
                >
                  PROMOCIONES TRANSFERIDAS
                </Link> */}
                <Link
                  to={"/historial"}
                  className="dropdown-item dropdown-type select-type"
                >
                  Redenciones
                  <hr />
                </Link>

                <a
                  rel="noopener noreferrer"
                  href={
                    "https://docs.google.com/presentation/d/e/2PACX-1vR62rTjX5aTIgEuZYnVVf6lWYNTsyzuyACnoySedWJJSuqeokZs7TtUAWvKVSSCVjVdGKnt8TNyjqsr/pub?start=true&loop=false&delayms=30000"
                  }
                  className="dropdown-item dropdown-type select-type"
                  target="_blank"
                >
                  Manual
                  <hr />
                </a>
                <a
                  href="/"
                  className="dropdown-item dropdown-type select-type close-section"
                  onClick={(e) => this.closeSession(e)}
                >
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
        </nav>
        {this.state.menuIcon && (
          <div className="menu-content-options">
            <div className="places-container">
              <div className="menu-dropdown">
                <div
                  className="form-control"
                  id="places"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div>
                    <h6>Escoger local</h6>
                    <h5>
                      {localStorage.getItem("placeAddressRedeem") &&
                        localStorage.getItem("placeAddressRedeem")}
                      {localStorage.getItem("placeAddressRedeem") === null &&
                        "Seleccionar Local"}
                    </h5>
                  </div>
                  <div>
                    <Arrow />
                  </div>
                </div>
                <div
                  className="dropdown-menu "
                  style={{ width: "100%" }}
                  aria-labelledby="places"
                >
                  {this.renderPlaces()}
                  <div className="dropdown-divider"></div>
                  <button
                    onClick={() => history.push("/places")}
                    className="dropdown-item dropdown-type select-type select-place"
                  >
                    Más locales
                  </button>
                </div>
              </div>
            </div>
            <div className="main-menu-container-mobile">
            <div className="menu-dropdown">
              <div
                className="form-control"
                id="menu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/* <span> {menu} </span> */}
                <span> {localStorage.getItem('subdomain') ? localStorage.getItem('subdomain') : "Empresa" } </span>
                <div>
                  <ArrowMenu />
                </div>
              </div>
              <div
                className="dropdown-menu"
                aria-labelledby="menu"
              >
                <Link
                  to={"/"}
                  className="dropdown-item dropdown-type select-type first-section"
                  onClick={() => this.setState({ menuIcon: false })}
                >
                  Validador
                  <hr />
                </Link>
                <Link
                  to={"/orders"}
                  className="dropdown-item dropdown-type select-type"
                  onClick={() => this.setState({ menuIcon: false })}
                >
                  Ordenes
                  <hr />
                </Link>
                {/* <Link
                  to={"/transferred"}
                  className="dropdown-item dropdown-type select-type"
                >
                  PROMOCIONES TRANSFERIDAS
                </Link> */}
                <Link
                  to={"/historial"}
                  className="dropdown-item dropdown-type select-type"
                  onClick={() => this.setState({ menuIcon: false })}
                >
                  Redenciones
                  <hr />
                </Link>

                <a
                  rel="noopener noreferrer"
                  href={
                    "https://docs.google.com/presentation/d/e/2PACX-1vSx487n1a-0yM82yk2QkzdBfAGl1ouzRuIeSDv6ctQZ6a6fBB56PVfEnWAkMgFWyN-Gu5RyV4QPwhWg/pub?start=true&loop=false&delayms=30000"
                  }
                  className="dropdown-item dropdown-type select-type"
                  target="_blank"
                  onClick={() => this.setState({ menuIcon: false })}
                >
                  Manual
                  <hr />
                </a>
                <a
                  href="/"
                  className="dropdown-item dropdown-type select-type close-section"
                  onClick={(e) => this.closeSession(e)}
                >
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
          </div>
        )}
      </Fragment>
    );
  }
}

MainMenu.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  menu: PropTypes.string,
  places: PropTypes.array,
  dataLocalArray: PropTypes.array,
  place: PropTypes.object,
  history: PropTypes.object,
};

MainMenu.defaultProps = {
  places: [],
  dataLocalArray: null,
  place: null,
  isFetching: false,
  menu: "VALIDADOR",
};

export default connect(({ places, menu, program }) => ({
  places: places.data,
  currentPlaceUid: places.currentPlaceUid,
  place: places.place,
  isFetching: places.isFetching,
  dataLocalArray: program.dataLocalArray,
  menu: menu.menu,
}))(MainMenu);
