import React, {Fragment} from "react";
import PropTypes from "prop-types";


const PlaceItem = props => {

  let {place, loading, noItems, currentPlaceUid, selectPlace} = props
    if(noItems){
        return (
            <Fragment>
                <tr>
                    <td colSpan={4} style={{fontWeight: 700}}>No se encontraron resultados</td>
                </tr>
            </Fragment>)
    }

  if (!loading) {
    let body = (
      <tr>
        <td style={{textAlign:'center'}}>{((place && place.name) ? place.name : "--")}</td>
        <td style={{textAlign:'center'}}>{((place && place.address) ? place.address : "--")}</td>
        <td style={{textAlign:'center'}}>{((place && place.locality) ? place.locality.name : "--")}</td>
          <td style={{textAlign:'center'}}>{
              (currentPlaceUid && place.uid === currentPlaceUid) ?
                  <button type="button" className="btn btn-light btn-success" disabled>Actual</button>
                  :
                  <button type="button" className="btn btn-light"
                          onClick={() => {
                              selectPlace(place.uid, place.address)
                          }}>
                      Elegir
                  </button>
          }
          </td>
      </tr>
    );
    return (
      <Fragment>
        {body}
      </Fragment>
    );
  } else {
      return (
        <Fragment>
          <tr>
            <td style={{textAlign:'center'}}></td>
            <td style={{textAlign:'center'}}></td>
            <td style={{textAlign:'center'}}></td>
              <td style={{textAlign:'center'}}>
                  <button type="button" className="btn btn-light" disabled={true}>
                      Elegir
                  </button>
              </td>
          </tr>
        </Fragment>
      )
  }
};

PlaceItem.defaultProps = {
  loading: false,
  company: null,
};

PlaceItem.propTypes = {
  company: PropTypes.object,
  loading: PropTypes.bool,
};
export default PlaceItem;
