import React, {Fragment} from 'react'
import './style.scss';
import ReactImageAppear from "react-image-appear";
import placeholder from "../../../../images/placeholder.jpg";
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../../../Bederr/constants";
import CoinSvg from "../../../../images/Coin.svg";
const $ = require('jquery');

export default class ProductPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeUid: "",
      productUid: "",
      productQuantity: 1,
      optionGroupUid: "",
      options: [],
      direct_purchase: false,
      inCart: false,
      load: true,

      isRedeem: false
    }
  }

  componentDidMount() {

    this.updateComponent();
  }

  componentDidUpdate() {
    this.updateComponent();
  }

  updateComponent() {
    const {openModal, setOpenModal, isRedeem} = this.props

    if (openModal) {
      $('#productModal').modal('show');
      setOpenModal(false);
    }
    $('body').css("padding", "0");

    if(isRedeem!==this.state.isRedeem){
      //this.setState({isRedeem: isRedeem})
    }
  }

  closeModal = (e) => {
    e.preventDefault()
    let {setProduct, cleanRedeemProduct, getPromotions, setSearch} = this.props
    this.setState(  {
      placeUid: "",
      productUid: "",
      productQuantity: 1,
      options: [],
      optionGroupUid: "",
      inCart: false
    });
    setProduct(null)
    cleanRedeemProduct()
    getPromotions()

    setSearch(true)


    $(this.modal).modal('hide');
  }

  redeemProduct = (e, product) => {
    let {redeemProduct, programClient} = this.props
    e.preventDefault();
    let payload = {
      "place": localStorage.getItem("placeIdRedeem"),
      "member": programClient.member ? programClient.member.uid: "",
      "payment_method": "0WpJ9Y",
      "product": product.uid,
      "quantity": 1
    }
    redeemProduct(payload)
  }

  render() {
    let {
      productsByPlaceCurrent,
      productsByPlaceCurrentStatus,
      currency,
      isRedeem
    } = this.props
    let nTopping = 0
    if(productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.option_groups && productsByPlaceCurrent.option_groups.length!==0){
      productsByPlaceCurrent.option_groups.map((option_group, index)=>{
        nTopping += option_group.options.length
        return true
      })
    }
    //console.log(this.props, "modal");
    return (
      <div>
        <div>
          <div
            className={"modal fade"}
            id="productModal"
            ref={(modal) => this.modal = modal}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static" data-keyboard="false"
          >
            {
              !isRedeem ?
                  <div
                      className={productsByPlaceCurrentStatus === STATUS_LOADING ?
                          "modal-dialog modal-lg load modal-product" : "modal-dialog modal-product  modal-lg"}
                      role="document">
                    <div
                        className={productsByPlaceCurrentStatus === STATUS_LOADING ? "modal-content-exchange load" : "modal-content-exchange "}>
                      <div
                          className={productsByPlaceCurrentStatus === STATUS_LOADING ? "modal-header load" : "modal-header "}>
                        {productsByPlaceCurrentStatus === STATUS_LOADING &&
                            <div className={"cover load"}>
                            </div>
                        }
                        { (productsByPlaceCurrentStatus === STATUS_SUCCESS ) &&
                            <ReactImageAppear
                                className="cover"
                                loader={placeholder}
                                loaderClass="cover"
                                placeholderClass={"placeholder"}
                                src={productsByPlaceCurrent.image ? productsByPlaceCurrent.image : ""}
                                animationDuration="1s"
                            />
                        }

                        <button type="button" className=" btn-close-ad btn-close-product"
                                style={{zIndex: 99, paddingRight: "25px"}} onClick={e=>{
                                  this.closeModal(e)
                        }}>
                          <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                        </button>
                      </div>

                      <div className={nTopping===0 ? "modal-body not-topping-sm" : nTopping>4 ? "modal-body" : "modal-body  not-topping"}>
                        <div className={"detail detail-redemptions"}>
                          <div className="header-detail d-flex">
                            {productsByPlaceCurrentStatus === STATUS_SUCCESS &&
                                <Fragment>
                                  <span>{productsByPlaceCurrent.title}</span>
                                </Fragment>
                            }
                          </div>
                          <div className="body-detail">
                            {productsByPlaceCurrentStatus === STATUS_SUCCESS ?
                                <Fragment>
                                  {/* <p> Descripción</p> */}
                                  <p>{productsByPlaceCurrent.short_description}</p>
                                  {
                                    productsByPlaceCurrent && productsByPlaceCurrent.is_redeemable ?
                                        <p className="coin font-weight-bold"><img src={CoinSvg} alt="coin" /> {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                                        :
                                        <p className="price font-weight-bold">{currency ? currency.symbol : "S/."} {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                                  }

                                </Fragment> : <p/>}
                          </div>
                          {
                              productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.option_groups && productsByPlaceCurrent.option_groups.length!==0 &&
                              productsByPlaceCurrent.option_groups.map((option_group, index)=>(

                                  <div className={"topping"} key={`option_group${index}`}>
                                    <div className="header-detail d-flex mt-4">
                                      <span className={"col-12 pb-1"}>{option_group.name}</span>
                                    </div>
                                    <div className={"d-flex col-12 topping-min-max"}>
                                      <p className={"topping-terms"} style={{marginRight: "0.15rem"}}>Escoge mínimo {option_group.minimum_to_select},</p>
                                      <p className={"topping-terms"}>{" "}máximo {option_group.maximum_to_select} {option_group.maximum_to_select>1 ? "opciones": "opción"}</p>
                                    </div>
                                    <div className="body-detail">
                                      {
                                        option_group.options.map((p, index)=>(
                                            <div className={index===option_group.options.length-1 ? "d-flex justify-content-between topping-block mt-0 align-items-center border-bottom-0" : "d-flex justify-content-between topping-block mt-0 align-items-center"} key={`option${index}`} >
                                              <div className={"topping-right col-10 pl-0 d-flex pr-0"}>
                                                <label htmlFor={`checkbox${option_group.uid}${p.uid}`} className="form-check-label mt-2" >
                                                  <p>{p.name}</p>
                                                  <span className={"topping-price"}>+{currency ? currency.symbol : "S/."}  {p.price}</span>
                                                </label>
                                              </div>
                                              <div className={"topping-left col-2 p-0 text-right"}>
                                                <div className={""}>
                                                  <input type="checkbox" id={`checkbox${option_group.uid}${p.uid}`}
                                                         checked={this.state.options.includes(p.uid)}
                                                         onChange={e=>{
                                                           if(this.state.options.includes(p.uid)){
                                                             let aux = this.state.options
                                                             let i = aux.indexOf( p.uid );
                                                             if ( i !== -1 ) {
                                                               aux.splice( i, 1 );
                                                             }
                                                             this.setState({options: aux, optionGroupUid: option_group.uid})
                                                           }else{
                                                             this.setState({options:[...this.state.options, p.uid], optionGroupUid: option_group.uid})
                                                           }
                                                         }}/>
                                                </div>

                                              </div>
                                            </div>
                                        ))
                                      }
                                    </div>
                                  </div>
                              ))

                          }
                        </div>
                        <div className="controls d-flex justify-content-sm-between">
                          <div className={"add-cart col-12 col-sm-12 col-lg-12  mx-auto"}>
                            <button className={"btn btn-primary btn-redemptions"}
                                    onClick={e => this.redeemProduct(e, productsByPlaceCurrent)}>
                              <div className='box-redemptions'>
                                <h3>Canjear</h3>
                                <p className="coin font-weight-bold"><img src={CoinSvg} alt="coin" /> {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  isRedeem===STATUS_SUCCESS ?
                  <div
                      className={"modal-dialog modal-product modal-lg"}
                      role="document">
                    <div
                        className={"modal-content-exchange modal-redeem"}>
                      <div
                          className={"modal-header "}>
                          <div className={"div-info"}>
                            <strong>Su canje fue realizado con exito</strong>
                            <p>Le hemos enviado un mail con el detalle de la compra</p>
                            <button className={"btn btn-whitelabel-secondary btn-lg btn-block btn-go-home"}
                                    onClick={e=>this.closeModal(e)}
                            >Ir al home</button>
                          </div>
                          <button type="button" className=" btn-close-ad btn-close-product" data-dismiss="modal" aria-label="Close"
                                style={{zIndex: 99, paddingRight: "25px"}}
                                  onClick={e=>this.closeModal(e)}>
                          <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                        </button>
                      </div>
                    </div>
                  </div> :
                      isRedeem === STATUS_ERROR ?
                          <div
                              className={"modal-dialog modal-product modal-lg"}
                              role="document">
                            <div
                                className={"modal-content-exchange  modal-redeem"}>
                              <div
                                  className={"modal-header "}>
                                <div className={"div-info"}>
                                  <strong>Ha ocurrido un error al realizar el canje</strong>
                                  <p>Por favor, vuelva a intentarlo en unos momentos.</p>
                                  <button className={"btn btn-whitelabel-secondary btn-lg btn-block btn-go-home"}
                                          onClick={e=>this.closeModal(e)}
                                  >Ir al home</button>
                                </div>
                                <button type="button" className=" btn-close-ad btn-close-product" data-dismiss="modal" aria-label="Close"
                                        style={{zIndex: 99, paddingRight: "25px"}}
                                        onClick={e=>this.closeModal(e)}>
                                  <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                                </button>
                              </div>
                            </div>
                          </div>
                      :
                          <div
                              className={"modal-dialog modal-product modal-lg"}
                              role="document">
                            <div
                                className={"modal-content-exchange  modal-redeem"}>
                              <div
                                  className={"modal-header "}>
                                <div className={"div-info"}>
                                </div>
                              </div>
                            </div>
                          </div>
            }

          </div>
        </div>
      </div>
    );
  }
}
