import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
//import Offer from '../../components/Offer';
import "./style.scss";
//import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';
import ModalValidator from "./ModalValidator";
import {
  cleanProductRedeemable, cleanRedeemProduct,
  editPromotion, getMoreProductsRedeemables,
  getMorePromotions, getPromotions, redeemProduct, retrieveProductRedeemable, selectClient,
  selectPromotion,
  stamped,
} from "./actions";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

//import ShowMoreText from 'react-show-more-text';
import Stamps from "./components/Stamps";
import Promotion from "./components/Promotion";
import ImageDefault from "../../images/placeholder.jpg"
import CoinSvg from "../../images/Coin.svg"
import ProductPopUp from "./components/productPopUp";
import {toast} from "react-toastify";
import backIcon from "../../components/Icons/back.svg";

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_promotions: false,
      show_coins: false,
      show_stamps: false,
      show_consumptions: false,
      openModal: false,
      productSelect: null
    };
  }

  showModalPromotion(e, promotion) {
    e.preventDefault();
    if (this.props.promotion != null)
      this.props.dispatch(selectPromotion(null));
    else this.props.dispatch(selectPromotion(promotion));
  }

  stampPromotion(e, promotion) {
    e.preventDefault();
    const { isStamping } = this.props;
    let uidPlace = localStorage.getItem("placeIdRedeem");
    if (uidPlace !== null && uidPlace !== "" && !isStamping) {
      const data = {
        member: promotion.member_uid,
        program_promotion: promotion.uid,
        place: uidPlace,
        stamps: 1,
      };
      promotion.stamping = true;
      this.props.dispatch(editPromotion(promotion));
      this.props.dispatch(stamped(data));
    }
  }

  loadMore = () => {
    const { next, nextProductsRedeemable, moreLoader } = this.props;
    if(this.state.show_promotions || this.state.show_stamps){
      if (!moreLoader && next != null && next !== "") {
        this.props.dispatch(getMorePromotions(next));
      }
    } else{
      if(this.state.show_coins){
        if (!moreLoader && nextProductsRedeemable != null && nextProductsRedeemable !== "") {
          this.props.dispatch(getMoreProductsRedeemables(nextProductsRedeemable));
        }
      }
    }
  };

  onBack = (e) => {
    e.preventDefault()
    this.setState({
      show_promotions: false,
      show_coins: false,
      show_stamps: false,
      show_consumptions: false,
      openModal: false,
      productSelect: null})
  };

  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }

  renderPromotions() {
    const { promotions, business } = this.props;
    //console.log(this.props);
    const stampsObject = promotions.filter(
      (stamp) => stamp.promotion.type === "STAMPS"
    );
    //console.log(stampsObject, "stamps");
    const promotionObject = promotions.filter(
      (promotion) => promotion.promotion.type !== "STAMPS"
    );
    //console.log(promotionObject, "promotions");
    if ((this.state.show_promotions && promotionObject.length === 0) || (this.state.show_stamps && stampsObject.length === 0)) {
      return (
          <div className="content-no-items col-lg-12">
            <h3>Ups! , No se pudo encontrar descuentos para este cliente</h3>
            <p>Esto puede ser debido a las siguientes causas</p>
            <ul className="list-group">
              <li className="list-group-item">
                El usuario no se encuentra registrado en la aplicación respectiva
                (Proponer que descargue la aplicación y se registre)
              </li>
              <li className="list-group-item">
                El usuario no tiene promociones en este local
              </li>
            </ul>
          </div>
      );
    }

    console.log(promotions, this.state.show_stamps, promotionObject, "---")
    return (
      <div className="col-lg-12 mt-4">
        {this.state.show_stamps && stampsObject.length > 0 && (
          <Fragment>
            <div className="section-promotion-title">
              <a
                  className="nav-link back-icon mr-3"
                  href={"/"}
                  onClick={(e) => this.onBack(e)}
              >
                <img src={backIcon} alt="backIcon" />
              </a>
              <h4>STAMPS DISPONIBLES</h4>
            </div>
            <div className="container-promotion-style">
              {stampsObject.map((promotion,i) => (
                <div key={`prom${promotion.uid} - ${i}`}>
                  <div className="row d-block box-promotion-style">
                    {promotion.promotion.required_stamps > 0 && (
                      <Stamps promotion={promotion} business={business} imageIcon={true} />
                    )}
                    <div className="btn-promotion-container">
                      {promotion.available_uses !== 0 &&
                        promotion.times_used_for_member <
                          promotion.usage_limit_per_user &&
                        (promotion.promotion.required_stamps <= 0 ||
                          (promotion.promotion.required_stamps >= 0 &&
                            promotion.promotion.required_stamps <=
                              promotion.available_stamps)) &&
                        promotion.promotion.type !== "GIFT_CARD" &&
                        !promotion.is_blocked_of_box && (
                          <div className="box-btn-stamps">
                            <button>
                              {promotion.stamping === false && (
                                <span>
                                  QUEDA (
                                  {`${
                                    promotion.usage_limit_per_user -
                                      promotion.times_used_for_member <=
                                    promotion.available_uses
                                      ? promotion.usage_limit_per_user -
                                        promotion.times_used_for_member
                                      : promotion.available_uses
                                  }`}
                                  /{promotion.usage_limit_per_user})
                                </span>
                              )}
                            </button>
                            <button
                              onClick={(e) =>
                                this.showModalPromotion(e, promotion)
                              }
                              className="btn-action-stamps"
                            >
                              <span>Validar</span>
                            </button>
                          </div>
                        )}
                      {promotion.available_uses > 0 &&
                        promotion.promotion.required_stamps > 0 &&
                        promotion.promotion.required_stamps >
                          promotion.available_stamps && (
                          <div className="box-btn-stamps">
                            <button>
                            {promotion.stamping === true && (
                                <span>
                                  Loading.....
                                </span>
                              )}
                              {promotion.stamping === false && (
                                <span>
                                  QUEDA (
                                  {promotion.promotion.required_stamps -
                                    promotion.available_stamps}{" "}
                                  / {promotion.promotion.required_stamps})
                                </span>
                              )}
                            </button>
                            <button
                              onClick={(e) => this.stampPromotion(e, promotion)}
                              className="btn-action-stamps"
                            >
                              {promotion.stamping === false && (
                                <span>Sellar</span>
                              )}
                              {promotion.stamping === true && (
                                <span>Sellando...</span>
                              )}
                            </button>
                          </div>
                        )}
                      {promotion.available_uses > 0 &&
                        promotion.promotion.required_stamps > 0 &&
                        promotion.promotion.required_stamps <=
                          promotion.available_stamps && (
                          <span className="text-validate">
                            Usted ya tiene las suficientes estampas ({" "}
                            {promotion.available_stamps} )
                          </span>
                        )}
                      {promotion.is_blocked_of_box &&
                        promotion.promotion.type !== "GIFT_CARD" && (
                          <p>
                            Promoción agotada, redimida{" "}
                            {promotion.usage_limit_per_user} veces el usuario.
                            Límite: {promotion.usage_limit_per_user}
                          </p>
                        )}
                      {(promotion.available_uses === 0 ||
                        promotion.times_used_for_member >=
                          promotion.usage_limit_per_user) &&
                        promotion.promotion.type !== "GIFT_CARD" && (
                          <p>
                            Promoción agotada, redimida{" "}
                            {promotion.times_used_for_member} veces el usuario.
                            Límite: {promotion.usage_limit_per_user}
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase == null && (
                          <p>
                            El usuario no ha adquirido o no tiene crédito en
                            este GiftCard
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase != null &&
                        promotion.purchase.total_balance === 0 && (
                          <p>
                            A usted ya no le queda credito en esta promoción
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase != null &&
                        promotion.purchase.total_balance > 0 && (
                          <div>
                            <span className={"credit"}>
                              Crédito:{" "}
                              {business &&
                                business.currency &&
                                business.currency.symbol}{" "}
                              {promotion.purchase.total_balance}
                            </span>
                            <button
                              onClick={(e) =>
                                this.showModalPromotion(e, promotion)
                              }
                              className="btn btn-whitelabel-secondary btn-lg btn-block"
                            >
                              Redimir
                            </button>{" "}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        )}
        {this.state.show_promotions && promotionObject.length > 0 && (
          <Fragment>
            <div className="section-promotion-title">
              <a
                  className="nav-link back-icon mr-3"
                  href={"/"}
                  onClick={(e) => this.onBack(e)}
              >
                <img src={backIcon} alt="backIcon" />
              </a>
              <h4>PROMOCIONES DISPONIBLES</h4>
            </div>
            <div className="container-promotion-style">
              {promotionObject.map((promotion,i) => (
                <div key={`prom${promotion.uid} - ${i}`}>
                  <div className="row d-block box-promotion-style">
                    {promotion.promotion.required_stamps === 0 && (
                      <Promotion promotion={promotion} business={business} />
                    )}
                    <div className="btn-promotion-container">
                      {promotion.available_uses !== 0 &&
                        promotion.times_used_for_member <
                          promotion.usage_limit_per_user &&
                        (promotion.promotion.required_stamps <= 0 ||
                          (promotion.promotion.required_stamps >= 0 &&
                            promotion.promotion.required_stamps <=
                              promotion.available_stamps)) &&
                        promotion.promotion.type !== "GIFT_CARD" &&
                        !promotion.is_blocked_of_box && (
                          <div className="box-btn-stamps box-btn-promotions">
                            <button>
                              {
                                promotion.stamping === false && (
                                  <span>
                                    QUEDA (
                                    {`${
                                      promotion.usage_limit_per_user -
                                        promotion.times_used_for_member <=
                                      promotion.available_uses
                                        ? promotion.usage_limit_per_user -
                                          promotion.times_used_for_member
                                        : promotion.available_uses
                                    }`}
                                    /{promotion.usage_limit_per_user})
                                  </span>
                                )
                                //<span>QUEDA {promotion.usage_limit_per_user} </span>
                              }
                            </button>
                            <button
                              onClick={(e) =>
                                this.showModalPromotion(e, promotion)
                              }
                              className="btn-action-stamps"
                            >
                              <span>Validar</span>
                            </button>
                          </div>
                        )}
                      {promotion.available_uses > 0 &&
                        promotion.promotion.required_stamps > 0 &&
                        promotion.promotion.required_stamps <=
                          promotion.available_stamps && (
                          <span className="text-validate">
                            Usted ya tiene las suficientes estampas ({" "}
                            {promotion.available_stamps} )
                          </span>
                        )}
                      {promotion.is_blocked_of_box &&
                        promotion.promotion.type !== "GIFT_CARD" && (
                          <p>
                            Promoción agotada, redimida2222{" "}
                            {promotion.usage_limit_per_user} veces el usuario.
                            Límite: {promotion.usage_limit_per_user}
                          </p>
                        )}
                      {(promotion.available_uses === 0 ||
                        promotion.times_used_for_member >=
                          promotion.usage_limit_per_user) &&
                        promotion.promotion.type !== "GIFT_CARD" && (
                          <p>
                            Promoción agotada, redimida{" "}
                            {promotion.times_used_for_member} veces el usuario.
                            Límite: {promotion.usage_limit_per_user}
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase == null && (
                          <p>
                            El usuario no ha adquirido o no tiene crédito en
                            este GiftCard
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase != null &&
                        promotion.purchase.total_balance === 0 && (
                          <p>
                            A usted ya no le queda credito en esta promoción
                          </p>
                        )}
                      {promotion.promotion.type === "GIFT_CARD" &&
                        promotion.purchase != null &&
                        promotion.purchase.total_balance > 0 && (
                          <div>
                            <span className={"credit"}>
                              Crédito:{" "}
                              {business &&
                                business.currency &&
                                business.currency.symbol}{" "}
                              {promotion.purchase.total_balance}
                            </span>
                            <button
                              onClick={(e) =>
                                this.showModalPromotion(e, promotion)
                              }
                              className="btn btn-whitelabel-secondary btn-lg btn-block"
                            >
                              Redimir
                            </button>{" "}
                          </div>
                        )}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  {/* <div className="line"/> */}
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  renderProductsRedeemables() {
    const { productsRedeemables: productRedeemableObject } = this.props;
    let { openModal } = this.state;
    //console.log(this.props);
    if (productRedeemableObject.length === 0) {
      return (
        <div className="content-no-items col-lg-12">
          <h3>Ups! , No se pudo encontrar productos canjeables para este cliente</h3>
          <p>Esto puede ser debido a las siguientes causas</p>
          <ul className="list-group">
            <li className="list-group-item">
              El usuario no se encuentra registrado en la aplicación respectiva
              (Proponer que descargue la aplicación y se registre)
            </li>
            <li className="list-group-item">
              El usuario no tiene productos canjeables en este local
            </li>
          </ul>
        </div>
      );
    }

    //console.log(this.props.programClient, "client");

    return (
      <div className="col-lg-12 mt-4">
        {productRedeemableObject.length > 0 && (
          <Fragment>
            <div className="section-promotion-title">
              <a
                  className="nav-link back-icon mr-3"
                  href={"/"}
                  onClick={(e) => this.onBack(e)}
              >
                <img src={backIcon} alt="backIcon" />
              </a>
              <h4>PRODUCTOS CANJEABLES DISPONIBLES</h4>
            </div>
            <div className="exchange-list mb-4">
              {productRedeemableObject.map((product,i) => (
                  <div
                      key={`prom${product.uid} - ${i}`}
                      className={"item-exchange"}
                  >
                    <div
                        onClick={(e) => {
                          if (!openModal) {
                            this.openModalProduct(e, product);
                          }
                        }}
                    >
                      <div className="item-image">
                        <img
                            src={product.image ? product.image : ImageDefault}
                            alt="exchange-img"
                        />
                      </div>
                      <div className="item-info">
                        <h4>
                          {" "}
                          <span>
                            <img src={CoinSvg} alt="coin" />{" "}
                          </span>{" "}
                          {product.unit_price}{" "}
                        </h4>
                        <h5> {product.title} </h5>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  openModalProduct = (e, product) => {
    e.preventDefault();

    const { openModal  } = this.state;
    let showFirstTime = false
    if (!openModal) {
      if (product.stock !== 0) {
        console.log(product, "product--");
        this.setState({productSelect : product, openModal: true});
        this.props.dispatch(cleanProductRedeemable())
        this.props.dispatch(cleanRedeemProduct())
        this.props.dispatch(retrieveProductRedeemable(localStorage.getItem('placeIdRedeem'), product.uid));
      }else{
        if(!showFirstTime){
          toast.error("El producto no cuenta con stock")
          showFirstTime=true
        }
      }
    }
  };

  showModalPoints(e, client) {
    e.preventDefault();
    this.props.dispatch(selectClient(client));
  }

  render() {
    const { document_number, type, programClient, isFetching, promotions, promotion, next, client, nextProductsRedeemable, business,
      business: {currency}, productsByPlaceCurrent, productsByPlaceCurrentStatus, isRedeem, clientProgram } = this.props;
    console.log(clientProgram, "clientProgram")
    let more = next != null && next !== "";
    let moreProductsRedeemable = nextProductsRedeemable != null && nextProductsRedeemable !== "";
    if (isFetching) {
      return (
        <div className="col-lg-12">
          <LoadingIndicator />
        </div>
      );
    }
    const loaderMore = (
      <div
        key={0}
        className="col-lg-12 content-loader"
        style={{ display: "inline-flex" }}
      >
        {
            (this.state.show_promotions || this.state.show_stamps) && (!this.state.show_coins && !this.state.show_consumptions) &&
            <p>Cargando más promociones...</p>
        }
        {
            (!this.state.show_promotions && !this.state.show_stamps && this.state.show_coins && !this.state.show_consumptions) &&
            <p>Cargando más productos...</p>
        }
      </div>
    );
    if (promotions != null) {
      return (
          <Fragment>
            <div className="col-lg-12">
          <InfiniteScroll
            className={"entities-section row"}
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={!this.state.show_coins ? more : moreProductsRedeemable}
            loader={loaderMore}
          >
            {
              !(this.state.show_promotions || this.state.show_coins || this.state.show_stamps || this.state.show_consumptions) &&
                <div className={"list-menu-options"}>
                  <div className={"list-submenu-options"}>
                    <button className={"btn-primary btn-option"}
                            onClick={()=>{this.setState({show_promotions: true})}}>Promos</button>
                    <button className={"btn-primary btn-option"}
                            onClick={()=>{this.setState({show_coins: true})}}>Monedas</button>
                  </div>
                  <div className={"list-submenu-options"}>
                    <button className={"btn-primary btn-option"}
                            onClick={()=>{this.setState({show_stamps: true})}}>Stamps</button>
                    <button className={"btn-primary btn-option d-none"}
                            onClick={(e) => {
                              this.showModalPoints(e, clientProgram)
                            }}
                           >Sumar puntos</button>
                  </div>
                </div>
            }
            {(this.state.show_promotions || this.state.show_stamps) && (!this.state.show_coins && !this.state.show_consumptions) && this.renderPromotions()}
            {(!this.state.show_promotions && !this.state.show_stamps && this.state.show_coins && !this.state.show_consumptions) && this.renderProductsRedeemables()}
          </InfiniteScroll>
          {promotion !== null ? <ModalValidator /> : null}
        </div>
                <ProductPopUp
                    product={this.state.productSelect}
                    openModal={this.state.openModal}
                    currency={currency}
                    setOpenModal={s=>this.setState({openModal: s})}
                    setProduct={product=>this.setState({productSelect : product})}
                    productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                    productsByPlaceCurrent={productsByPlaceCurrent}
                    company={business}
                    redeemProduct={(data)=>this.props.dispatch(redeemProduct(data))}
                    cleanRedeemProduct={()=>this.props.dispatch(cleanRedeemProduct())}
                    cleanProductRedeemable={()=>this.props.dispatch(cleanProductRedeemable())}
                    getPromotions={()=>this.props.dispatch(getPromotions(document_number, type, localStorage.getItem("placeIdRedeem")))}
                    isRedeem={isRedeem}
                    programClient={programClient}
                    client={client}
                    setSearch={(s)=>this.props.setSearch(s)}
                />
          </Fragment>
      );
    }
    return (
      <div className="col-lg-12 start-search">
        {client && client.type !== "PROGRAM_PROMOTION" && (
          <Fragment>
            <h2>PARA COMENZAR</h2>
            <p>
              Selecciones el local en el que se encuentra <br />y luego ingrese
              un documento de identidad para comenzar con la búsqueda
            </p>
          </Fragment>
        )}
      </div>
    );
  }
}

Promotions.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  promotions: PropTypes.array,
  promotion: PropTypes.object,
  place: PropTypes.object,
  next: PropTypes.string,
  moreLoader: PropTypes.bool,
  isStamping: PropTypes.bool,
  business: PropTypes.object,
};
Promotions.defaultProps = {
  promotions: null,
  promotion: null,
  place: null,
  isFetching: false,
  isStamping: false,
  moreLoader: false,
  client: null,
  business: null,
};
export default connect(({ promotions, places, user, client, program }) => ({
  promotions: promotions.data,
  productsRedeemables: promotions.dataProductsRedeemable,
  promotion: promotions.promotion,
  isFetching: promotions.isFetching,
  isFetchingProductsRedeemable: promotions.isFetchingProductsRedeemable,
  next: promotions.next,
  nextProductsRedeemable: promotions.nextProductsRedeemable,
  productsByPlaceCurrentStatus: promotions.productsByPlaceCurrentStatus,
  isRedeem: promotions.isRedeem,
  productsByPlaceCurrent: promotions.productsByPlaceCurrent,
  isStamping: promotions.isStamping,
  moreLoader: promotions.moreLoader,
  place: places.place,
  business: user.business,
  client: client.client,
  programClient: program.data,
  clientProgram: program.client,
}))(Promotions);
