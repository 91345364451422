import {
    GET_PLACES,
    SELECT_PLACE,
    SET_NUMBER_PAGE_PLACES,
    SET_FIRST_PAGE_PLACES,
    SET_CURRENT_COMPANY_UID_PLACES,
    SET_CURRENT_SEARCH_TERM_PLACES
} from './constants';
import HttpClient from '../../utils/httpClient';


export const LIST_CREATE_PLACES_URL = 'v5/platform/companies/{company_uid}/places/';
export const LIST_PAGE_PLACES_URL = 'v5/platform/companies/{company_uid}/places/?page={uid}';


export function setSearchTermCurrentPlaces(term) {
    return {
        type: SET_CURRENT_SEARCH_TERM_PLACES,
        term
    };
}

export function setCompanyUidCurrentPlaces(uid) {
    return {
        type: SET_CURRENT_COMPANY_UID_PLACES,
        uid
    };
}

export function setFirstList(list) {
    return {
        type: SET_FIRST_PAGE_PLACES,
        list
    };
}

export function setNumberPage(currentPageNumber) {
    return {
        type: SET_NUMBER_PAGE_PLACES,
        currentPageNumber
    };
}

export function getPlaces(params = []) {
    let filters = "";
    let isEmpty = false;
    if (params.length > 0) {
        filters = `?`;
        params.forEach(function (param) {
            if (filters.length > 1) {
                filters += `&`;
            }
            for (let key of Object.keys(param)) {
                filters += `${key}=${param[key]}`
                if(param[key]===""){
                    isEmpty = true
                }else{
                    isEmpty = false
                }
            }

        })
    }
    let url = LIST_CREATE_PLACES_URL.replace('{company_uid}', window.localStorage.getItem(`companyUid`));

    if(filters.length>0 && !isEmpty) {
        url = `${url}${filters}`;
    }

    let type = GET_PLACES;


    console.log("url")
    console.log(url)
    return (dispatch) => HttpClient.get(dispatch, type, url);
}


export function getNumberPagePlaces(uid, params = []) {
    let filters = "";
    let url = LIST_CREATE_PLACES_URL.replace('{company_uid}', window.localStorage.getItem(`companyUid`));
    let type = GET_PLACES;
    let isEmpty = false;
    if (params.length > 0) {
        filters = `?`;

        params.forEach(function (param) {
            if (filters.length > 1) {
                filters += `&`;
            }
            for (let key of Object.keys(param)) {
                filters += `${key}=${param[key]}`
                if(param[key]===""){
                    isEmpty = true
                }else{
                    isEmpty = false
                }
            }

        })
    }
    if(filters.length>0 && !isEmpty) {
        url = `${url}${filters}`;
        url = `${url}&page=${uid}`;
    }else{
        url = LIST_PAGE_PLACES_URL.replace('{company_uid}', window.localStorage.getItem(`companyUid`)).replace('{uid}', uid);
    }

    console.log("url-next")
    console.log(url)
    return (dispatch) => HttpClient.get(dispatch, type, url);
}

export function selectPlace(placeUid, placeAddress) {
    return {
        type: SELECT_PLACE,
        placeUid,
        placeAddress
    };
}
