import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {appliedPoints, searchProgram, selectClient} from './actions';
import Validator from '../../utils/validator';
import LoadingIndicator from '../../components/LoadingIndicator';
import {toast} from "react-toastify";

const $ = require('jquery');


class ModalAddPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: 0,
      description: '',
      error: '',
      textButton: 'REGISTRAR',
    };
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  componentDidMount() {
    $(this.modal)
      .modal('show');
    $(this.modal)
      .on('hidden.bs.modal');
    this.validator = new Validator([
      {
        id: 'points',
        ref: this.pointsInput,
        rules: ['required', 'numeric'],
      },
      {
        id: 'description',
        ref: this.descriptionInput,
        rules: ['required'],
      },
    ]);
  }

  handleCloseClick() {
    this.props.dispatch(selectClient(null));
    $(this.modal)
      .modal('hide');
  }

  componentWillReceiveProps(nextProps) {
    const {
      errorApplied, code, client
    } = nextProps;
    if ((code === 200 || code === 201)) {
      this.setState({
        error: '',
        points: 0,
        textButton: 'REGISTRAR',
      });
      toast.success("Se agregaron los puntos correctamente");
      this.props.dispatch(searchProgram(localStorage.getItem('placeIdRedeem'), client.document_number, client.document_type));
      this.handleCloseClick();
    } else if (errorApplied !== '') {
      console.log(code, "codecode")
      if(code === 403){
        toast.error("El programa de beneficios no tiene activado esta funcionalidad");
      }else{
        toast.error("Hubo un error al agregar los puntos");
      }
      this.setState({
        error: errorApplied,
        textButton: 'REGISTRAR',
      });
    }
  }


  onSubmitValidate(e) {
    e.preventDefault();
    const { client, program } = this.props;
    if (this.state.textButton === 'REGISTRAR' && client !== null) {
      if (this.state.points > 0 && this.state.description !== '') {
        this.setState({
          error: '',
          textButton: 'REGISTRANDO...',
        });
        this.props.dispatch(appliedPoints(
          program.uid,
          {
            document_type: client.document_type,
            document_number: client.document_number,
            place_uid: localStorage.getItem('placeIdRedeem'),
            amount: this.state.points,
            description: this.state.description
          }));
      } else {
        this.setState({
          error: 'El monto debe ser mayor a 0 y la descripción es obligatoria',
        });
      }
    }
  }

  handlePoints = async (event) => {
    event.preventDefault();
    this.setState({
      points: event.target.value,
      error: '',
    });
  };

  handleDescription = async (event) => {
    event.preventDefault();
    this.setState({
      description: event.target.value,
      error: '',
    });
  };

  render() {
    const { isFetchingApplied, client } = this.props;
    if (client != null) {
      return (
        <div>
          <div
            className="modal fade"
            ref={(modal) => this.modal = modal}
            id="pointsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="pointsModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg modal-style-promotion" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button onClick={this.handleCloseClick} type="button" className="close">
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="modal-body d-flex flex-column">
                  <div className="col-lg-12 justify-content-center data">
                    <h4>Adicionar consumo</h4>
                    <div
                      className="form-group"
                      ref={(input) => {
                        if (input) {
                          this.pointsInput = input.querySelector('input');
                        }
                      }}
                    >
                      <input
                        id="points"
                        value={this.state.points}
                        className="form-control col-lg-5 input-search input-amount"
                        type="text"
                        onChange={this.handlePoints}
                        placeholder="Ingresar puntos"
                      />
                    </div>
                    <div
                      className="form-group"
                      ref={(input) => {
                        if (input) {
                          this.descriptionInput = input.querySelector('input');
                        }
                      }}
                    >
                      <input
                        id="description"
                        value={this.state.description}
                        onChange={this.handleDescription}
                        className="form-control col-lg-5 input-search input-amount"
                        type="text"
                        placeholder="Ingresar descripción"
                      />
                    </div>
                  </div>
                  {
                    isFetchingApplied &&
                    <LoadingIndicator/>
                  }
                  {
                    (isFetchingApplied === false && this.state.error !== '') &&
                    <div className=" col-lg-12 alert alert-warning alert-dismissible fade show" role="alert">
                      <strong>{this.state.error}</strong>
                    </div>
                  }
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn-cancel col-lg-3"
                    onClick={this.handleCloseClick}
                  >Cancelar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-whitelabel-secondary col-lg-3"
                    onClick={(e) => this.onSubmitValidate(e)}
                  >
                    {this.state.textButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (<div></div>);
  }
}

ModalAddPoints.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  place: PropTypes.object,
  program: PropTypes.object,
  errorApplied: PropTypes.string,
  isFetchingApplied: PropTypes.bool,
  code: PropTypes.number,
};
export default connect(({ points, places, program }) => ({
  isFetchingApplied: points.isFetchingApplied,
  errorApplied: points.errorApplied,
  place: places.place,
  client: points.client,
  code: points.applyCode,
  program : program.data
}))(ModalAddPoints);
