import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import ModalPromotionTransferredDetail from "./ModalPromotionTransferredDetail";
import Offer from "../../components/Offer";
import CompanyLogo from "../../components/CompanyLogo";
import ShowMoreText from "react-show-more-text";
import {stamped} from "../Promotions/actions";
import {toast} from "react-toastify";
import {getPromotionTransferred} from "./actions";

class PromotionTransferredListItem extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedPromotionTransferred: null,
         showMessage: false
      };
   }

   renderHistory() {
      const {promotionTransferred} = this.props;
      if (promotionTransferred.length === 0) {
         return (
            <tr className="content-no-items">
               <td colSpan="11">
                  Ups! , no pudimos encontrar Registros
               </td>
            </tr>
         );
      }
      return (
         <tr key={`${promotionTransferred.program_promotion_uid}`}>
            <td> <button type={'button'}
                         onClick={e => this.setState({selectedPromotionTransferred: promotionTransferred})}
                         className={'btn btn-whitelabel-secondary btn-sm'}>{promotionTransferred.program_promotion_uid}</button></td>

            <td>{promotionTransferred.member_uid ? promotionTransferred.member_uid : ""}</td>
            <td>{promotionTransferred.recipient_email ? promotionTransferred.recipient_email : ""}</td>
            <td>{promotionTransferred.code ? promotionTransferred.code : ""}</td>
            <td>{promotionTransferred.promotion.title ? promotionTransferred.promotion.title : ""}</td>
            <td>{promotionTransferred.promotion.terms ? promotionTransferred.promotion.terms : ""}</td>
         </tr>
      )
   }

   showModalPromotion(e, promotionTransferred) {
      e.preventDefault();
      this.setState({selectedPromotionTransferred: promotionTransferred})

   }

   stampPromotion(e) {
      e.preventDefault();
      const {isStamping, promotionTransferred} = this.props;
      let uidPlace = localStorage.getItem('placeIdRedeem')
      if (uidPlace !== null && uidPlace !== "" && !isStamping) {
         const data = {
            member: promotionTransferred.member_uid,
            program_promotion: promotionTransferred.program_promotion_uid,
            place: uidPlace,
            stamps: 1
         }
         this.props.dispatch(stamped(data));
      }
   }

   renderPromotions() {
      const {promotionTransferred, isStamping, business} = this.props;
      if (promotionTransferred.length === 0) {
         return (
             <div className="content-no-items col-lg-12">
                <h3>Ups! , No se pudo encontrar descuentos para este cliente</h3>
                <p>
                   Esto puede ser debido a las siguientes causas
                </p>
                <ul className="list-group">
                   <li className="list-group-item">El usuario no se encuentra registrado en la aplicación respectiva
                      (Proponer
                      que descargue la aplicación y se registre)
                   </li>
                   <li className="list-group-item">El usuario no tiene promociones en este local</li>

                </ul>
             </div>
         );
      }



      return (
          <div key={`prom${promotionTransferred.promotion.uid}`} className="col-lg-12 promotion-list-item">
             <div className="row">
                <div className="col-lg-4">
                   <Offer promotion={promotionTransferred.promotion}/>
                </div>
                <div className="col-lg-5 description-list-offers">
                   <div className="header-description">
                      <CompanyLogo logo={promotionTransferred.program.company_logo}/>
                      <h4>{promotionTransferred.program.name}</h4>
                      <h5>{promotionTransferred.promotion.title}</h5>
                   </div>
                   <ShowMoreText
                       lines={6}
                       more='Ver más'
                       less='Ver menos'
                       className='content-css'
                       anchorClass='my-anchor-css-class'
                       onClick={this.executeOnClick}
                       expanded={false}
                       width={380}
                   >
                      <strong>Email del receptor:</strong>
                      <br/>
                      <p> {promotionTransferred.recipient_email}</p>
                      <br/>
                      <strong>Uid del miembro:</strong>
                      <br/>
                      <p> {promotionTransferred.member_uid}</p>
                   </ShowMoreText>
                   <ShowMoreText
                       lines={6}
                       more='Ver más'
                       less='Ver menos'
                       className='content-css'
                       anchorClass='my-anchor-css-class'
                       onClick={this.executeOnClick}
                       expanded={false}
                       width={380}
                   >
                      <p> {promotionTransferred.promotion.terms}</p>
                   </ShowMoreText>
                </div>

                <div className="col-lg-3">
                   {(promotionTransferred.available_uses > 0 && (promotionTransferred.promotion.required_stamps <= 0 ||
                       (promotionTransferred.promotion.required_stamps >= 0 && promotionTransferred.promotion.required_stamps <= promotionTransferred.promotion.available_stamps)))
                   && promotionTransferred.promotion.type !== "GIFT_CARD" &&
                   <button
                       onClick={(e) => this.showModalPromotion(e, promotionTransferred)}
                       className="btn btn-whitelabel-secondary btn-lg btn-block"
                   >
                      Validar ({promotionTransferred.available_uses})
                   </button>
                   }
                   {(promotionTransferred.available_uses > 0
                       && (promotionTransferred.promotion.required_stamps > 0
                           && promotionTransferred.promotion.required_stamps > promotionTransferred.promotion.available_stamps)) &&
                   <button
                       onClick={(e) => this.stampPromotion(e)}
                       className="btn btn-whitelabel-secondary btn-lg btn-block">
                      {(promotionTransferred.promotion.stamping === false) &&
                      <span>Estampar ({promotionTransferred.available_stamps} / {promotionTransferred.promotion.required_stamps})</span>
                      }
                      {isStamping === true &&
                      <span>Estampando...</span>
                      }
                   </button>
                   }
                   {(promotionTransferred.available_uses > 0 && (promotionTransferred.promotion.required_stamps > 0 && promotionTransferred.promotion.required_stamps <= promotionTransferred.promotion.available_stamps)) &&
                   <span>Usted ya tiene las suficientes estampas ( {promotionTransferred.promotion.available_stamps} )</span>
                   }
                   {
                      promotionTransferred.available_uses === 0 && promotionTransferred.promotion.type !== "GIFT_CARD" &&
                      <p>Promoción agotada,  {promotionTransferred.promotion.times_used ? `redimida${promotionTransferred.promotion.times_used}veces el usuario.` : "ya ha sido redimida. "}
                         Límite: {promotionTransferred.promotion.limit}</p>
                   }
                   {
                      promotionTransferred.promotion.type === "GIFT_CARD" && promotionTransferred.promotion.purchase==null &&
                      <p>El usuario no ha adquirido o no tiene crédito en este GiftCard</p>
                   }
                   {
                      promotionTransferred.promotion.type === "GIFT_CARD" && promotionTransferred.promotion.purchase!=null &&  promotionTransferred.promotion.purchase.total_balance === 0 &&
                      <p>A usted ya no le queda credito en esta promoción</p>
                   }
                   {
                      promotionTransferred.promotion.type === "GIFT_CARD" && promotionTransferred.promotion.purchase!=null &&  promotionTransferred.promotion.purchase.total_balance > 0 &&
                      <div><span className={"credit"}>Crédito:  { business && business.currency && business.currency.symbol } {promotionTransferred.promotion.purchase.total_balance}</span>
                         <button
                             onClick={(e) => this.showModalPromotion(e, promotionTransferred)}
                             className="btn btn-whitelabel-secondary btn-lg btn-block"
                         >
                            Redimir
                         </button> </div>
                   }
                </div>
             </div>
             <div className="line"/>
          </div>
      );
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      const {message, promotionTransferred} = this.props
      if(message.length!==0 && !this.state.showMessage){
         toast.success(message)
         this.setState({showMessage: true})
         let uidPlace = localStorage.getItem('placeIdRedeem')
         if (uidPlace!=="") {
            this.props.dispatch(getPromotionTransferred(uidPlace,  promotionTransferred.code.replace(" ","")));
         }
      }
   }

   render() {
      const {
         isFetching, promotionTransferred
      } = this.props;
      if (isFetching) {
         return (
            <div className="col-lg-12">
               <LoadingIndicator/>
            </div>
         );
      }
      if (promotionTransferred != null) {
         return (
            <div className="row">
               <div className="col-lg-12">
                  {this.renderPromotions()}
               </div>
               {this.state.selectedPromotionTransferred ? (<ModalPromotionTransferredDetail
                  showModal={active => this.setState({selectedPromotionTransferred: active ? this.state.selectedPromotionTransferred : null})}
                  promotionTransferred={promotionTransferred}
                  updateOrder={(status)=>console.log({status:status}, this.state.selectedPromotionTransferred.uid)}
               />) : null}
            </div>);
      }
      return (
         <div></div>
      );
   }
}

PromotionTransferredListItem.propTypes = {
   dispatch: PropTypes.func,
   isFetching: PropTypes.bool,
   promotionTransferred: PropTypes.object,
   business: PropTypes.object,
};
PromotionTransferredListItem.defaultProps = {
   promotionTransferred: null,
   isFetching: false,
   business: null,
};
export default connect(({promotionTransferred, promotions, user}) => ({
   promotionTransferred: promotionTransferred.formInitial,
   retrieveStatus: promotionTransferred.retrieveStatus,
   isStamping: promotions.isStamping,
   message: promotions.message,
   business: user.business
}))(PromotionTransferredListItem);
