import {
    CLEAN_EXPORT,
    CLEAN_HISTORY,
    DOWNLAND,
    HISTORY, SET_CURRENT_COMPANY_UID_HISTORY,
    SET_FIRST_PAGE_HISTORY,
    SET_NUMBER_PAGE_HISTORY
} from '../containers/History/constants';
import {normalize, schema} from "normalizr";

const redemptionsSchema = new schema.Entity('redemptions', {}, {idAttribute: 'uid'});
const redemptionsListSchema = new schema.Array(redemptionsSchema);

const initialState = {
  data: null,
  next: null,
  previous: null,
  isFetching: false,
  count: 0,
  error: null,
  exportFile: null,
  errorExportFile: null,
  isFetchingFile: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
      case SET_CURRENT_COMPANY_UID_HISTORY:
          return {
              ...state,
              currentCompanyUid: action.uid
          }
      case SET_FIRST_PAGE_HISTORY:
          return {
              ...state,
              firstList: action.list
          }
      case SET_NUMBER_PAGE_HISTORY:
          return {
              ...state,
              currentPageNumber: action.currentPageNumber
          }
    case `${HISTORY}_PENDING`:
      return { ...state, isFetching: true, next: null };
    case `${HISTORY}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${HISTORY}_FULFILLED`: {
      let newData = [];
      let data = [];
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.results !== []) {
          newData = action.payload.data.results;
          data = normalize(action.payload.data.results, redemptionsListSchema)

        }
      }
      return Object.assign({},
        state, {
          isFetching: false,
          entities: data.entities ? data.entities.redemptions : [],
          results: data.result ? data.result : [],
          data: newData,
          next: action.payload.data.next,
          previous: action.payload.data.previous,
          count: action.payload.data.count
        }
      );
    }
    case `${DOWNLAND}_PENDING`:
      return {
        ...state, isFetchingFile: true, exportFile: null, errorExportFile: null
      };
    case `${DOWNLAND}_REJECTED`:
      return {
        ...state, isFetchingFile: false, exportFile: null, errorExportFile: 'No se pudo traer el archivo'
      };
    case `${DOWNLAND}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.report !== null) {
            console.log(action.payload.data.report)
          return Object.assign({},
            state, {
              isFetchingFile: false,
              exportFile: action.payload.data.report,
              errorExportFile: ''
            }
          );
        }
      }
      return Object.assign({},
        state, {
          isFetchingFile: false,
          exportFile: null,
          errorExportFile: 'No se pudo traer el archivo'
        }
      );
    }
    case `${CLEAN_HISTORY}`:
      return Object.assign({},
        state, {
          isFetching: false,
          data: null,
          next: null,
          previous: null,
        }
      );

    case `${CLEAN_EXPORT}`:
      return Object.assign({},
        state, {
          isFetchingFile: false,
          exportFile: null,
          errorExportFile: ''
        }
      );
    default:
      return state;
  }
}
