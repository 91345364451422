import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';
import {getPromotionTransferred} from './actions';
import {LOADING_STATUS} from "../Bederr/constants";

class SearchPromotionTransferred extends Component {
   constructor(props) {
      super(props);
      this.state = {
         focused: null,
         error: '',
         promotion_code: '',
         place: null,
         uidPlace: "",
      };
   }

   componentDidMount() {
      this.updateComponent()
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      this.updateComponent()
   }
   updateComponent(){
      let uidPlace = localStorage.getItem('placeIdRedeem')
      if (uidPlace !== null) {
         if (this.state.uidPlace !== uidPlace ) {
            this.setState({uidPlace: uidPlace});
         }
      }
   }

   showError() {
      const {error} = this.props;
      if (error) return <div className="col-lg-12"><p
          className="field-error-message">{ error }</p></div>;
   }

   handleSearch = async (event) => {
      event.preventDefault();
      let uidPlace = localStorage.getItem('placeIdRedeem')
      if (uidPlace!=="") {
         this.props.dispatch(getPromotionTransferred(uidPlace,  this.state.promotion_code.replace(" ","")));
      }
   };



   render() {
      const {isFetching} = this.props;
      return (
          <div>
             <div className="container ">
                <div className="row search-section">
                   <form
                       className="col-lg-12 "
                       onSubmit={this.handleSearch}
                   >
                      <div className="row align-content-center">
                         <div className="col-12 col-lg-3 p-0 pr-lg-3" >
                            <input
                                value={"Código la promoción"}
                                className="form-control input-search"
                                type="search"
                                placeholder="Ingrese el código de la promoción"
                                aria-label="Search"
                                readOnly={true}
                                style={{background:"#fff"}}
                            />
                         </div >
                         <div className="col-12 col-lg-5 p-0 pr-lg-3" >
                            <input
                                value={this.state.promotion_code}
                                onChange={ e => {this.setState({promotion_code: e.target.value})}}
                                className="form-control input-search"
                                type="search"
                                placeholder="Ingrese el código"
                                aria-label="Search"
                            />
                         </div >

                         <div className="col-12 col-lg-3 p-0 pr-lg-3 d-flex flex-column ">
                            <button
                                type="submit"
                                className="btn btn-whitelabel-secondary btn-lg btn-block"
                            >
                               {
                                  isFetching === LOADING_STATUS && 'Buscando'
                               }
                               {
                                  isFetching !== LOADING_STATUS && 'Buscar'
                               }
                            </button>
                         </div>

                         <div className="col-lg-12 justify-content-center verify-client-steps">
                            <h5>Paso 1: Código</h5>
                            <p>Solicite el CÓDIGO al cliente para realizar la búsqueda</p>
                            <h5>Paso 2: Búsqueda</h5>
                            <p>Con el CÓDIGO, ingreselo en el buscador de arriba para comenzar la búsqueda y pueda redimirlo</p>
                         </div>
                      </div>
                   </form>
                   {
                      this.showError()
                   }
                </div>
             </div>
             <div className="line" />
          </div>
      );
   }
}

SearchPromotionTransferred.propTypes = {
   dispatch: PropTypes.func,
   isFetching: PropTypes.string,
   places: PropTypes.array,
   error: PropTypes.string,
   place: PropTypes.object,

};
SearchPromotionTransferred.defaultProps = {
   isFetching: '',
   places: null,
   place: null,
   error: '',

};
export default connect(({places, promotionTransferred}) => ({
   places: places.data,
   place: places.place,
   isFetching: promotionTransferred.retrieveStatus,
   error: promotionTransferred.error,
}))(SearchPromotionTransferred);
