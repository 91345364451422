import {CHANGE_ROOT, GET_PROGRAMS} from './constants';
import HttpClient from '../../utils/httpClient';
import {USER_PROGRAM} from '../Promotions/constants';


export function changeRootMenu(menu) {
  return {
    menu,
    type: CHANGE_ROOT,
  };
}

export function getPrograms(placeId) {
  return (dispatch) => HttpClient.get(dispatch, GET_PROGRAMS, `v5/me/company/places/${placeId}/programs/`);
}

export function setDefaultProgram(program) {
  return {
    program,
    type: USER_PROGRAM,
  };
}

