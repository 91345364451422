import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import {getByPage} from './actions';
import DateUtils from "../../utils/dateUtils";


class HistoryListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderHistory() {
    const { redemptions } = this.props;
    if (redemptions.length === 0) {
      return (
        <tr className="content-no-items">
          <td colSpan="11">
            Ups! , no pudimos encontrar Registros
          </td>
        </tr>
      );
    }
    return redemptions.map((register, index) => (
      <tr key={`register${index}`}>
        <td>{register.place ? (register.place && register.place.address ? register.place.address:"") :""}</td>
        <td>{DateUtils.getFormatDate(register.created_at)}</td>
        <td>{register.member?(register.member.first_name?register.place && register.place.address:""):""}</td>
        <td>{register.document_number?register.document_number:""}</td>
        <th>{register.program_promotion?(register.program_promotion.promotion_title?register.program_promotion.promotion_title:""):""}</th>
        <td>{register.account_balance?register.account_balance.subtotal:register.amount}</td>
        <td>{register.account_balance?register.account_balance.discount_by_retail:"--"}</td>
        <td>{register.account_balance?register.account_balance.discount_by_program:"--"}</td>

        <td>{register.account_balance?register.account_balance.subtotal_discount:"--"}</td>
        <th>{register.account_balance?register.account_balance.total:register.amount} </th>
      </tr>
    ));
  }

  toPage(e, page) {
    e.preventDefault();
    this.props.dispatch(getByPage(page));
  }

  previous(){
    const {getNumberPageHistory, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent-1)
    getNumberPageHistory(pageCurrent-1)
  }

  next(){
    const {getNumberPageHistory, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent+1)
    getNumberPageHistory(pageCurrent+1)
  }

  renderPagination() {
    const {count, getNumberPageHistory, pageCurrent, setPageCurrent} = this.props
    let nPage = Math.ceil(count/25)
    let pages = [];

    for (let i = 1; i < nPage+1; i++) {

      if(nPage+1>10){

        if(pageCurrent===i){
          if(pageCurrent===1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===3){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===4){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===5){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push(7)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent>5 && pageCurrent<nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
          }
          if(pageCurrent===nPage-1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
          }
          if(pageCurrent===nPage){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
          }
        }

      }else{
        pages.push(i)
      }
    }

    for(let i = pages.length -1; i >=0; i--){
      if(pages.indexOf(pages[i]) !== i){
        if(pages[i]!=="..."){
          pages.splice(i,1);
        }
      }
    }

    return pages.map((page, index) => (
        <li key={`page${index}`}><a href={"/#"}
                                    className={page===pageCurrent ? "active" : ""}
                                    onClick={(e)=>{
                                      e.preventDefault()
                                      if(page!==pageCurrent && page!=="..."){
                                        setPageCurrent(page)
                                        getNumberPageHistory(page)
                                      }
                                    }}
        >{page}</a></li>
    ));

  }

  render() {
    const {
      isFetching, next, previous,
      redemptions
    } = this.props;
    if (isFetching) {
      return (
        <div className="col-lg-12">
          <LoadingIndicator />
        </div>
      );
    }
    if (redemptions != null) {
      return (
        <div className="row">

          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Local</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Documento</th>
                    <th scope="col">Beneficio</th>
                    <th scope="col">Total sin Dscto</th>
                    <th scope="col">Dsct por empresa</th>
                    <th scope="col">Dsct por programa</th>
                    <th scope="col">Dsct</th>
                    <th scope="col">Total a pagar</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderHistory()}
                </tbody>
              </table>
            </div>
          </div>
          <div className={"d-flex justify-content-center mt-1 mb-3 mx-auto"}>
            <ul className="pagination">
              <li>
                <button className={previous===null ? "is-disabled": ""} disabled={previous===null} onClick={(e) => this.previous()}> {"<"} </button>
              </li>
              {this.renderPagination()}
              <li>
                <button className={next===null ? "is-disabled": ""} disabled={next===null} onClick={(e) => this.next()}> {">"} </button>
              </li>
            </ul>
          </div>
        </div>);
    }
    return (
      <div></div>
    );
  }
}

HistoryListItem.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  registers: PropTypes.array,
  next: PropTypes.string,
  previous: PropTypes.string,
};
HistoryListItem.defaultProps = {
  registers: null,
  isFetching: false,
  next: null,
  previous: null,
};
export default connect(({ history }) => ({
  registers: history.data,
  next: history.next,
  previous: history.previous,
  isFetching: history.isFetching
}))(HistoryListItem);
