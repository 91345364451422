import React, { Component } from "react";
import { Fragment } from "react";

export default class Promotion extends Component {
  render() {
    const { promotion, business } = this.props;
    
    const color =  promotion.color ==="" ? "#1abc9c" : promotion.color;
    return (
      <Fragment>
        <div className={"logo-promotions logo-promotions-normal"}>
            <h6>{promotion.program.name} </h6>
            <img src={promotion.program?.company_logo} alt="logo" />
          </div>
        <div className="DiscountBox">
          <div
            className="DiscountType"
            style={{
              background: `${
                color.includes("#")
                  ? color
                  : `#${color}`
              }`,
            }}
          >
            {promotion.promotion.type === "RATE" && (
              <>
                {promotion.promotion.rate_discount === 100 ? (
                  <>
                    <h1>GRA</h1>
                    <h1>TIS</h1>
                  </>
                ) : (
                  <>
                    <h1>{promotion.promotion.rate_discount}%</h1>
                    <h2>de&nbsp;dscto.</h2>
                  </>
                )}
              </>
            )}
            {promotion.promotion.type === "GIFT" && (
              <>
                    <h1>GRA</h1>
                    <h1>TIS</h1>
              </>
            )}
            {promotion.promotion.type === "SPECIAL_PRICE" && (
              <>
                    <h1>ESPE</h1>
                    <h1>CIAL</h1>
              </>
            )}
            {promotion.promotion.type === "FIXED" && (
              <>
                {promotion.promotion.fixed_discount && (
                  <>
                    {business?.currency.symbol && (
                      <h1>{business.currency.symbol}</h1>
                    )}
                    <h1>
                      {promotion.promotion.fixed_discount.replace(".", ",")}
                    </h1>
                  </>
                )}
              </>
            )}
          </div>
          <div className="DiscountDateBox">
            <h1>{promotion?.promotion.title}</h1>
            <p>{promotion?.promotion.content}</p>
            <div
              className="DiscountDate"
              style={{
                background: `${
                  color.includes("#")
                    ? color
                    : `#${color}`
                }`,
              }}
            ></div>
          </div>
        </div>
      </Fragment>
    );
  }
}
