import React from 'react';
import './style.scss';

const VerifyClientStepsPhone = () => (
  <div className="col-lg-12 justify-content-center verify-client-steps">
    <h5>Paso 1: Número Telefónico</h5>
    <p>Solicite el número telefónico del cliente (formato internacional + código del país) antes de hacer la búsqueda para verificar si es miembro</p>
    <h5>Paso 2: Búsqueda</h5>
    <p>Ingrese en la barra de arriba el el número telefónico para comenzar la búsqueda</p>
  </div>
);

export default VerifyClientStepsPhone;
