

export const CHANGE_ROOT = 'CHANGE_ROOT';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const LOADING_STATUS = 'LOADING_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const ERROR_STATUS = 'ERROR_STATUS';


export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';
export const STATUS_INCLUDE = 'include';
export const STATUS_LOADING = 'loading';

