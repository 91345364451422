import {CLIENT, DNI_CLIENT, CLIENT_QR} from '../containers/Promotions/constants';
import {CREATE_CLIENT, RESET_CREATE_CLIENT} from '../containers/CreateUser/constants';


const initialState = {
   error: null,
   isFetching: false,
   errorCreate: null,
   isFetchingCreate: false,
   clientCreate: null,
   dni: null,
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${CLIENT}_PENDING`:
         return {
            ...state,
            isFetching: true,
            client: null,
         };
      case `${CLIENT}_REJECTED`:
         return {
            ...state,
            isFetching: false,
            client: null,
            error: 'Algo salió mal, inténtelo nuevamente',
         };
      case `${CLIENT}_FULFILLED`: {
         if (action.payload === undefined) {
            return Object.assign({},
               state, {
                  isFetching: false,
                  client: null,
                  error: 'Este documento no se encuentra dentro de nuestra Base de Datos',
               },
            );
         }
         if(action.payload.status===400 && action.payload.data !== undefined){
             return Object.assign({},
                 state, {
                     isFetching: false,
                     client: null,
                     error: "Este número no se encuentra dentro de nuestra Base de Datos",
                 },
             );
         }
         window.localStorage.setItem('companyIdRedeem', action.payload.data);
         if(Array.isArray(action.payload.data)){
            let uniqueClients = [];
            let obj = {};
            let clients = action.payload.data
            let len = clients.length

            for ( let i=0; i < len; i++ )
               obj[clients[i]['document_number']] = clients[i];

            for ( let key in obj )
               uniqueClients.push(obj[key]);

            return Object.assign({},
                state, {
                   isFetching: false,
                   client: uniqueClients,
                   error: '',
                },
            );
         }else{
            return Object.assign({},
                state, {
                   isFetching: false,
                   client: action.payload.data,
                   error: '',
                },
            );
         }
      }
      case `${CLIENT_QR}_PENDING`:
         return {
            ...state,
            isFetching: true,
                clientQR: null,
         };
      case `${CLIENT_QR}_REJECTED`:
         return {
            ...state,
            isFetching: false,
             clientQR: null,
            error: 'Algo salió mal, inténtelo nuevamente',
         };
      case `${CLIENT_QR}_FULFILLED`: {
         if (action.payload === undefined) {
            return Object.assign({},
               state, {
                  isFetching: false,
                    clientQR: null,
                  error: 'Este documento no se encuentra dentro de nuestra Base de Datos',
               },
            );
         }
         if(action.payload.status===400 && action.payload.data !== undefined){
             return Object.assign({},
                 state, {
                     isFetching: false,
                     clientQR: null,
                     error: "Este número no se encuentra dentro de nuestra Base de Datos",
                 },
             );
         }
         window.localStorage.setItem('companyIdRedeem', action.payload.data);
         if(Array.isArray(action.payload.data)){
            let uniqueClients = [];
            let obj = {};
            let clients = action.payload.data
            let len = clients.length

            for ( let i=0; i < len; i++ )
               obj[clients[i]['document_number']] = clients[i];

            for ( let key in obj )
               uniqueClients.push(obj[key]);

            return Object.assign({},
                state, {
                   isFetching: false,
                    clientQR: uniqueClients,
                   error: '',
                },
            );
         }else{
            return Object.assign({},
                state, {
                   isFetching: false,
                    clientQR: action.payload.data,
                   error: '',
                },
            );
         }
      }

      case `${CREATE_CLIENT}_PENDING`:
         return {
            ...state,
            isFetchingClient: true,
            clientCreate: null,
         };
      case `${CREATE_CLIENT}_REJECTED`:
         return {
            ...state,
            isFetchingClient: false,
            clientCreate: null,
            errorCreate: 'Algo salió mal, inténtelo nuevamente',
         };
      case `${CREATE_CLIENT}_FULFILLED`: {
         if (action.payload === undefined) {
            return Object.assign({},
               state, {
                  isFetchingClient: false,
                  client: null,
                  errorCreate: 'Algo ocurrio mal',
               },
            );
         } else if (action.payload.status === 200 || action.payload.status === 201) {
            return Object.assign({},
               state, {
                  isFetchingClient: false,
                  clientCreate: action.payload.data,
                  errorCreate: '',
               },
            );
         } else if (action.payload.data !== undefined && action.payload.data !== null) {
            let error = '';
            for (let key of Object.keys(action.payload.data)) {
               for(let index =0 ; index < action.payload.data[key].length; index++ ){
                  error += `${action.payload.data[key][index]}`;
               }

            }
            return Object.assign({},
               state, {
                  isFetchingClient: false,
                  clientCreate: null,
                  errorCreate: error,
                  error: '',
               },
            );
         }

         return Object.assign({},
            state, {
               isFetchingClient: false,
               clientCreate: null,
               errorCreate: 'No se pudo crear el usuario correctamente',
               error: '',
            },
         );
      }
      case `${DNI_CLIENT}`: {
         return {
            ...state,
            dni: action.dni,
         };
      }
      case `${RESET_CREATE_CLIENT}`: {
         return {
            isFetchingClient: false,
            clientCreate: null,
            errorCreate: '',
         };
      }

      default:
         return state;
   }
}
