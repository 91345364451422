import moment from 'moment';
import HttpClient from '../../utils/httpClient';
import {
  CLEAN_ORDER_STATUS,
  CLEAN_ORDERS,
  NEXT_ORDERS,
  ORDER_DETAIL,
  ORDERS,
  PATCH_ORDER,
  SET_CURRENT_PLACE_UID_ORDERS, SET_FIRST_PAGE_ORDERS, SET_NUMBER_PAGE_ORDERS
} from "./constants";
export const UPDATE_RETRIEVE_ORDER_URL = 'v5/platform/orders/{order_uid}/'

export function getOrders( placeId, date, typePayment, search) {
  let params = '';
  if (date != null) {
    try{
      const dateStr = moment(date).format('YYYY-MM-DD');
      if (params.length > 0) {
        params = `${params}&`;
      }
      params = `${params}date=${dateStr}`;
    }catch (ignored) {
    }
  }
  if (search !== null && search !== "null" && search.length > 0) {
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}number=${search}`;
  }
  if (typePayment !== "null" && typePayment !== null && typePayment.length > 0) {
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}payment_method=${typePayment}`;
  }
  if (params.length > 0) {
    params = `?${params}`;
  }
  return (dispatch) => HttpClient.get(dispatch, ORDERS, `v5/platform/places/${placeId}/orders/${params}`);
}

export function getNumberPageOrders( placeId, date, typePayment, search, numberPage) {
  let params = '';
  if (date != null) {
    try{
      const dateStr = moment(date).format('YYYY-MM-DD');
      if (params.length > 0) {
        params = `${params}&`;
      }
      params = `${params}date=${dateStr}`;
    }catch (ignored) {
    }
  }
  if (search !== null && search !== "null" && search.length > 0) {
    console.log(search,"--")
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}number=${search}`;
  }
  if (typePayment !== "null" && typePayment !== null && typePayment.length > 0) {
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}payment_method=${typePayment}`;
  }
  if (params.length > 0) {
    params = `?${params}`;
  }
  if (numberPage != null) {
      if (params.length > 0) {
        params = `${params}&`;
      }
      params = `${params}page=${numberPage}`;
  }

  return (dispatch) => HttpClient.get(dispatch, ORDERS, `v5/platform/places/${placeId}/orders/?${params}`);
}


export function retrieveOrder( orderUid) {
  return (dispatch) => HttpClient.get(
     dispatch,
     ORDER_DETAIL,
     UPDATE_RETRIEVE_ORDER_URL.replace("{order_uid}", orderUid)
  );
}

export function updateOrder(data, orderUid) {
  return (dispatch) => HttpClient.patch(
     data,
     dispatch,
     PATCH_ORDER,
     UPDATE_RETRIEVE_ORDER_URL.replace("{order_uid}", orderUid)
  );
}

export function getByPage(page) {
  return (dispatch) => HttpClient.getUrl(dispatch, NEXT_ORDERS, page);
}

export function cleanHistory() {
  return {
    type: CLEAN_ORDERS,
  };
}

export function cleanOrderStatus() {
  return {
    type: CLEAN_ORDER_STATUS,
  };
}



export function setNumberPageOrder(currentPageNumber) {
  return {
    type: SET_NUMBER_PAGE_ORDERS,
    currentPageNumber
  };
}

export function setFirstListOrders(list) {
  return {
    type: SET_FIRST_PAGE_ORDERS,
    list
  };
}

export function setPlaceUidCurrentOrders(uid) {
  return {
    type: SET_CURRENT_PLACE_UID_ORDERS,
    uid
  };
}
