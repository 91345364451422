import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {changeRootMenu} from '../Bederr/actions';
import OrdersListItem from "./OrdersListItem";
import SearchOrders from "./SearchOrders";
import {getNumberPageOrders, setFirstListOrders, setNumberPageOrder, setPlaceUidCurrentOrders} from "./actions";
import moment from "moment";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
        pageCurrent: 1,
        firstList: "",
        date: moment(),
        number: '',
    };
  }


  componentDidMount() {
    this.props.dispatch(changeRootMenu('ORDENES'));
  }

    componentDidUpdate() {
        const {dispatch, firstList, orders, currentPageNumber, listStatus, currentPlaceUid} = this.props

        let currentPlaceUidOrders = localStorage.getItem("placeUid") ? localStorage.getItem("placeUid") : ''

        if(this.state.pageCurrent !== currentPageNumber){
            this.setState({pageCurrent: currentPageNumber})
        }


        if(currentPlaceUidOrders!==currentPlaceUid){
            dispatch(setNumberPageOrder(1))
            dispatch(setPlaceUidCurrentOrders(currentPlaceUidOrders))
        }

        let aux = []
        if(listStatus==="success"){
            orders.map(c=>{
                aux.push(c.uid)
                return true
            })
        }

        if(listStatus==="success" && firstList!==JSON.stringify(aux) && currentPageNumber===1){
            this.setState({firstList: JSON.stringify(aux)})
            dispatch(setFirstListOrders(JSON.stringify(aux)))
            dispatch(setNumberPageOrder(1))
        }
    }

  render() {
      const {
          count, history,
          next, previous,
          listStatus,
          results,
          entities,
          currentPlaceUid
      } = this.props;
    if(localStorage.getItem('placeIdRedeem')===null || localStorage.getItem('placeIdRedeem')==="")
      return (
         <div>
           <div className="container" >
             <p>Escoger un local</p>
           </div>
         </div>
      );
    return (
      <div>
        <SearchOrders
            setPageCurrent={pageCurrent=> {
                this.setState({pageCurrent: pageCurrent})
                this.props.dispatch(setNumberPageOrder(pageCurrent))
            }}
            setNumber={number=> {
                this.setState({number: number})
            }}
            setDate={date=> {
                this.setState({date: date})
            }}/>
        <div className="container" >
          <OrdersListItem
              number={this.state.number}
              date={this.state.date}
              next={next}
              previous={previous}
              listOrderStatus={listStatus}
              currentPlaceUid={currentPlaceUid}
              getNumberPageOrders={(uid, page) => this.props.dispatch(getNumberPageOrders(uid, this.state.date, localStorage.getItem('paymentTypeSearchOrders'), this.state.number, page))}
              orders={results && entities && results.map(pUid => entities[pUid])}
              count={count}
              history={history}
              pageCurrent={this.state.pageCurrent}
              setPageCurrent={pageCurrent=> {
                  this.setState({pageCurrent: pageCurrent})
                  this.props.dispatch(setNumberPageOrder(pageCurrent))
              }}
          />
        </div>
      </div>
    );
  }
}
Orders.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  place: PropTypes.object
};
Orders.defaultProps = {
  match: null,
  place: null,
};
export default connect(({ places, orders}) => ({
  place : places.place,
    next: orders.next,
    previous: orders.previous,
    firstList: orders.firstList,
    currentPlaceUid: orders.currentPlaceUid,
    currentPageNumber: orders.currentPageNumber,
    count: orders.count,
    listStatus: orders.listStatus,
    results: orders.results,
    entities: orders.entities,
}))(Orders);
