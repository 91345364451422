import moment from 'moment';
import {CLEAN_EXPORT, CLEAN_HISTORY, DOWNLAND, HISTORY, SET_NUMBER_PAGE_HISTORY, SET_FIRST_PAGE_HISTORY, SET_CURRENT_COMPANY_UID_HISTORY} from './constants';
import HttpClient from '../../utils/httpClient';


export function getHistory(companyId, placeId, date) {
  let params = '';
  if (placeId != null && placeId.length > 0) {
    params = `place_uid=${placeId}`;
  }
  if (date != null) {
    const dateStr = moment(date).format('DD-MM-YYYY');
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}date=${dateStr}`;
  }
  if (params.length > 0) {
    params = `?${params}`;
  }
  return (dispatch) => HttpClient.get(dispatch, HISTORY, `v5/companies/${companyId}/redemptions/${params}`);
}
export function getNumberPageHistory(companyId, placeId, date, numberPage) {
  let params = '';
  if (placeId != null && placeId.length > 0) {
    params = `place_uid=${placeId}`;
  }
  if (date != null) {
    const dateStr = moment(date).format('DD-MM-YYYY');
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}date=${dateStr}`;
  }
  if (params.length > 0) {
    params = `?${params}`;
  }
  if (numberPage != null) {
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}page=${numberPage}`;
  }

  return (dispatch) => HttpClient.get(dispatch, HISTORY, `v5/companies/${companyId}/redemptions/${params}`);
}


export function exportHistory(place, date) {
  let params = '';
  if (place != null && place.length > 0) {
    params = `place=${place}`;
  }
  if (date != null) {
    const dateStr = moment(date).format('DD-MM-YYYY');
    if (params.length > 0) {
      params = `${params}&`;
    }
    params = `${params}date=${dateStr}`;
  }
  if (params.length > 0) {
    params = `?${params}`;
  }
  console.log(`v4/corporate-offers/applications/export/${params}`)
  return (dispatch) => HttpClient.get(dispatch, DOWNLAND, `v4/corporate-offers/applications/export/${params}`);
}

export function getByPage(page) {
  return (dispatch) => HttpClient.getUrl(dispatch, HISTORY, page);
}

export function cleanHistory() {
  return {
    type: CLEAN_HISTORY,
  };
}

export function cleanExport() {
  return {
    type: CLEAN_EXPORT,
  };
}



export function setNumberPageHistory(currentPageNumber) {
  return {
    type: SET_NUMBER_PAGE_HISTORY,
    currentPageNumber
  };
}

export function setFirstListHistory(list) {
  return {
    type: SET_FIRST_PAGE_HISTORY,
    list
  };
}

export function setCompanyUidCurrentHistory(uid) {
  return {
    type: SET_CURRENT_COMPANY_UID_HISTORY,
    uid
  };
}
