import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import {cleanOrderStatus, getByPage, retrieveOrder, updateOrder} from './actions';
import moment from "moment";
import ModalOrderDetail from "./ModalOrderDetail";
import Render from "../../utils/help";


class OrdersListItem extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedOrder: null,
      };
   }

   renderHistory() {
      const {orders} = this.props;
      if (orders.length === 0) {
         return (
            <tr className="content-no-items">
               <td colSpan="11">
                  Ups! , no pudimos encontrar Registros
               </td>
            </tr>
         );
      }
      return orders.map((order, index) => (
         <tr key={`register${index}`}>
            <td> <button type={'button'}
                         onClick={e => this.setState({selectedOrder: order})}
                         className={'btn btn-whitelabel-secondary btn-sm'}>{order.number}</button></td>
            <td>{order.created_at ?  `${moment(order.created_at).format('DD/MM/YYYY')}` : '--'}</td>
            <td>{order.user ? (`${order.user.first_name} ${order.user.last_name}`) : ""}</td>

            <td>
               <span
                   className={order.delivery_method ? `badge badge-light ${order.delivery_method.type}` : 'badge badge-light '}>
                  {order.delivery_method ? `${order.delivery_method.name}` : ''}
               </span>
            </td>
            <td>{order.delivery_method ? (order.delivery_method.type === 'pickup' ? `${moment(order.pickup_scheduled_date).format('DD/MM/YYYY')}` : '--') : '--'}</td>
            <td>
               <span
                className={order.payment_method ? `badge badge-light ${order.payment_method.type}` : 'badge badge-light '}>
               {order.payment_method ? `${order.payment_method.name}` : ''}
               </span>
            </td>
            <td>
               <span
                  className={`badge badge-light ${order.status}`}>
                  {Render.renderStatus(order.status)}
               </span>
            </td>
            <td>{order.currency ? order.currency.symbol : "S/."} {order.total}</td>
         </tr>
      ));
   }

   toPage(e, page) {
      e.preventDefault();
      this.props.dispatch(getByPage(page));
   }

   previous(){
      const {getNumberPageOrders, pageCurrent, setPageCurrent} = this.props
      setPageCurrent(pageCurrent-1)
      getNumberPageOrders(localStorage.getItem("placeIdRedeem"), pageCurrent-1)
   }

   next(){
      const {getNumberPageOrders, pageCurrent, setPageCurrent} = this.props
      setPageCurrent(pageCurrent+1)
      getNumberPageOrders(localStorage.getItem("placeIdRedeem"), pageCurrent-1)
   }

   renderPagination() {
      const {count, getNumberPageOrders, pageCurrent, setPageCurrent} = this.props
      let nPage = Math.ceil(count/25)
      let pages = [];

      for (let i = 1; i < nPage+1; i++) {

         if(nPage+1>10){

            if(pageCurrent===i){
               if(pageCurrent===1){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent===2){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push(4)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent===3){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push(4)
                  pages.push(5)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent===4){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push(4)
                  pages.push(5)
                  pages.push(6)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent===5){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push(4)
                  pages.push(5)
                  pages.push(6)
                  pages.push(7)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent>5 && pageCurrent<nPage-2){
                  pages.push(1)
                  pages.push(2)
                  pages.push("...")
                  pages.push(pageCurrent-2)
                  pages.push(pageCurrent-1)
                  pages.push(pageCurrent)
                  pages.push(pageCurrent+1)
                  pages.push(pageCurrent+2)
                  pages.push("...")
                  pages.push(nPage-2)
                  pages.push(nPage-1)
                  pages.push(nPage)
               }
               if(pageCurrent===nPage-2){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push("...")
                  pages.push(pageCurrent-1)
                  pages.push(pageCurrent)
                  pages.push(pageCurrent+1)
                  pages.push(pageCurrent+2)
               }
               if(pageCurrent===nPage-1){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push("...")
                  pages.push(pageCurrent-1)
                  pages.push(pageCurrent)
                  pages.push(pageCurrent+1)
               }
               if(pageCurrent===nPage){
                  pages.push(1)
                  pages.push(2)
                  pages.push(3)
                  pages.push("...")
                  pages.push(pageCurrent-2)
                  pages.push(pageCurrent-1)
                  pages.push(pageCurrent)
               }
            }

         }else{
            pages.push(i)
         }
      }

      for(let i = pages.length -1; i >=0; i--){
         if(pages.indexOf(pages[i]) !== i){
            if(pages[i]!=="..."){
               pages.splice(i,1);
            }
         }
      }

      return pages.map((page, index) => (
          <li key={`page${index}`}><a href={"/#"}
                                      className={page===pageCurrent ? "active" : ""}
                                      onClick={(e)=>{
                                         e.preventDefault()
                                         if(page!==pageCurrent && page!=="..."){
                                            setPageCurrent(page)
                                            getNumberPageOrders(localStorage.getItem("placeIdRedeem"), page)
                                         }
                                      }}
          >{page}</a></li>
      ));

   }

   render() {
      const {
         dispatch, isFetching, orders, next, previous,retrieveStatus,editStatus, order
      } = this.props;
      if (isFetching) {
         return (
            <div className="col-lg-12">
               <LoadingIndicator/>
            </div>
         );
      }
      if (orders != null) {
         return (
            <div className="row">

               <div className="col-lg-12">
                  <div className="table-responsive">
                     <table className="table table-striped">
                        <thead>
                        <tr>
                           <th scope="col">Número</th>
                           <th scope="col">Fecha de Pedido</th>
                           <th scope="col">Nombre</th>
                           <th scope="col">Tipo de envío</th>
                           <th scope="col">Fecha de Recojo</th>
                           <th scope="col">Tipo de pago</th>
                           <th scope="col">Estado</th>
                           <th scope="col">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderHistory()}
                        </tbody>
                     </table>
                  </div>
               </div>
               <div className={"d-flex justify-content-center mt-1 mb-3 mx-auto"}>
                  <ul className="pagination">
                     <li>
                        <button className={previous===null ? "is-disabled": ""} disabled={previous===null} onClick={(e) => this.toPage(e, previous)}> {"<"} </button>
                     </li>
                     {this.renderPagination()}
                     <li>
                        <button className={next===null ? "is-disabled": ""} disabled={next===null} onClick={(e) => this.toPage(e, next)}> {">"} </button>
                     </li>
                  </ul>
               </div>
               {this.state.selectedOrder ? (<ModalOrderDetail
                  showModal={active => this.setState({selectedOrder: active?this.state.order:null})}
                  retrieveStatus={retrieveStatus}
                  order={order}
                  getOrder={()=>dispatch(retrieveOrder(this.state.selectedOrder.uid))}
                  updateOrder={(status)=>dispatch(updateOrder({status:status}, this.state.selectedOrder.uid))}
                  cleanOrderStatus={()=>dispatch(cleanOrderStatus())}
                  editStatus={editStatus}
               />) : null}
            </div>);
      }
      return (
         <div></div>
      );
   }
}

OrdersListItem.propTypes = {
   dispatch: PropTypes.func,
   isFetching: PropTypes.bool,
   orders: PropTypes.array,
   next: PropTypes.string,
   previous: PropTypes.string,
};
OrdersListItem.defaultProps = {
   orders: null,
   isFetching: false,
   next: null,
   previous: null,
};
export default connect(({orders}) => ({
   orders: orders.data,
   order: orders.formInitial,
   retrieveStatus: orders.retrieveStatus,
   editStatus: orders.editStatus,
   next: orders.next,
   previous: orders.previous,
   isFetching: orders.isFetching,
}))(OrdersListItem);
