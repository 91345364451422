/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducersapp accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOGIN = 'LOGIN';
export const VERIFY = 'VERIFY';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const BUSINESS_LOGIN = 'BUSINESS_LOGIN';
export const RESET_BUSINESS = 'RESET_BUSINESS';
export const RESET_USER = 'RESET_USER';

export const BTN_LOGIN = 'Ingresar';
export const BTN_LOGIN_LOADING = 'Cargando...';