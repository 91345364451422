import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./style.scss";
import { selectClient } from "./actions";
import ModalAddPoints from "./ModalAddPoints";
import CoinSvg from "../../images/Coin.svg"

class LevelProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showModalPoints(e, client) {
    e.preventDefault();
    this.props.dispatch(selectClient(client));
  }

  render() {
    const { isFetching, programRetail, clientSelect, clientProgram,client,typeSearch,dataInput } =
      this.props;
    console.log(clientSelect, "clientProgram")

    //console.log(this.props, "level");
    if (isFetching) {
      return (
        <div className="col-lg-12 program">
          <p className="text">Actualizando programa asociado al retail</p>
          <div className="line" />
        </div>
      );
    }
    if (programRetail != null) {
      let colorLevel = "#1ABC9C";
      let percent = "0%";
      let remainingPoints = 0;
      let message = "";

      if (
        programRetail.member !== undefined &&
        programRetail.member !== null &&
        programRetail.member.level !== undefined &&
        programRetail.member.level !== null
      ) {
        if (
          programRetail.member.level.color !== undefined &&
          programRetail.member.level.color != null
        ) {
          colorLevel = programRetail.member.level.color;
        }
        let points = programRetail.member.level.max_points;
        if (points <= 0) {
          points = 1;
        }
        percent = `${(programRetail.member.points * 100) / points}%`;
        remainingPoints =
          programRetail.member.level.max_points !== null &&
          programRetail.member.level.max_points - programRetail.member.points;

        if (remainingPoints < 0) {
          message = `Actualmente el usuario está en el nivel ${programRetail.member.level.title} con ${programRetail.member.points} puntos`;
        } else {
          message = `Actualmente el usuario está en el nivel ${programRetail.member.level.title} con ${programRetail.member.points} puntos y te le faltan ${remainingPoints} puntos para el siguiente`;
        }
      }
      return (
        <div className="col-lg-12 program">
          <p className="text d-none">
            {programRetail.member && programRetail.member.level !== undefined
              ? message
              : "El usuario no se encuentra en ningún nivel"}
          </p>
          <div className={"col-12 d-flex flex-md-row flex-column p-0"}>
            <div className={"col-md-9 col-12 d-flex p-0"}>
              {programRetail.member &&
                  programRetail.member.level !== undefined &&
                  programRetail.member.level !== null && (
                      <div className="container-progress-level">
                        <div className="progress-level-box">
                          <div className={"d-flex flex-column box-level"}>
                            <div className="info-level">
                              <h5>{programRetail.member.level.title}</h5>
                              {programRetail.member.level.max_points !== null ? (
                                  <h6>
                                    Faltan{" "}
                                    {programRetail.member.level.max_points -
                                        programRetail.member.points}
                                    pts para pasar al siguiente nivel{" "}
                                  </h6>
                              ) : (
                                  <h6>Tienes {programRetail.member.points}pts </h6>
                              )}
                              {/* <h6>{programRetail.member.level.max_points!==null ? programRetail.member.level.max_points - programRetail.member.points  : programRetail.member.points }</h6> */}
                            </div>
                            {colorLevel}
                            <div className="progress">
                              <div
                                  className="progress-bar-2"
                                  role="progressbar"
                                  style={{
                                    width: percent,
                                    backgroundColor: `${colorLevel}`
                                  }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                              />
                            </div>
                          </div>
                          <h5> Avance {parseInt(percent)}% </h5>
                        </div>
                      </div>
                  )}
            </div>
            <div className={"col-md-3 div-modal-points col-12  d-flex align-items-center justify-content-end pr-0"}>
              <div className="btn-modal-points text-center" onClick={(e) => this.showModalPoints(e, clientProgram)}>
                Registrar consumo
              </div>
            </div>
          </div>
          {clientSelect !== null ? <ModalAddPoints /> : null}
          <div className="client-info">
              <div>
                <h5>Cliente: <span>{Object.keys(client).includes("0") ? client[0].first_name : client?.first_name}</span> </h5>
                <h5>{typeSearch}: <span>{dataInput}</span> </h5>
              </div>
              <div>

                <div className="coin-container-menu">
                  <h4>
                    {" "}
                    <span>
                  <img src={CoinSvg} alt="coin" />{" "}
                </span>{" "}
                    {(programRetail && programRetail.member && programRetail.member.coins) ? programRetail.member.coins : "0"}{" "}
                  </h4>
                </div>

              </div>
              <div className="btn-modal-points d-none" onClick={(e) => this.showModalPoints(e, clientProgram)}>
              Sumar Puntos
              </div>
          </div>
          
        </div>
      );
    }
    return <div />;
  }
}

LevelProgram.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  programRetail: PropTypes.object,
  clientSelect: PropTypes.object,
  clientProgram: PropTypes.object,
};
LevelProgram.defaultProps = {
  programRetail: null,
  clientSelect: null,
  clientProgram: null,
  isFetching: false,
};
export default connect(({ program, points }) => ({
  programRetail: program.data,
  isFetching: program.isFetching,
  clientSelect: points.client,
  clientProgram: program.client,
}))(LevelProgram);
