

export const HISTORY = 'HISTORY';
export const CLEAN_HISTORY = 'CLEAN_HISTORY';
export const CLEAN_EXPORT = 'CLEAN_EXPORT';
export const NEXT_HISTORY = 'NEXT_HISTORY';
export const DOWNLAND = 'DOWNLAND';
export const SET_CURRENT_COMPANY_UID_HISTORY = 'SET_CURRENT_COMPANY_UID_HISTORY';
export const SET_FIRST_PAGE_HISTORY = 'SET_FIRST_PAGE_HISTORY';
export const SET_NUMBER_PAGE_HISTORY = 'SET_NUMBER_PAGE_HISTORY';

