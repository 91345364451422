import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {changeRootMenu} from '../Bederr/actions';
import PromotionTransferredListItem from "./PromotionTransferredListItem";
import SearchPromotionTransferred from "./SearchPromotionTransferred";

class PromotionTransferred extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentDidMount() {
    this.props.dispatch(changeRootMenu('PROMOCIONES TRANFERIDAS'));
  }

  render() {
    if(localStorage.getItem('placeIdRedeem')===null || localStorage.getItem('placeIdRedeem')==="")
      return (
         <div>
           <div className="container" >
             <p>Escoger un local</p>
           </div>
         </div>
      );
    return (
      <div>
        <SearchPromotionTransferred />
        <div className="container" >
          <PromotionTransferredListItem />
        </div>
      </div>
    );
  }
}
PromotionTransferred.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  place: PropTypes.object
};
PromotionTransferred.defaultProps = {
  match: null,
  place: null,
};
export default connect(({ places}) => ({
  place : places.place
}))(PromotionTransferred);
