import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import Promotions from '../Promotions';
import MainMenu from '../MainMenu';
import History from '../History';
import CreateUser from '../CreateUser';
import LoadingIndicator from "../../components/LoadingIndicator";
import {searchBusiness} from "../Login/actions";
import {Whitelabel} from "../../utils/generic";
import Orders from "../Orders/Orders";
import history from "../../utils/history";
import ListPlaces from "../ListPlaces";
import {toast, ToastContainer} from "react-toastify";
import PromotionTransferred from "../Transferred/PromotionTransferred";


class Bederr extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   componentDidMount() {
      let {business} = this.props;
      let subdomain = window.localStorage.getItem(`subdomain`);


      toast.configure();
      if (business == null)
         if (subdomain !== undefined && subdomain !== null && subdomain.length > 0)
            this.props.dispatch(searchBusiness(subdomain))
         else
            this.closeSession()
   }

   componentWillReceiveProps(nextProps, nextContext) {
      const { business } = nextProps;
      if (business != null) {
         window.localStorage.setItem(`apiKey`, business.api_key);
         window.localStorage.setItem(`subdomain`, business.domain.subdomain);
      }else{
         this.closeSession()
      }
   }

   closeSession =  () => {
      const NAME = Whitelabel.name;
      window.localStorage.removeItem(`token${NAME}`);
      window.localStorage.removeItem('placeIdRedeem');
      window.localStorage.removeItem('companyIdRedeem');
      window.localStorage.removeItem('subdomain');
      document.location.href = '/';
   };

   render() {
      let {business} = this.props;
      if (business == null)
         return (<LoadingIndicator/>)
      return (
         <div>
            <div className="content">
               <div id="header" className="main-header flex-column flex-md-row sticky-top">
                  <MainMenu history={history}/>
               </div>
               <div className="container-fluid" style={{minWidth: "400px"}}>
                  <Switch>
                     <Route exact path="/" component={Promotions}/>
                     <Route exact path="/places" component={ListPlaces}/>
                     <Route exact path="/historial" component={History}/>
                     <Route exact path="/orders" component={Orders}/>
                     <Route exact path="/transferred" component={PromotionTransferred}/>
                     <Route exact path="/createuser" component={CreateUser}/>
                     <Route exact path="/historial/:page" component={History}/>
                  </Switch>
               </div>
            </div>

            <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
            />
         </div>
      );
   }
}

Bederr.propTypes = {
   dispatch: PropTypes.func,
   match: PropTypes.object,
   business: PropTypes.object,
};
History.defaultProps = {
   match: null,
   business: null,
};

export default connect(({user}) => ({
   business: user.business
}))(Bederr);

