import {
   ACCEPTED,
   CANCELED_BY_PLACE,
   CANCELED_BY_USER,
   DELIVERED,
   RECEIVED,
   SHIPPED,
   ACCEPTED2
} from "../containers/Orders/constants";

export default class Render {
   static renderStatus(status){
      switch (status) {
         case RECEIVED: return "Orden recibida";
         case DELIVERED: return "Orden entregada";
         case CANCELED_BY_PLACE: return "Orden Cancelada";
         case CANCELED_BY_USER: return "Orden Cancelada por el usuario";
         case ACCEPTED: return "Orden aceptada";
         case ACCEPTED2: return "Orden aceptada";
         case SHIPPED: return "Orden enviada";
         default: return '--'
      }
   }


}
