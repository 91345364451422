import React from 'react';
import PropTypes from 'prop-types';
import {ERROR_STATUS, LOADING_STATUS} from "../Bederr/constants";
import moment from "moment";
import LoadingIndicator from "../../components/LoadingIndicator";
import 'moment/locale/es';
import "./style.scss"
import {applied, stampedQR} from "./actions";
import triangle from "./components/triangle.png";


export default class PromotionData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            error: '',
            mount: 0.0,
            discount: 0.0,
            total: 0.0,
            textMount: '',
            showTerms: true
        };
    }

    componentDidMount() {
        let {getProgramPromotion, getClient} = this.props
        getProgramPromotion()
        getClient()
    }


    handleMount = async (event) => {
        event.preventDefault()
        const {promotion: offer, business} = this.props;
        let amount = event.target.value;
        let currencySymbol = "S/"
        if (business && business.currency)
            currencySymbol = business.currency.symbol
        amount = amount.replace(currencySymbol, '').replace(' ', '');

        if (offer.promotion.type === "GIFT_CARD") {
            //GIFT_CARD LOGIC
            let amountPrice = 0.0
            try {
                amountPrice = Math.round(amount * 100) / 100;
            } catch (e) {

            }

            if (amountPrice > this.state.gift_card_max) {
                this.setState(
                    {
                        error: "El monto supera el máximo",
                        textMount: `${currencySymbol} ${amountPrice}`,
                        mount: amountPrice
                    })
            } else {
                this.setState(
                    {
                        //error: '',
                        textMount: `${currencySymbol} ${amountPrice}`,
                        mount: amountPrice
                    })
            }
        } else {
            if (amount !== '' && amount !== this.state.mount) {
                let d = 0;
                if (offer.promotion.rate_discount != null) {
                    d = amount * (offer.promotion.rate_discount) / 100;
                }
                if (offer.promotion.maximum_discount_amount != null) {
                    if (d > offer.promotion.maximum_discount_amount) {
                        d = offer.promotion.maximum_discount_amount;
                    }
                }

                const t = amount - d;
                this.setState({
                    mount: Math.round(amount * 100) / 100,
                    textMount: `${currencySymbol} ${amount}`,
                    discount: Math.round(d * 100) / 100,
                    total: Math.round(t * 100) / 100,
                    //error: ''
                });
            } else {
                this.setState({
                    mount: 0.0,
                    discount: 0.0,
                    total: 0.0,
                    textMount: '',
                    //error: ''
                });
            }
        }

    };


    onSubmitValidate(e) {
        e.preventDefault();
        const {promotion} = this.props;
        let offer = promotion
        if (offer.promotion.type === 'FIXED') {
            this.setState({error: ''});
            this.props.dispatch(applied(
                {
                    place: localStorage.getItem('placeIdRedeem'),
                    member: offer.member_uid,
                    program_promotion: offer.uid,
                    comments: ""
                }));
        } else if (!isNaN(this.state.mount)) {
            this.setState({error: ''});
            this.props.dispatch(applied(
                {
                    amount: this.state.mount,
                    place: localStorage.getItem('placeIdRedeem'),
                    member: offer.member_uid,
                    program_promotion: offer.uid,
                    comments: ""
                }));
        }
    }

    stampPromotion(e, promotion) {
        e.preventDefault();
        const {isStamping} = this.props;
        let uidPlace = localStorage.getItem('placeIdRedeem')
        if (uidPlace !== null && uidPlace !== "" && !isStamping) {
            const data = {
                member: promotion.member_uid,
                program_promotion: promotion.uid,
                place: uidPlace,
                stamps: 1
            }
            promotion.stamping = true;
            this.props.dispatch(stampedQR(data));
        }
    }


    render() {
        let {editStatus, retrieveStatus, promotion, client, clientQR, business, isStamping} = this.props
        moment.locale('es');
        let offer = promotion ? promotion.promotion : null
        let price = (offer !== null && offer !== undefined && offer.type === "FIXED") ? offer.fixed_discount : 0

        let color = "36AA8C";
        let stamps = [];
        let required_stamps = promotion && promotion.promotion && promotion.promotion.required_stamps
            ? promotion.promotion.required_stamps
            : 0;
        if (required_stamps > 0) {
            for (let i = 0; i < required_stamps; i++) {
                stamps.push(promotion.available_stamps > i);
            }
        }

        return (
            <div>
                <div className="content-order-detail">
                    <div className="modal-header p-0">
                        <div className={"col"} style={{padding: "0.25em 0.4em"}}>
                            <h5 className="modal-title text-center">
                                {
                                    (promotion) &&
                                    (promotion.promotion && promotion.promotion.title ? promotion.promotion.title
                                        :
                                        "DETALLE DE LA PROMOCIÓN")
                                }
                            </h5>
                        </div>
                    </div>

                    <div className="modal-body p-0">

                        <div className="col-12">
                            {(retrieveStatus === LOADING_STATUS || editStatus === LOADING_STATUS) &&
                                <div className={"d-flex"}>
                                    <LoadingIndicator/>
                                </div>
                            }
                        </div>
                        <div className="col-12">
                            {retrieveStatus === ERROR_STATUS &&
                                <p>
                                    <span>Lo sentimos la información no se ha podido traer adecuadamente</span>
                                </p>
                            }
                        </div>
                        <div className="col-12">
                            {editStatus === ERROR_STATUS &&
                                <p>
                                    <span>Lo sentimos la información no se ha podido actualizar</span>
                                </p>
                            }
                        </div>
                        {(promotion && (retrieveStatus !== LOADING_STATUS || editStatus !== LOADING_STATUS)) &&
                            <div className="row">
                                <div className={"col-12 justify-content-center body-section"}>

                                    <div className={"col-12 title-section pt-0"}>
                                        <p className={"name-section"}>PEDIDO</p>
                                        <div className={"col-12 pt-0 d-flex justify-content-between"}>
                                            <p>{promotion.promotion && promotion.promotion.content}</p>
                                        </div>
                                    </div>

                                    <div className={"col-12 title-section pt-0"}>
                                        <p className={"name-section"}>PROGRAMA DE BENEFICIOS</p>
                                        <div className={"col-12 pt-0 d-flex justify-content-between"}>
                                            <p>{promotion.program && promotion.program.name}</p>
                                        </div>
                                    </div>

                                    <div className={"col-12 title-section pt-0"}>
                                        <p className={"name-section"}>INGRESAR MONTO</p>
                                        {
                                            offer && offer.required_stamps > 0 ?
                                                <div
                                                    className={"col-12 pt-0 d-flex justify-content-between flex-column mb-3"}>
                                                    <div className={"mb-3"}>
                                                        {required_stamps > 0 && required_stamps < 7 && (
                                                            <>
                                                                <div className="stamps-box"
                                                                     style={{background: `#${color}`}}>
                                                                    <div className="row stamps-box_1 p-0">
                                                                        {stamps.map(function (item, i) {
                                                                            return (
                                                                                <div
                                                                                    className={item ? "stamps-use" : {}}
                                                                                    style={item ? {background: `#${color}`} : {}}
                                                                                    key={`stamp${promotion.promotion.uid}${i}`}
                                                                                >
                                                                                    {i + 1}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {required_stamps > 6 && required_stamps < 11 && (
                                                            <>
                                                                <div className="stamps-box"
                                                                     style={{background: `#${color}`}}>
                                                                    <div className="row stamps-box-2 p-0">
                                                                        {stamps.map(function (item, i) {
                                                                            return (
                                                                                <div
                                                                                    className={item ? "stamps-use" : {}}
                                                                                    style={item ? {background: `#${color}`} : {}}
                                                                                    key={`stamp${promotion.promotion.uid}${i}`}
                                                                                >
                                                                                    {i + 1}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    {(promotion.available_uses > 0
                                                            && (promotion.promotion.required_stamps > 0
                                                                && promotion.promotion.required_stamps > promotion.available_stamps)) &&
                                                        <button
                                                            onClick={(e) => this.stampPromotion(e, promotion)}
                                                            className="btn btn-whitelabel-secondary btn-lg btn-block">
                                                            {(isStamping === false) &&
                                                                <span>Estampar ({promotion.available_stamps} / {promotion.promotion.required_stamps})</span>
                                                            }
                                                            {isStamping === true &&
                                                                <span>Estampando...</span>
                                                            }
                                                        </button>
                                                    }
                                                    {(promotion.available_uses > 0 && (promotion.promotion.required_stamps > 0 && promotion.promotion.required_stamps <= promotion.available_stamps)) &&
                                                        <span>Usted ya tiene las suficientes estampas ( {promotion.available_stamps} )</span>
                                                    }
                                                    {
                                                        promotion.is_blocked_of_box && promotion.promotion.type !== "GIFT_CARD" &&
                                                        <p>Promoción agotada,
                                                            redimida {promotion.usage_limit_per_user} veces el usuario.
                                                            Límite: {promotion.usage_limit_per_user}</p>
                                                    }
                                                    {
                                                        (promotion.available_uses > 0 || promotion.times_used_for_member >= promotion.usage_limit_per_user) && promotion.promotion.type !== "GIFT_CARD" &&
                                                        <p>Promoción agotada,
                                                            redimida {promotion.times_used_for_member} veces el usuario.
                                                            Límite: {promotion.usage_limit_per_user}</p>
                                                    }
                                                </div>
                                                :
                                                (offer && offer.type === 'FIXED') ?
                                                    <div
                                                        className={"col-12 pt-0 d-flex justify-content-between flex-column"}>
                                                        <div className={"col-12 p-0 mt-1"}>
                                                            <p className={"m-0 font-weight-bold"}>TOTAL A COBRAR</p>
                                                            <p>{business && business.currency && business.currency.symbol} {price}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div
                                                        className={"col-12 pt-0 d-flex justify-content-between flex-column"}>
                                                        <div className={"col-12 p-0 mb-2 inputContainer"}>
                                                            <input
                                                                id="amount"
                                                                value={this.state.textMount}
                                                                onChange={this.handleMount}
                                                                type="text" className="input" placeholder="a"/>
                                                            <label htmlFor="" className="label">Monto consumido</label>
                                                        </div>
                                                        <div className={"col-12 p-0 mt-1"}>
                                                            <p className={"m-0 font-weight-bold"}>DESCUENTO</p>
                                                            <p>{business && business.currency && business.currency.symbol} {this.state.discount}</p>
                                                        </div>
                                                        <div className={"col-12 p-0"}>
                                                            <p className={"m-0 font-weight-bold"}>TOTAL A COBRAR</p>
                                                            <p>{business && business.currency && business.currency.symbol} {this.state.total}</p>
                                                        </div>
                                                    </div>
                                        }
                                    </div>

                                    <div className={"col-12 title-section pt-0"}>
                                        <p className={"name-section"}>DATOS DEL USUARIO</p>
                                        <div className={"col-12 pt-0 d-flex justify-content-between flex-column"}>
                                            <p className={"col-12 p-0"}>Antes de seguir y validar una promoción,
                                                verifique que el DNI física coincida con la búsqueda.</p>
                                            <div className={"col-12 p-0 d-flex justify-content-between flex-wrap mb-2"}>
                                                <strong>Nombre </strong>{clientQR && clientQR.first_name}
                                            </div>
                                            <div className={"col-12 p-0 d-flex justify-content-between flex-wrap mb-2"}>
                                                <strong>Documento </strong>{client && client.data && client.data.document_number}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-12 title-section pt-0 mt-1"}>
                                        <p className={"name-section d-flex justify-content-between"}
                                           style={{cursor: "pointer"}}
                                           onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({showTerms: !this.state.showTerms})
                                        }}>
                                            TÉRMINOS Y CONDICIONES
                                            <img className={this.state.showTerms ? "triangle-auto" : "triangle-revert"} src={triangle}
                                                 alt="triangle" width={15} height={10} style={{marginTop: 6}}
                                                 />
                                        </p>
                                        <div className={!this.state.showTerms ?
                                            "col-12 pt-0 d-flex justify-content-between hidden-terms" :
                                            "col-12 pt-0 d-flex justify-content-between show-terms"}>
                                            <p>{promotion.promotion && promotion.promotion.terms}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                    {
                        promotion && offer && offer.required_stamps === 0  &&
                        <div className={"d-flex"}>
                            <button
                                type="button"
                                className="btn btn-save"
                                onClick={(e) => this.onSubmitValidate(e)}>
                                Canjear Promoción
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

PromotionData.propTypes = {
    order: PropTypes.object,
    client: PropTypes.object,
};

