import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from "../../components/LoadingIndicator";
import {applied, cleanApplied} from "../Promotions/actions";
import {connect} from "react-redux";
import Validator from "../../utils/validator";


const $ = require('jquery');


 class ModalPromotionTransferredDetail extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         status: '',
         error: '',
         textValidateButton: 'VALIDAR',
         mount: 0.0,
         discount: 0.0,
         total: 0.0,
         textMount: '',
         gift_card_max: 0.0,
         gift_card_total_full: 0,
         number_gift_cards: 0,
      };
      this.handleCloseClick = this.handleCloseClick.bind(this);
   }

   componentDidMount() {
      let {showModal} = this.props
      $(this.modal).modal('show');
      $(this.modal).on('hidden.bs.modal', function (e) {
         showModal(false)
      })

      this.validator = new Validator([
         {
            id: 'amount',
            ref: this.amountInput,
            rules: ['required', 'numeric'],
         }
      ]);
      const {promotionTransferred} = this.props;
      if (promotionTransferred.promotion.type === "GIFT_CARD") {
         //GIFT_CARD LOGIC
         if (promotionTransferred.purchase != null) {
            if (promotionTransferred.promotion.purchase.quantity > 0 && promotionTransferred.promotion.purchase.total_balance > 0) {
               let number_gift_cards =  Math.trunc(promotionTransferred.promotion.purchase.total_balance / promotionTransferred.promotion.promotion.original_price);
               let extra = promotionTransferred.promotion.purchase.total_balance % promotionTransferred.promotion.promotion.original_price;
               if (extra > 0) {
                  this.setState({
                     gift_card_max: extra,
                     gift_card_extra: extra,
                     number_gift_cards: number_gift_cards,
                     gift_card_total_full: number_gift_cards
                  })
               } else {
                  this.setState({
                     gift_card_max: promotionTransferred.promotion.original_price,
                     gift_card_extra: extra,
                     number_gift_cards: number_gift_cards,
                     gift_card_total_full: number_gift_cards
                  })
               }
            } else {

            }
         }
      }
   }



   handleCloseClick() {
      $(this.modal).modal('hide');
   }


   componentWillReceiveProps(nextProps) {
       const {
          errorApplied, code
       } = nextProps;
       if ((code === 200 || code === 201)) {
          this.props.dispatch(cleanApplied());
          this.setState({
             error: '',
             textValidateButton: 'VALIDAR'
          });
          this.handleCloseClick();
       } else if (errorApplied !== '') {
          this.setState({
             error: errorApplied,
             textValidateButton: 'VALIDAR'
          });
       }
    }

   handleMount = async (event) => {
       const {promotionTransferred, business} = this.props;
       let amount = event.target.value;
       let currencySymbol = "S/"
       if(business && business.currency)
           currencySymbol = business.currency.symbol
       amount = amount.replace(currencySymbol, '').replace(' ', '');

       if (promotionTransferred.promotion.type === "GIFT_CARD") {
          //GIFT_CARD LOGIC
          let amountPrice = 0.0
          try {
             amountPrice = Math.round(amount * 100) / 100;
          } catch (e) {

          }

          if (amountPrice > this.state.gift_card_max) {
             this.setState(
                 {
                    error: "El monto supera el máximo",
                    textMount: `${currencySymbol} ${amountPrice}`,
                    mount: amountPrice
                 })
          } else {
             this.setState(
                 {
                    error: '',
                    textMount: `${currencySymbol} ${amountPrice}`,
                    mount: amountPrice
                 })
          }
       } else {
          if (amount !== '' && amount !== this.state.mount) {
             let d = 0;
             if (promotionTransferred.promotion.rate_discount != null) {
                d = amount * (promotionTransferred.promotion.rate_discount) / 100;
             }
             if (promotionTransferred.promotion.maximum_discount_amount != null) {
                if (d > promotionTransferred.promotion.maximum_discount_amount) {
                   d = promotionTransferred.promotion.maximum_discount_amount;
                }
             }

             const t = amount - d;
             this.setState({
                mount: Math.round(amount * 100) / 100,
                textMount: `${currencySymbol} ${amount}`,
                discount: Math.round(d * 100) / 100,
                total: Math.round(t * 100) / 100,
                error: ''
             });
          } else {
             this.setState({
                mount: 0.0,
                discount: 0.0,
                total: 0.0,
                textMount: '',
                error: ''
             });
          }
       }

    };

    onSubmitValidate(e) {
       e.preventDefault();
       const {promotionTransferred} = this.props;
       if (promotionTransferred.promotion.type === 'FIXED') {
          if (this.state.textValidateButton === 'VALIDAR') {
             this.setState({error: ''});
             this.props.dispatch(applied(
                 {
                    place: localStorage.getItem('placeIdRedeem'),
                    member: promotionTransferred.member_uid,
                    program_promotion: promotionTransferred.program_promotion_uid,
                    promotion_code_transferred: promotionTransferred.code
                 }));
          }
       } else if (promotionTransferred.promotion.type === 'GIFT_CARD') {

          if (this.state.textValidateButton === 'VALIDAR' && this.state.error.length === 0) {
             this.setState({error: ''});
             this.props.dispatch(applied(
                 {
                    amount: this.state.mount,
                    place: localStorage.getItem('placeIdRedeem'),
                    member: promotionTransferred.member_uid,
                    promotion_purchased: promotionTransferred.purchase.uid,
                    program_promotion: promotionTransferred.program_promotion_uid,
                     promotion_code_transferred: promotionTransferred.code
                 }));
          }


       } else if (this.state.textValidateButton === 'VALIDAR' && this.validator.validate({amount: this.state.mount})) {
          this.setState({error: ''});
          this.props.dispatch(applied(
              {
                 amount: this.state.mount,
                 place: localStorage.getItem('placeIdRedeem'),
                 member: promotionTransferred.member_uid,
                 program_promotion: promotionTransferred.program_promotion_uid,
                  promotion_code_transferred: promotionTransferred.code
              }));
       }
    }

   render() {
      let {promotionTransferred, isFetchingApplied, business} = this.props
      if (promotionTransferred != null) {
         if (promotionTransferred.promotion.type === 'FIXED') {
            const price = promotionTransferred.promotion.fixed_discount
            return (
                <div>
                   <div
                       className="modal fade"
                       ref={(modal) => this.modal = modal}
                       id="exampleModal"
                       tabIndex="-1"
                       role="dialog"
                       aria-labelledby="exampleModalLabel"
                       aria-hidden="true"
                   >
                      <div className="modal-dialog  modal-lg" role="document">
                         <div className="modal-content">
                            <div className="modal-header">
                               <button onClick={this.handleCloseClick} type="button" className="close">
                                  <span aria-hidden="true">&times;</span>
                               </button>
                            </div>
                            <div className="modal-body ">
                               <div className="col-lg-12 justify-content-center data">
                                  <h4>{promotionTransferred.promotion.title}</h4>
                                  <p className="title-total">Total a Cobrar</p>
                                  <p
                                      className="total"> { business && business.currency && business.currency.symbol } {price}</p>
                                  <p className="description">El cliente
                                     tiene <span>{promotionTransferred.available_uses}</span> descuentos Disponibles
                                  </p>
                               </div>
                               <div className="col-lg-12 justify-content-center message-verify-client">
                                  <h5>Verifique Codigo</h5>
                                  <p>Antes de seguir y validar una promoción, verifique que el código coincida con la busqueda</p>
                                  <div className="data-client">
                                     <p className="label">Código</p>
                                     <p className="control">{promotionTransferred.code}</p>
                                  </div>
                                  <div className="data-client">
                                     <p className="label">Member Uid</p>
                                     <p className="control">{promotionTransferred.member_uid}</p>
                                  </div>
                               </div>
                               {
                                  isFetchingApplied &&
                                  <LoadingIndicator></LoadingIndicator>
                               }
                               {
                                  (isFetchingApplied === false && this.state.error !== '') &&
                                  <div className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                                       role="alert">
                                     <strong>{this.state.error}</strong>
                                  </div>
                               }
                            </div>
                            <div className="modal-footer justify-content-center">
                               <button
                                   type="button"
                                   className="btn btn-whitelabel-auxiliar col-lg-3"
                                   onClick={this.handleCloseClick}
                               >Cancelar
                               </button>
                               <button
                                   type="submit"
                                   className="btn btn-whitelabel-secondary col-lg-3"
                                   onClick={(e) => this.onSubmitValidate(e)}
                               >
                                  {this.state.textValidateButton}
                               </button>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
            );
         }
         if (promotionTransferred.promotion.type === 'GIFT_CARD') {

            return (
                <div>
                   <div
                       className="modal fade"
                       ref={(modal) => this.modal = modal}
                       id="exampleModal"
                       tabIndex="-1"
                       role="dialog"
                       aria-labelledby="exampleModalLabel"
                       aria-hidden="true"
                   >
                      <div className="modal-dialog  modal-lg" role="document">
                         <div className="modal-content">
                            <div className="modal-header">
                               <button onClick={this.handleCloseClick} type="button" className="close">
                                  <span aria-hidden="true">&times;</span>
                               </button>
                            </div>
                            <div className="modal-body ">
                               <div className="col-lg-12 justify-content-center data">
                                  <h4>{promotionTransferred.promotion.title}</h4>
                                  <h5>Ingrese el monto total de la cuenta</h5>
                                  <div
                                      className="form-group"
                                      ref={(input) => {
                                         if (input) {
                                            this.amountInput = input.querySelector('input');
                                         }
                                      }}
                                  >
                                     <input
                                         id="amount"
                                         value={this.state.textMount}
                                         onChange={this.handleMount}
                                         className="form-control col-lg-5 input-search input-amount"
                                         type="text"
                                         placeholder={`${ business && business.currency && business.currency.symbol } 0.0`}
                                     />
                                  </div>
                                  <p className="description" style={{paddingTop: 5}}>
                                     Recuerda solo redimir una promoción a la vez, si el consumo es superior, introducir
                                     lo restante en otra redención. <br/>
                                     Puedes gastar parte o el total de la promoción
                                  </p>

                                  <p className="description">Actualmente el usuario tiene</p>
                                  {
                                     this.state.gift_card_total_full > 0 &&
                                     <p className="description" style={{paddingTop: 5}}>
                                        <span>{this.state.gift_card_total_full}</span> {this.state.gift_card_total_full > 1 ? "promociones" : "promoción"}
                                        de <span>{promotionTransferred.promotion.original_price}</span></p>
                                  }
                                  {
                                     this.state.gift_card_extra > 0 &&
                                     <p className="description" style={{paddingTop: 5}}>
                                        {this.state.gift_card_total_full > 0 ? "Y" : ""} <span>1</span> promoción
                                        de <span>{this.state.gift_card_extra}</span></p>
                                  }
                                  <p className="description" style={{paddingTop: 5}}>Por lo tanto puedes gastar hasta
                                      { business && business.currency && business.currency.symbol } <span>{this.state.gift_card_max}</span> en
                                     esta redencion</p>

                               </div>
                               <div className="col-lg-12 justify-content-center message-verify-client">
                                  <h5>Verifique Codigo</h5>
                                  <p>Antes de seguir y validar una promoción, verifique que el código coincida con la busqueda</p>
                                  <div className="data-client">
                                     <p className="label">Código</p>
                                     <p className="control">{promotionTransferred.code}</p>
                                  </div>
                                  <div className="data-client">
                                     <p className="label">Member Uid</p>
                                     <p className="control">{promotionTransferred.member_uid}</p>
                                  </div>
                               </div>
                               {
                                  isFetchingApplied &&
                                  <LoadingIndicator></LoadingIndicator>
                               }
                               {
                                  (isFetchingApplied === false && this.state.error !== '') &&
                                  <div className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                                       role="alert">
                                     <strong>{this.state.error}</strong>
                                  </div>
                               }
                            </div>
                            <div className="modal-footer justify-content-center">
                               <button
                                   type="button"
                                   className="btn btn-whitelabel-auxiliar col-lg-3"
                                   onClick={this.handleCloseClick}
                               >Cancelar
                               </button>
                               <button
                                   type="submit"
                                   className="btn btn-whitelabel-secondary col-lg-3"
                                   onClick={(e) => this.onSubmitValidate(e)}
                               >
                                  {this.state.textValidateButton}
                               </button>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
            );
         }
         return (
             <div>
                <div
                    className="modal fade"
                    ref={(modal) => this.modal = modal}
                    id="exampleModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                   <div className="modal-dialog  modal-lg" role="document">
                      <div className="modal-content">
                         <div className="modal-header">
                            <button onClick={this.handleCloseClick} type="button" className="close">
                               <span aria-hidden="true">&times;</span>
                            </button>
                         </div>
                         <div className="modal-body ">
                            <div className="col-lg-12 justify-content-center data">
                               <h4>{promotionTransferred.promotion.title}</h4>
                               <h5>Ingrese el monto total de la cuenta</h5>
                               <div
                                   className="form-group"
                                   ref={(input) => {
                                      if (input) {
                                         this.amountInput = input.querySelector('input');
                                      }
                                   }}
                               >
                                  <input
                                      id="amount"
                                      value={this.state.textMount}
                                      onChange={this.handleMount}
                                      className="form-control col-lg-5 input-search input-amount"
                                      type="text"
                                      placeholder={`${ business && business.currency && business.currency.symbol } 0.0`}
                                  />
                               </div>
                               <p className="title-discount">Descuento</p>
                               <p className="discount"> { business && business.currency && business.currency.symbol } {this.state.discount}</p>
                               <p className="title-total">Total a Cobrar</p>
                               <p className="total"> { business && business.currency && business.currency.symbol } {this.state.total}</p>
                               <p className="description">El cliente tiene <span>{promotionTransferred.available_uses}</span> descuentos
                                  Disponibles
                               </p>
                            </div>

                            <div className="col-lg-12 justify-content-center message-verify-client">
                               <h5>Verifique Codigo</h5>
                               <p>Antes de seguir y validar una promoción, verifique que el código coincida con la busqueda</p>
                               <div className="data-client">
                                  <p className="label">Código</p>
                                  <p className="control">{promotionTransferred.code}</p>
                               </div>
                               <div className="data-client">
                                  <p className="label">Member Uid</p>
                                  <p className="control">{promotionTransferred.member_uid}</p>
                               </div>
                            </div>

                            {
                               isFetchingApplied &&
                               <LoadingIndicator></LoadingIndicator>
                            }
                            {
                               (isFetchingApplied === false && this.state.error !== '') &&
                               <div className=" col-lg-12 alert alert-warning alert-dismissible fade show" role="alert">
                                  <strong>{this.state.error}</strong>
                               </div>
                            }
                         </div>
                         <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-whitelabel-auxiliar col-lg-3"
                                onClick={this.handleCloseClick}
                            >Cancelar
                            </button>
                            <button
                                onClick={(e) => this.onSubmitValidate(e)}
                                type="submit"
                                className="btn btn-whitelabel-secondary col-lg-3"
                            >
                               {this.state.textValidateButton}
                            </button>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
         );
      }
   }
}

ModalPromotionTransferredDetail.propTypes = {
    promotionTransferred: PropTypes.object,
    business: PropTypes.object,
};

export default connect(({promotions, user}) => ({
   code: promotions.applyCode,
   isFetchingApplied: promotions.isFetchingApplied,
   errorApplied: promotions.errorApplied,
   business: user.business
}))(ModalPromotionTransferredDetail);
