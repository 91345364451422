import React from 'react';
import Img from 'react-image';
import PropTypes from 'prop-types';
import Preloader from './Preloader';
import CompanyLogo from '../CompanyLogo';
import './style.scss';


const Offer = (props) => (
  <div className="item col-xs-12">
    <figure>
      <Img
        className="cover"
        src={props.promotion.image}
        loader={
          <Preloader />
        }
      />
      <div className="shadow-offer">
        {
          (props.promotion.company_retail !== null && props.promotion.company_retail.logo !== undefined) &&
          <CompanyLogo logo={props.promotion.company_retail.logo} />
        }
      </div>

      <div className="info">
        {props.promotion.company_retail !== null &&
        <p className="title">{props.promotion.company_retail.name}</p>
        }
      </div>
    </figure>

  </div>
);

Offer.propTypes = {
  promotion: PropTypes.object.isRequired,
};
export default Offer;
