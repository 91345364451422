import {
    APPLIED,
    APPLIED_BOC,
    APPLIED_POINTS,
    CLEAN_APPLIED,
    CLEAN_APPLIED_BOC,
    CLEAN_CLIENT,
    CLEAN_PROGRAM,
    CLIENT,
    CLIENT_QR,
    DNI_CLIENT,
    EDIT_PROMOTION,
    MESSAGE,
    MORE_PROMOTIONS,
    MORE_PRODUCTS_REDEEMABLES,
    PROMOTIONS,
    PRODUCTS_REDEEMABLES,
    PROMOTIONS_CLEAN,
    SEARCH_PROGRAM,
    SEARCH_PROGRAM_PROMOTION,
    SELECT_CLIENT,
    SELECT_PROMOTION,
    SET_CLIENT_PROGRAM,
    STAMP_PROGRAM_PROMOTION,
    STAMP_PROGRAM_PROMOTION_QR,
    SEARCH_PRODUCT_REDEEMABLE,
    PRODUCT_REDEEMABLE_CLEAN,
    REDEEM_PRODUCT,
    REDEEM_PRODUCT_REDEEMABLE_CLEAN,
} from './constants';
import HttpClient from '../../utils/httpClient';

export function getPromotions(documentNumber, documentType, placeUid) {

  return (dispatch) => HttpClient.get(
    dispatch,
    PROMOTIONS,
    `v5/me/company/places/${placeUid}/promotions/?document_number=${documentNumber}&document_type=${documentType}`
  );
}
export function getProductsRedeemables(placeUid) {

  return (dispatch) => HttpClient.get(
    dispatch,
    PRODUCTS_REDEEMABLES,
    `v5/me/company/places/${placeUid}/redeemable-products/`
  );
}

export function getMorePromotions(url){
  return (dispatch) => HttpClient.getUrl(
    dispatch,
    MORE_PROMOTIONS,
    url
  );
}

export function getMoreProductsRedeemables(url) {

    return (dispatch) => HttpClient.get(
        dispatch,
        MORE_PRODUCTS_REDEEMABLES,
        url
    );
}

export function getClientByPhone(phone) {
    let payload = { "mobile_number" : phone }
  return (dispatch) => HttpClient.post(payload, dispatch, CLIENT, `v5/members/search/`);
}

export function getClient(dni) {
  return (dispatch) => HttpClient.get(dispatch, CLIENT, `v4/me/company/benefit-programs/levels/members/${dni}/verify/?type=MANAGER`);
}
export function getClientQR(dni) {
  return (dispatch) => HttpClient.get(dispatch, CLIENT_QR, `v4/me/company/benefit-programs/levels/members/${dni}/verify/?type=MANAGER`);
}

export function getDataByQR(qr) {
  return (dispatch) => HttpClient.get(dispatch, CLIENT, `v5/platform/qrcodes/${qr}/`);
}

export function applied(data) {
  return (dispatch) => HttpClient.post(data, dispatch, APPLIED, `v5/redemptions/`);
}

export function appliedRedemptionBOC(data) {
    return (dispatch) => HttpClient.post(data, dispatch, APPLIED_BOC, `v5/boc/notify/redemptions/`);
}

export function stamped(data) {
  return (dispatch) => HttpClient.post(data, dispatch, STAMP_PROGRAM_PROMOTION, `v5/stamps/`);
}

export function redeemProduct(data) {
  return (dispatch) => HttpClient.post(data, dispatch, REDEEM_PRODUCT, `v5/platform/redeem-product/`);
}

export function stampedQR(data) {
  return (dispatch) => HttpClient.post(data, dispatch, STAMP_PROGRAM_PROMOTION_QR, `v5/stamps/`);
}

export function appliedPoints( programId, data) {
  return (dispatch) => HttpClient.post(data, dispatch, APPLIED_POINTS, `v5/platform/programs/${programId}/consumptions/`);
}


export function searchProgram(placeUid, documentNumber, documentType) {
  return (dispatch) => HttpClient.get(dispatch, SEARCH_PROGRAM, `v5/me/company/places/${placeUid}/programs/?document_number=${documentNumber}&document_type=${documentType}`);
}

export function retrieveProgramPromotion(placeUid, promotion_uid, documentNumber, documentType) {
  return (dispatch) => HttpClient.get(dispatch, SEARCH_PROGRAM_PROMOTION, `v5/me/company/places/${placeUid}/promotions/${promotion_uid}/?document_number=${documentNumber}&document_type=${documentType}`);
}

export function retrieveProductRedeemable(placeUid, productUid) {
  return (dispatch) => HttpClient.get(dispatch, SEARCH_PRODUCT_REDEEMABLE, `v5/places/${placeUid}/products/${productUid}/`);
}


export function cleanPromotions() {
  return {
    type: PROMOTIONS_CLEAN,
  };
}
export function cleanProductRedeemable() {
  return {
    type: PRODUCT_REDEEMABLE_CLEAN,
  };
}

export function cleanRedeemProduct() {
  return {
    type: REDEEM_PRODUCT_REDEEMABLE_CLEAN,
  };
}


export function cleanClient() {
  return {
    type: CLEAN_CLIENT,
  };
}

export function cleanProgram() {
  return {
    type: CLEAN_PROGRAM,
  };
}

export function cleanApplied() {
  return {
    type: CLEAN_APPLIED,
  };
}
export function cleanAppliedBoc() {
  return {
    type: CLEAN_APPLIED_BOC,
  };
}

export function selectPromotion(promotion) {
  return {
    type: SELECT_PROMOTION,
    promotion
  };
}
export function editPromotion(promotion) {
  return {
    type: EDIT_PROMOTION,
    promotion
  };
}
export function addClientProgram(client) {
  return {
    type: SET_CLIENT_PROGRAM,
    client
  };
}

export function selectClient(client) {
  return {
    type: SELECT_CLIENT,
    client
  };
}

export function setDniClient(dni) {
  return {
    dni,
    type: DNI_CLIENT,
  };
}

export function sendMessage(message) {
  return {
    message,
    type: MESSAGE,
  };
}
