import HttpClient from '../../utils/httpClient';
import {CREATE_CLIENT, RESET_CREATE_CLIENT} from './constants';

export function createClient(programId, data) {
  return (dispatch) => HttpClient.post(data, dispatch, CREATE_CLIENT, `v5/programs/${programId}/members/`);
}

export function cleanCreateClient() {
  return {
    type: RESET_CREATE_CLIENT,
  };
}
