/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import {BUSINESS_LOGIN, LOGIN, RESET_BUSINESS, RESET_USER} from './constants';
import HttpClient from '../../utils/httpClient';


/*export function loginUser(data) {
  return (dispatch) => HttpClient.postLogout(data, dispatch, LOGIN, 'v4/login/business/');
}*/

export function loginUser(data) {
  return (dispatch) => HttpClient.postLogout(data, dispatch, LOGIN, 'v5/login/business/');
}

export function searchBusiness(domain) {
  return (dispatch) => HttpClient.getLogout( dispatch, BUSINESS_LOGIN, `v5/companies/subdomain/${domain}/`);
}

export function cleanBusiness() {
  return {
    type: RESET_BUSINESS,
  };
}

export function cleanUser() {
  return {
    type: RESET_USER,
  };
}
