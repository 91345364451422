import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import downArrow from '../../images/down_arrow.png';
import {cleanCreateClient, createClient} from './actions';
import Validator from '../../utils/validator';
import back from '../../images/back.png';
import './style.scss';
import moment from 'moment';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      sexF: true,
      sexM: false,
      placeId: '',
      telephone: '',
      program: null,
      type: 'NATIONAL_ID',
      typeText: 'Documento de Identidad',
      textBtn: 'CREAR USUARIO',
      documents: [
        {
          name: 'Documento de Identidad',
          typeId: 'NATIONAL_ID',
        },
        {
          name: 'Pasaporte',
          typeId: 'PASSPORT',
        },
        {
          name: 'Carnet de Extranjería',
          typeId: 'FOREIGNER_ID',
        },
        {
          name: 'Ruc',
          typeId: 'TAX_ID',
        },
        {
          name: 'Otro',
          typeId: 'OTHER',
        },
      ],
    };
  }



  componentDidMount() {
    const { program, place} = this.props;
    if (place !== null) {
      this.setState({
        placeId: place.uid,
      });
    }
    this.setState({ program });
    if (program !== null &&
      program.allow_latam_accrual !== undefined &&
      program.allow_latam_accrual !== null &&
      program.allow_latam_accrual === true) {
      this.validator = new Validator([
        {
          id: 'documentNumber',
          ref: this.documentNumberInput,
          rules: ['required'],
        },
        {
          id: 'email',
          ref: this.emailInput,
          rules: ['required', 'email'],
        },
        {
          id: 'firstName',
          ref: this.firstNameInput,
          rules: ['required'],
        },
        {
          id: 'lastName',
          ref: this.lastNameInput,
          rules: ['required'],
        },
        {
          id: 'birthday',
          ref: this.birthdayInput,
          rules: ['dateSlash', 'required'],
        },
        {
          id: 'telephone',
          ref: this.telephoneInput,
          rules: ['telephone', 'required'],
        },
      ]);

    } else {
      this.validator = new Validator([
        {
          id: 'documentNumber',
          ref: this.documentNumberInput,
          rules: ['required'],
        },
        {
          id: 'email',
          ref: this.emailInput,
          rules: ['required', 'email'],
        },
        {
          id: 'firstName',
          ref: this.firstNameInput,
          rules: ['required'],
        },
        {
          id: 'lastName',
          ref: this.lastNameInput,
          rules: ['required'],
        },
        {
          id: 'birthday',
          ref: this.birthdayInput,
          rules: ['dateSlash', 'required'],
        },
        {
          id: 'telephone',
          ref: this.telephoneInput,
          rules: ['telephone'],
        },
      ]);
    }


    this.props.dispatch(cleanCreateClient());
  }

  componentWillReceiveProps(nextProps) {
    const { isFetchingClient, program } = nextProps;
    const uid = this.state.program != null ? this.state.program.uid : '';
    if (program != null && program.uid !== uid) {
      this.setState({ program });
      if (
        program.allow_latam_accrual === true) {
        this.validator = new Validator([
          {
            id: 'documentNumber',
            ref: this.documentNumberInput,
            rules: ['required'],
          },
          {
            id: 'email',
            ref: this.emailInput,
            rules: ['required', 'email'],
          },
          {
            id: 'firstName',
            ref: this.firstNameInput,
            rules: ['required'],
          },
          {
            id: 'lastName',
            ref: this.lastNameInput,
            rules: ['required'],
          },
          {
            id: 'birthday',
            ref: this.birthdayInput,
            rules: ['dateSlash', 'required'],
          },
          {
            id: 'telephone',
            ref: this.telephoneInput,
            rules: ['telephone', 'required'],
          },
        ]);

      } else {
        this.validator = new Validator([
          {
            id: 'documentNumber',
            ref: this.documentNumberInput,
            rules: ['required'],
          },
          {
            id: 'email',
            ref: this.emailInput,
            rules: ['required', 'email'],
          },
          {
            id: 'firstName',
            ref: this.firstNameInput,
            rules: ['required'],
          },
          {
            id: 'lastName',
            ref: this.lastNameInput,
            rules: ['required'],
          },
          {
            id: 'birthday',
            ref: this.birthdayInput,
            rules: ['dateSlash', 'required'],
          },
          {
            id: 'telephone',
            ref: this.telephoneInput,
            rules: ['telephone'],
          },
        ]);
      }
    }

    if (isFetchingClient) {
      this.setState({ textBtn: 'CREANDO USUARIO' });
    } else {
      this.setState({ textBtn: 'CREAR USUARIO' });
    }
  }

  showError() {
    const { error } = this.props;
    if (error !== '' || this.state.error !== '') {
      return (<div className="col-lg-12"><p className="field-error-message">{error}</p></div>);
    }
    return (<div />);
  }

  backPage(e) {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }

  handleDocumentNumberInput = (event) => {
    event.preventDefault();
    this.setState({
      documentNumber: event.target.value,
    });
  };

  handleFirstNameInput = (event) => {
    event.preventDefault();
    this.setState({
      firstName: event.target.value,
    });
  };

  handleLastNameInput = (event) => {
    event.preventDefault();
    this.setState({
      lastName: event.target.value,
    });
  };

  handleEmailInput = (event) => {
    event.preventDefault();
    this.setState({
      email: event.target.value,
    });
  };

  handleTelInput = (event) => {
    event.preventDefault();
    let band = event.target.value;
    band = band.toString()
      .trim();
    this.setState({
      telephone: band,
    });
  };
  handleSexInput = (event) => {
    this.setState({
      sexF: event.target.value === 'f',
      sexM: event.target.value === 'm'
    });
  };
  handleBirthdayInput = (event) => {
    event.preventDefault();
    let band = event.target.value;

    if (band.length === 2 && this.state.birthday.length !== 3) {
      band += '/';
    }
    if (band.length === 5 && this.state.birthday.length !== 6) {
      band += '/';
    }
    this.setState({
      birthday: band,
    });
  };


  selectDocumentType = (e, document) => {
    e.preventDefault();
    this.setState({
      typeText: document.name,
      type: document.typeId,
    });
  };

  renderDocuments() {
    return this.state.documents.map((document) => (
      <a
        key={document.typeId}
        className="dropdown-item dropdown-type select-type"
        href="/"
        onClick={(e) =>
          this.selectDocumentType(
            e,
            document,
          )
        }
      >
        {document.name}
      </a>
    ));
  }

  onSubmitForm = async (event) => {
    event.preventDefault();
    const { program } = this.props;
    this.props.dispatch(cleanCreateClient());
    if (this.state.textBtn === 'CREAR USUARIO' && this.validator.validate(
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        documentNumber: this.state.documentNumber,
        email: this.state.email,
        birthday: this.state.birthday,
        telephone: this.state.telephone,
      })) {
      const sex = this.state.sexF ? 'f' : 'm';
      const formatBirthday = moment(this.state.birthday, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
      const data = {
        email: this.state.email,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        document_number: this.state.documentNumber,
        document_type: this.state.type,
        sex,
        birthdate: formatBirthday,
      };
      if (this.state.telephone !== '') {
        data.mobile_number = this.state.telephone;
        if (this.state.telephone[0] !== '+') {
          data.mobile_number = `+51${data.mobile_number}`;
        }
      }
      try {
        this.props.dispatch(createClient(program.uid, data));
      } catch (e) {
        this.setState({ textBtn: 'CREAR USUARIO' });
      }
    }
  };

  render() {
    const { loadProgram, program, client } = this.props;
    let style = {};
    if (loadProgram || program == null || client !== null) {
      style = {
        display: 'none',
      };
    }
    return (
      <div className="container">
        <div className="container">
          <div className="row search-section">

            <a href="/" className="link-back" onClick={this.backPage}> <img src={back} alt="back" /> Atrás </a>
            <h3> / Crear Usuario</h3>
          </div>
        </div>
        {
          client !== null &&
          <div className="row search-section">
            <p>El usuario fue creado exitosamente</p>
          </div>
        }
        {
          loadProgram &&
          <div className="row search-section">
            <p>Cargando</p>
          </div>
        }
        {
          (loadProgram === false && program == null) &&
          <div className="row search-section">
            <p>Usted no puede crear usuarios, ya que su local no tiene un programa de beneficios asignado, por favor
              seleccionar otro local.
            </p>
          </div>
        }
        <div className="row search-section" style={style}>
          <form
            className="col-lg-6 "
            onSubmit={this.onSubmitForm}
          >
            <div className="row align-content-center">

              <div className="col-lg-12">
                <div className="menu-dropdown">
                  <div
                    className="form-control"
                    id="dropdownDocumentType"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ marginBottom: 15 }}
                  >
                    <span>{this.state.typeText}</span>
                    <img src={downArrow} alt="arrow" className={'icon-select'} />
                  </div>
                  <div
                    className="dropdown-menu"
                    style={{ width: '100%' }}
                    aria-labelledby="dropdownDocumentType"
                  >
                    {this.renderDocuments()}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.documentNumberInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.documentNumber}
                    onChange={this.handleDocumentNumberInput}
                    className="form-control input-search"
                    type="text"
                    placeholder="Numero de documento"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.firstNameInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.firstName}
                    onChange={this.handleFirstNameInput}
                    className="form-control input-search"
                    type="text"
                    placeholder="Nombres"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.lastNameInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.lastName}
                    onChange={this.handleLastNameInput}
                    className="form-control input-search"
                    type="text"
                    placeholder="Apellidos"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.emailInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.email}
                    onChange={this.handleEmailInput}
                    className="form-control input-search"
                    type="text"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.birthdayInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.birthday}
                    onChange={this.handleBirthdayInput}
                    className="form-control input-search"
                    type="text"
                    autoComplete="off"
                    placeholder="Fecha de nacimiento"
                  />
                </div>
                <small id="passwordHelpBlock" className="form-text text-muted">
                  FORMATO: dd/mm/aaaa, ejemplo 14/03/1996
                </small>
                <br />
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group"
                  ref={(input) => {
                    if (input) {
                      this.telephoneInput = input.querySelector('input');
                    }
                  }}
                >
                  <input
                    value={this.state.telephone}
                    onChange={this.handleTelInput}
                    className="form-control input-search"
                    type="text"
                    autoComplete="off"
                    placeholder="Número telefónico"
                  />
                </div>
                <small id="passwordHelpBlock" className="form-text text-muted">
                  Si es que el telefono es de otra area que no sea Perú agregar código del país con el formato +XX
                </small>
                <br />
              </div>
              <div
                className="col-lg-12"
                style={{ marginBottom: 15 }}
              >
                <span>Sexo</span> <br />
                <div className="form-check">
                  <input
                    value={'f'}
                    onChange={this.handleSexInput}
                    className="form-check-input"
                    type={'checkbox'}
                    id="sexf"
                    checked={this.state.sexF}
                  />
                  <span className="form-check-label">Femenino</span>
                </div>
                <div
                  className="form-check"

                >
                  <input
                    value={'m'}
                    onChange={this.handleSexInput}
                    className="form-check-input"
                    type="checkbox"
                    id="sexm"
                    checked={this.state.sexM}
                  /> <span className="form-check-label">Masculino</span>
                </div>
              </div>
              <div className="col-lg-12">
                <button
                  type="submit"
                  className="btn btn-whitelabel-secondary btn-lg btn-block"
                >
                  {this.state.textBtn}
                </button>
              </div>
            </div>
          </form>
          {
            this.showError()
          }
        </div>
      </div>
    );
  }
}

CreateUser.propTypes = {
  dispatch: PropTypes.func,
  isFetchingClient: PropTypes.bool,
  client: PropTypes.object,
  error: PropTypes.string,
  place: PropTypes.object,
  program: PropTypes.object,
  loadProgram: PropTypes.bool,
};
CreateUser.defaultProps = {
  isFetchingClient: false,
  client: null,
  place: null,
  error: '',
  program: null
};
export default connect(({ places, client, program }) => ({
  isFetchingClient: client.isFetchingCreate,
  client: client.clientCreate,
  place: places.place,
  program: program.dataLocal,
  loadProgram: program.isFetchingLocal,
  error: client.errorCreate,
}))(CreateUser);
