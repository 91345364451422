import {CHANGE_ROOT} from '../containers/Bederr/constants';


const initialState = {
  menu: 'VALIDADOR',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${CHANGE_ROOT}`:
      return Object.assign({},
        state, {
          menu: action.menu
        }
      );
    default:
      return state;
  }
}
