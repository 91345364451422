import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const CompanyLogo = (props) => {
  const logoImage = {
    backgroundImage: `url(${props.logo})`
  };
  return (
    <div className="company-logo" style={logoImage} />
  );
};
CompanyLogo.propTypes = {
  logo: PropTypes.string,
};
export default CompanyLogo;
