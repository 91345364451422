

export const ORDERS = 'ORDERS';
export const ORDER_DETAIL = 'ORDER_DETAIL';
export const PATCH_ORDER = 'PATCH_ORDER';
export const CLEAN_ORDERS = 'CLEAN_ORDERS';
export const CLEAN_ORDER_STATUS = 'CLEAN_ORDER_STATUS';
export const NEXT_ORDERS = 'NEXT_ORDERS';


export const RECEIVED = 'received'
export const ACCEPTED = 'accepted'
export const ACCEPTED2 = 'Aceptada'
export const SHIPPED = 'shipped'
export const DELIVERED = 'delivered'
export const CANCELED_BY_PLACE = 'canceled_by_place'
export const CANCELED_BY_USER = 'canceled_by_user'


export const SET_CURRENT_PLACE_UID_ORDERS = 'SET_CURRENT_PLACE_UID_ORDERS';
export const SET_FIRST_PAGE_ORDERS = 'SET_FIRST_PAGE_ORDERS';
export const SET_NUMBER_PAGE_ORDERS = 'SET_NUMBER_PAGE_ORDERS';
