import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const VerifyClientModalMessage = (props) => (
  <div className="col-lg-12 justify-content-center message-verify-client">
    <h5>Verifique DNI</h5>
    <p>Antes de seguir y validar una promoción, verifique que el DNI físico coincida con la busqueda</p>
    <div className="data-client">
      <p className="label">cliente</p>
      <p className="control">{props.client.first_name}</p>
    </div>
    <div className="data-client">
      <p className="label">DNI</p>
      <p className="control">{props.dni}</p>
    </div>
  </div>
);
VerifyClientModalMessage.propTypes = {
  client: PropTypes.object,
  dni: PropTypes.string,
};
export default VerifyClientModalMessage;
