import {ERROR_STATUS, LOADING_STATUS, SUCCESS_STATUS} from "../containers/Bederr/constants";
import {PROMOTION_TRANSFERRED} from "../containers/Transferred/constants";


const initialState = {
   retrieveStatus: '',
   formInitial: null,
   error: null,
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${PROMOTION_TRANSFERRED}_PENDING`:
         return {
            ...state,
            formInitial: null,
            retrieveStatus:  LOADING_STATUS
         };
      case `${PROMOTION_TRANSFERRED}_FULFILLED`:
         let data = (action.payload && (action.payload.status === 200 || action.payload.status === 201) ) ? action.payload.data : null;
         if(action.payload !==undefined ){
            return {
               ...state,
               retrieveStatus: (action.payload && (action.payload.status === 200 || action.payload.status === 201)  ) ? SUCCESS_STATUS : ERROR_STATUS,
               formInitial: data,
               error: (action.payload && action.payload.error !== 200 ) ? action.payload.error : null,
            };
         }else{
            return {
               ...state,
               retrieveStatus: ERROR_STATUS,
               formInitial: data,
               error: "Ha ocurrido un problema",
            };
         }

      case `${PROMOTION_TRANSFERRED}_REJECTED`:
         return { ...state, isFetchingMore: false, error: action.payload };
      default:
         return state;
   }
}
