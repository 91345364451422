import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {SingleDatePicker} from 'react-dates';
import PropTypes from 'prop-types';
import {getOrders} from './actions';
import moment from "moment";
import DownIcon from "../../components/Icons/DownIcon";

class SearchOrders extends Component {
   constructor(props) {
      super(props);
      this.state = {
         date: moment(),
         focused: null,
         error: '',
         number: '',
         place: null,
         uidPlace: "",
         paymentTypeText: 'Tipo de pago',
         paymentType: [
            {uid: "000", name: "Todos", selected: false},
            {uid: "L9G2kp", name: "TARJETA DÉBITO/CRÉDITO", selected: false},
            {uid: "a90x9M", name: "EFECTIVO", selected: false}
         ]
      };
   }

   componentDidMount() {
      let uidPlace = localStorage.getItem('placeIdRedeem')
      localStorage.setItem('paymentTypeSearchOrders', null)
      this.props.dispatch(getOrders(uidPlace, this.state.date, localStorage.getItem('paymentTypeSearchOrders'), this.state.number.replace(" ","")));

      this.updateComponent()
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      this.updateComponent()
   }
   updateComponent(){
      let uidPlace = localStorage.getItem('placeIdRedeem')
      if (uidPlace !== null) {
         if (this.state.uidPlace !== uidPlace ) {
            this.setState({uidPlace: uidPlace});
            //this.props.dispatch(getOrders(uidPlace, this.state.date, null));
         }
      }
   }

   showError() {
      const {error} = this.props;
      if (error !== '' || this.state.error !== '') return <div className="col-lg-12"><p
          className="field-error-message">{error} {this.state.error}</p></div>;
   }

   handleSearch = async (event) => {
      event.preventDefault();
      let uidPlace = localStorage.getItem('placeIdRedeem')
      if (!this.props.isFetching && uidPlace!=="") {
         this.props.dispatch(getOrders(uidPlace,  this.state.date, localStorage.getItem('paymentTypeSearchOrders'), this.state.number.replace(" ","")));
      }
   };


   renderPaymentType = () => {
      return this.state.paymentType.map((category, index) => (<div
          key={`category${index}`}
          className={`dropdown-item dropdown-type select-type ${category.selected ? 'selected' : ''}`}
          onClick={_ =>
              this.selectPaymentType(
                  category, index
              )
          }
      >
         {category.name}
      </div>));
   }

   selectPaymentType = (stateActive, i) => {

      let {placeCurrent} = this.props
      let {paymentType, paymentTypeText} = this.state
      let text = 'Seleccione un tipo de pago'

      if (paymentType[i]) {
         //TODO segment.selected = !segment.selected for multiple selection
         stateActive.selected = true
         if (stateActive.selected) {
            for (let i = 0; i < paymentType.length; i++) {
               let c = paymentType[i]
               if (c.uid !== stateActive.uid && c.selected) {
                  c.selected = false
                  paymentType[i] = c
               }
            }
            text = `Tipo: ${stateActive.name}`
         }
         paymentType[i] = stateActive
      }

      this.setState({paymentTypeText: text, paymentType: paymentType})
      let paymentTypeParams = ""
      for (let i = 0; i < paymentType.length; i++) {
         if (paymentType[i].uid !== "000" && paymentType[i].selected) {
            if (paymentTypeParams.length > 0)
               paymentTypeParams += ","
            paymentTypeParams += `${paymentType[i].uid}`
         }
      }

      localStorage.setItem('paymentTypeSearchOrders', paymentTypeParams);

      this.props.setPageCurrent(1)

      if(text!==paymentTypeText){
         if (placeCurrent) {
            let uidPlace = localStorage.getItem('placeIdRedeem')
            this.props.dispatch(getOrders(uidPlace,  this.state.date, localStorage.getItem('paymentTypeSearchOrders'), this.state.number.replace(" ","")));
         }
      }
   }


   handleDateChange(date) {
      this.setState({date});
      this.props.setDate(date)
      this.props.setPageCurrent(1)
   }





   render() {
      const {isFetching} = this.props;
      let {paymentTypeText} = this.state
      return (
          <div>
             <div className="container ">
                <div className="row search-section">
                   <form
                       className="col-lg-12 "
                       onSubmit={this.handleSearch}
                   >
                      <div className="row align-content-center">
                         <div className="col-12 col-lg-3 p-0 pr-lg-3" style={{marginBottom: 5}}>
                            <SingleDatePicker

                                showDefaultInputIcon
                                small
                                block
                                placeholder={'Todas las fechas'}
                                numberOfMonths={1}
                                date={this.state.date}
                                onDateChange={(date) => this.handleDateChange(date)}
                                focused={this.state.focused}
                                onFocusChange={({focused}) =>
                                    this.setState({focused})
                                }
                                isOutsideRange={() => false}
                                openDirection="down"
                                hideKeyboardShortcutsPanel
                                displayFormat="DD/MM/YYYY"
                            />
                            <div className="form-check">
                               <input type="checkbox"  defaultChecked={this.state.date===null} className="form-check-input" onChange={e=>{
                                  if(e.target.value){
                                     this.setState({date:null})
                                     this.props.setDate(null)
                                     this.props.setPageCurrent(1)
                                  }
                               }} />
                               <label className="form-check-label" >Todas las fechas</label>
                            </div>
                         </div>
                         <div className="col-12 col-lg-3 p-0 pr-lg-3" >
                            <input
                                value={this.state.number}
                                onChange={ e => {
                                   this.setState({number: e.target.value})
                                   this.props.setNumber(e.target.value)
                                   this.props.setPageCurrent(1)
                                }}
                                className="form-control input-search"
                                type="search"
                                placeholder="Ingrese el número de pedido"
                                aria-label="Search"
                            />
                         </div >

                         <div className={"col-12 col-lg-3 p-0 pr-lg-3"}>
                            <div
                                className={`
                              form-group 
                              dropdown 
                              categories`}
                                id={"document_type"}>
                               <div
                                   className="form-control  input-order"
                                   data-toggle="dropdown"
                                   aria-haspopup="true"
                                   aria-expanded="false"
                               >
                                  <span>{paymentTypeText}</span>
                                  <DownIcon />
                               </div>
                               <div
                                   className="dropdown-menu"
                                   style={{
                                      width: "100%",
                                      overflowY: "auto",
                                      maxHeight: 200
                                   }}
                               >
                                  {this.renderPaymentType()}
                               </div>
                            </div>
                         </div>

                         <div className="col-12 col-lg-3 p-0 pr-lg-3 d-flex flex-column ">
                            <button
                                type="submit"
                                className="btn btn-whitelabel-secondary btn-lg btn-block"
                            >
                               {
                                  isFetching && 'Buscando'
                               }
                               {
                                  isFetching === false && 'Buscar'
                               }
                            </button>
                         </div>
                      </div>
                   </form>
                   {
                      this.showError()
                   }
                </div>
             </div>
             <div className="line" />
          </div>
      );
   }
}

SearchOrders.propTypes = {
   dispatch: PropTypes.func,
   isFetching: PropTypes.bool,
   isFetchingFile: PropTypes.bool,
   places: PropTypes.array,
   error: PropTypes.string,
   place: PropTypes.object,

};
SearchOrders.defaultProps = {
   isFetching: false,
   places: null,
   place: null,
   error: '',

};
export default connect(({places, orders}) => ({
   places: places.data,
   place: places.place,
   isFetching: orders.isFetching,
   isFetchingFile: orders.isFetchingFile,

}))(SearchOrders);
