import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {ERROR_STATUS, LOADING_STATUS, SUCCESS_STATUS} from "../Bederr/constants";
import moment from "moment";
import {
    ACCEPTED,
    CANCELED_BY_PLACE,
    DELIVERED,
    RECEIVED,
    SHIPPED
} from "../Orders/constants";
import arrowIcon from "../../images/arrow_icon_white.png"
import LoadingIndicator from "../../components/LoadingIndicator";
import 'moment/locale/es';
import {toast} from "react-toastify";


export default class OrderData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            error: '',
            textValidateButton: 'Actualizar',

            types: [
                {uid: "RECEIVED", name: "RECEIVED", selected: false},
                {uid: "DELIVERED", name: "DELIVERED", selected: false},
                {uid: "CANCELED_BY_PLACE", name: "CANCELED_BY_PLACE", selected: false},
                {uid: "CANCELED_BY_USER", name: "CANCELED_BY_USER", selected: false},
                {uid: "ACCEPTED", name: "ACCEPTED", selected: false},
                {uid: "SHIPPED", name: "SHIPPED", selected: false},
            ],
            typeText: 'Tipo',
        };
        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount() {
        let {getOrder} = this.props
        getOrder()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {order, editStatus, cleanOrderStatus} = this.props

        if(order && this.state.status!==order.status){
            this.setState({status: order.status, typeText: order.status.toUpperCase()})
        }
        if(editStatus===SUCCESS_STATUS){
            toast.info("Se actualizó la información correctamente")
            cleanOrderStatus()
        } else if(editStatus===ERROR_STATUS){
            toast.error("Lo sentimos la información no se ha podido actualizar")
            cleanOrderStatus()
        }
    }

    renderProducts(products) {
        let {order} = this.props
        return products.map((product, index) => (
            <Fragment key={index}>
                <tr key={`product${index}`}>
                    <td><strong>{product.product.title}</strong></td>
                    <td> {product.quantity} </td>
                    <td className={"text-right"}> {order.currency ? order.currency.symbol : "S/."}{product.subtotal} </td>
                </tr>
                {product.option_groups.length !== 0 &&
                    product.option_groups.map((option_group, index) => (
                        <Fragment key={option_group.uid}>
                            {
                                option_group.options.map((o, index) => (
                                    <tr key={o.name}>
                                        <td>Extra: {o.name}</td>
                                        <td>{product.quantity}</td>
                                        <td >{order.currency ? order.currency.symbol : "S/."}{o.price * product.quantity}</td>
                                    </tr>
                                ))
                            }
                        </Fragment>
                    ))
                }
            </Fragment>
        ));
    }

    renderDiscountTypes = () => {
        return this.state.types.map((category, index) => (<div
            key={`category${index}`}
            className={`dropdown-item dropdown-type select-type ${category.selected ? 'selected' : ''}`}
            onClick={_ =>
                this.selectDiscountType(
                    category, index
                )
            }
        >
            {this.renderTextStatus(category.name)}
        </div>));
    }

    selectDiscountType = (discountType, i) => {

        let {types} = this.state
        let text = 'Tipo'

        if (types[i]) {
            //TODO segment.selected = !segment.selected for multiple selection
            discountType.selected = true
            if (discountType.selected) {
                for (let i = 0; i < types.length; i++) {
                    let c = types[i]
                    if (c.uid !== discountType.uid && c.selected) {
                        c.selected = false
                        types[i] = c
                    }
                }
                text = `${discountType.name}`
            }
            types[i] = discountType
        }
        this.setState({typeText: text, types: types})
        let typesParams = ""
        for (let i = 0; i < types.length; i++) {
            if (types[i].uid !== "000" && types[i].selected) {
                if (typesParams.length > 0)
                    typesParams += ","
                typesParams += `${types[i].uid}`
            }
        }
        localStorage.setItem('typesSelectPromotion', typesParams);
    }

    updateStatus(status) {
        const {editStatus, updateOrder} = this.props
        if (editStatus !== LOADING_STATUS) {
            updateOrder(status)
        }
    }
    updateNewStatus() {
        const {editStatus, updateOrder} = this.props
        if (editStatus !== LOADING_STATUS) {
            updateOrder(this.state.typeText)
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {
            editStatus,
            cleanOrderStatus,
            getOrder
        } = props;
        //console.log(editStatus)
        if (editStatus === SUCCESS_STATUS) {
            cleanOrderStatus()
            getOrder()
        }
        return state
    }
    renderTextStatus(status) {
        switch (status) {
            case "RECEIVED": return "Orden recibida";
            case "DELIVERED": return "Orden entregada";
            case "CANCELED_BY_PLACE": return "Orden Cancelada";
            case "CANCELED_BY_USER": return "Orden Cancelada por el usuario";
            case "ACCEPTED": return "Orden aceptada";
            case "SHIPPED": return "Orden enviada";
            default: return '--'
        }
    }

    renderDocumentType(documentType) {
        switch (documentType) {
            case 'NATIONAL_ID':
                return (<span>Documento de Identidad</span>);
            case 'PASSPORT':
                return (<span>Pasaporte</span>);
            case 'FOREIGNER_ID':
                return (<span>Carnet de Extranjería</span>);
            case 'TAX_ID':
                return (<span>Ruc</span>);
            default:
                return (<span>Otro</span>);

        }

    }

    render() {
        let {typeText} = this.state
        let {editStatus, retrieveStatus, order, typeOrder} = this.props
        moment.locale('es');
        //console.log(typeOrder,"typeOrder")
        return (
            <div>
                <div className="content-order-detail">
                    <div className="modal-header p-0">
                        <div className={"col"} style={{padding: "0.25em 0.4em"}}>
                            {
                                typeOrder==="DIGITAL_PRODUCT" ?
                                    <h5 className="modal-title text-center">{order && order.order_products && order.order_products.length!==0 && order.order_products[0].product.title.toUpperCase()}</h5>
                                    :
                                    <h5 className="modal-title text-center">DETALLE DE LA ORDEN</h5>
                            }
                        </div>

                    </div>

                    <div className="modal-body p-0">

                        <div className="col-12">
                            {(retrieveStatus === LOADING_STATUS || editStatus === LOADING_STATUS) &&
                                <div className={"d-flex"}>
                                    <LoadingIndicator/>
                                </div>
                            }
                        </div>

                        {(order && (retrieveStatus !== LOADING_STATUS || editStatus !== LOADING_STATUS)) &&
                            <div className="row">
                                <div className={"col-12 justify-content-center"}>
                                    <p className={"name-section"}>ESTADO</p>
                                    <div
                                        className="form-control dropdown-status"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{cursor: "pointer"}}
                                    >
                                        <span>{this.renderTextStatus(typeText)}</span>
                                        <img src={arrowIcon} alt="arrowIcon" width={20} height={25}/>
                                    </div>
                                    <div
                                        className="dropdown-menu"
                                        style={{
                                            width: "90%",
                                            overflowY: "auto",
                                            maxHeight: 200
                                        }}
                                    >
                                        {this.renderDiscountTypes()}
                                    </div>
                                </div>
                                <div className={"col-12 justify-content-center body-section"}>
                                    <p className={"name-section"}>DETALLE</p>
                                    <p className={"col-12 title-section pt-0"}>
                                       <span className={"detail-number"}>#{order.number}</span>
                                    </p>
                                </div>

                                <div className={"col-12 justify-content-center body-section"}>
                                    <p className={"name-section"}>FECHA DE ADQUISICIÓN</p>
                                    <p className={"col-12 title-section pt-0"}>
                                       <span style={{textTransform: "capitalize"}}>{moment(order.created_at).format('LL').toString().replaceAll("de", "")}</span>
                                    </p>
                                </div>

                                <div className={"col-12 title-section pt-0"}>
                                    <p className={"name-section"}>DATOS DE USUARIO</p>
                                </div>
                                {
                                    order.user &&
                                    <div className={"col-12 body-section "}>
                                        <div className={"row"}>
                                            <div className={"col-6"}>
                                                <strong>Nombre y Apellidos</strong>
                                            </div>
                                            <div className={"col-6"}>
                                                <p className={"text-right"}>{order.user.first_name} {order.user.last_name}</p>
                                            </div>
                                            <div className={"col-6"}>
                                                <strong className={"text-right"}>{this.renderDocumentType(order.user.document_type)}</strong>
                                            </div>
                                            <div className={"col-6"}>
                                                <p className={"text-right"}>{order.user.document_number}</p>
                                            </div>
                                            <div className={"col-6"}>
                                                <strong className={"text-right"}>email</strong>
                                            </div>
                                            <div className={"col-6"}>
                                                <p className={"text-right"}>{order.user.email}</p>
                                            </div>
                                        </div>
                                    </div>

                                }
                                {
                                    order.contact_phone &&
                                    <div className={"col-12 body-section"}>
                                        <div className={"row"}>
                                            <div className={"col-6"}>
                                                <strong>Número de contacto</strong>
                                            </div>
                                            <div className={"col-6"}>
                                                <p className={"text-right"}>{order.contact_phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className={"col-12 title-section pt-0"}>
                                    <p className={"name-section"}>PEDIDO</p>
                                </div>

                                <div className={"col-12 body-section"}>
                                    <div className={"row"}>
                                        <div className={"col-6"}>
                                            <strong>Tipo de Pedido</strong>
                                        </div>
                                        <div className={"col-6 text-right"}>
                                            <span
                                                className={order.delivery_method ? `badge badge-light ${order.delivery_method.type} text-right` : 'badge badge-light text-right'}>
                                              {order.delivery_method ? `${order.delivery_method.name}` : ''}
                                           </span>
                                        </div>
                                        {
                                            order.delivery_method && order.delivery_method.type === 'delivery' &&
                                            <div className={"col-12 section-detail"}>
                                                <div className={"row"}>
                                                    <div className={"col-6 "}>
                                                        <strong>Distrito</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {order.delivery_locality && <span>
                                             {order.delivery_locality.name}
                                          </span>}
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <strong>Dirección</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {order.shipping_address}
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <strong>Referencia</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {order.reference_address &&
                                                            <span>{order.reference_address}</span>}
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <strong>Comentarios</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {order.comments && <span>{order.comments}</span>}
                                                    </div>
                                                    {order.delivery_price !== 0 &&
                                                        <Fragment>
                                                            <div className={"col-6"}>
                                                                <strong>Precio</strong>
                                                            </div>
                                                            <div className={"col-6 text-right"}>
                                                                <span>{order.delivery_price}</span>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>

                                            </div>
                                        }
                                        {
                                            order.delivery_method && order.delivery_method.type === 'pickup' &&
                                            <div className={"col-12 section-detail"}>
                                                <div className={"row"}>
                                                    <div className={"col-6"}>
                                                        <strong>Fecha de Recojo</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {`${moment(order.pickup_scheduled_date).format('DD/MM/YYYY')}`}
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <strong>Hora del recojo</strong>
                                                    </div>
                                                    <div className={"col-6 text-right"}>
                                                        {order.pickup_scheduled_starts} - {order.pickup_scheduled_ends}
                                                    </div>

                                                </div>

                                            </div>
                                        }
                                        <div className={"col-6"}>
                                            <strong>Tipo de Pago</strong>
                                        </div>
                                        <div className={"col-6 text-right"}>
                                       <span
                                           className={order.payment_method ? `badge badge-light ${order.payment_method.type} text-right` : 'badge badge-light  text-right'}>
                                          {order.payment_method ? `${order.payment_method.name}` : ''}
                                       </span>
                                        </div>
                                    </div>
                                </div>


                                <div className={"col-12 title-section pt-0"}>
                                    <p className={"name-section"}>PRODUCTOS</p>
                                </div>
                                {
                                    order.order_products &&
                                    <div className={"col-12 table-order"}>
                                        <div className={"row"}>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col" id={"price-th"}>Precio</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderProducts(order.order_products)}


                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                }
                                {
                                    typeOrder==="DIGITAL_PRODUCT" &&

                                    <div className={"col-12 body-section"}>
                                        <p className={"name-section"}>Descripción</p>
                                        <div className={"col-12 pt-0 d-flex justify-content-between"}>
                                            <p>{order.order_products.length!==0 && order.order_products[0].product.description.length!==0 ?  order.order_products[0].product.description : "-" }</p>
                                        </div>
                                    </div>
                                }

                                <div className={"col-12 body-section"}>
                                    <p className={"name-section"}>PRECIO FINAL</p>
                                    <div className={"col-12 pt-0 d-flex justify-content-between"}>
                                            <p className={"font-weight-bold"}>Total</p>
                                            <p>{order.currency ? order.currency.symbol : "S/."}{order.delivery_price !== null && order.delivery_price !== undefined && order.delivery_price !== 0 ? order.total + order.delivery_price : order.total}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(order && false &&
                            order.delivery_method) &&
                        <div>
                            {
                                order.delivery_method.type === 'pickup' &&
                                <div className={"modal-footer justify-content-center"}>
                                    {
                                        order.status === RECEIVED &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(ACCEPTED)}
                                        >Aceptar pedido
                                        </button>
                                    }
                                    {
                                        (order.status === 'accepted' || order.status === 'Aceptada') &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(DELIVERED)}
                                        >Orden entregada
                                        </button>
                                    }
                                    {
                                        order.status === RECEIVED &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(CANCELED_BY_PLACE)}
                                        >Cancelar pedido
                                        </button>
                                    }
                                </div>
                            }

                            {
                                order.delivery_method.type === 'delivery' &&
                                <div className={"modal-footer justify-content-center"}>
                                    {
                                        order.status === RECEIVED &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(ACCEPTED)}
                                        >Aceptar pedido
                                        </button>
                                    }
                                    {
                                        (order.status === ACCEPTED) &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(SHIPPED)}
                                        >Pedido enviado
                                        </button>
                                    }
                                    {
                                        (order.status === SHIPPED) &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(DELIVERED)}
                                        >Pedido entregado
                                        </button>
                                    }
                                    {
                                        order.status === RECEIVED &&
                                        <button
                                            type="button"
                                            className="btn btn-whitelabel-auxiliar col-lg-3"
                                            onClick={e => this.updateStatus(CANCELED_BY_PLACE)}
                                        >
                                            Cancelar pedido
                                        </button>
                                    }
                                </div>
                            }

                        </div>
                    }
                    {
                        order &&
                        <div className={"d-flex"}>
                            <button
                                type="button"
                                className="btn btn-save"
                                onClick={e => this.updateNewStatus(e)}>
                                Guardar
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

OrderData.propTypes = {
    order: PropTypes.object,
};

