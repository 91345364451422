import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class AuthorizedRoute extends React.Component {
  isLogin = () => {
    const token = localStorage.getItem('tokenvalidator');
    return token != null;
  }

  render() {
    const { ...rest } = this.props;
    const ComponentToRender = this.props.component;
    if (this.isLogin()) {
      return (
         <ComponentToRender {...rest} />
      );
    }
    return (
       <Redirect to="/login" />
    );
  }
}


export default connect()(AuthorizedRoute);

