/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import {PLACES, SET_DATA_LOCAL} from './constants';
import HttpClient from '../../utils/httpClient';

export const LIST_PLACES_URL = 'v5/company/{company_uid}/places/';

export function getPlaces() {
  let url = LIST_PLACES_URL.replace('{company_uid}', window.localStorage.getItem(`companyUid`));
  return (dispatch) => HttpClient.get(dispatch, PLACES, url);
}


export function setDataLocal(dataLocal) {

  return {
    type: SET_DATA_LOCAL,
    dataLocal
  };
}
