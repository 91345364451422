import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  addClientProgram,
  cleanClient,
  cleanProgram,
  cleanPromotions,
  getClient,
  getClientByPhone, getClientQR, getDataByQR, getProductsRedeemables,
  getPromotions, retrieveProgramPromotion,
  searchProgram,
  setDniClient,
} from './actions';
import VerifyClientSteps from '../../components/VeryfyClientMessage/VerifyClientSteps';
import LevelProgram from './LevelProgram';
//import {Link} from 'react-router-dom';
import VerifyClientStepsPhone from "../../components/VeryfyClientMessage/VerifyClientStepsPhone";
import countriesPhone from "../../components/JSON/countriesPhone.json"
import Select from 'react-select'
import {cleanOrderStatus, retrieveOrder, updateOrder} from "../Orders/actions";
import OrderData from "./OrderData";
import PromotionData from "./PromotionData";
import Arrow from '../../images/Arrow';

class SearchUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      dniUser: '',
      error: '',
      placeId: '',
      documentNumber: '',
      code: "",
      type: 'NATIONAL_ID',
      typeText: 'Documento de identidad',
      documents: [
        {
          name: 'Documento de identidad',
          typeId: 'NATIONAL_ID',
        },
        {
          name: 'Pasaporte',
          typeId: 'PASSPORT',
        },
        {
          name: 'Carnet de Extranjería',
          typeId: 'FOREIGNER_ID',
        },
        {
          name: 'RUC',
          typeId: 'TAX_ID',
        },
        {
          name: 'Teléfono',
          typeId: 'MOBILE_PHONE',
        },
        {
          name: 'Código QR',
          typeId: 'QR',
        },
        {
          name: 'Otro',
          typeId: 'OTHER',
        },
      ]
    };
  }



  componentDidMount() {
    let {business} = this.props
    let uidPlace = localStorage.getItem('placeIdRedeem')
    if (uidPlace !== null) {
      if (this.state.uidPlace !== uidPlace ) {
        this.setState({
          placeId: uidPlace,
        });
        localStorage.setItem('placeIdRedeem', uidPlace)

        //delete
        //this.props.dispatch(getDataByQR("384YZW"));
      }
    }
    if(business){
      this.setState({code: `+${business.country.code}`})
    }
  }

  componentWillReceiveProps(nextProps) {
    const { client, searchUserBool } = nextProps;
    let uidPlace = localStorage.getItem('placeIdRedeem')
    if (client !== null) {
      if ((this.state.dniUser !== this.state.documentNumber &&
          (this.state.type!=="MOBILE_PHONE" || client.type==="MEMBER"))) {
        this.props.dispatch(setDniClient(this.state.documentNumber));
        this.setState({
          dniUser: this.state.documentNumber,
        });
        if (uidPlace && uidPlace !== "" ) {
          if(client.type==="MEMBER" && client){
            this.props.dispatch(getPromotions(client.data.document_number, client.data.document_type, uidPlace));
          }else{
            this.props.dispatch(getPromotions(this.state.documentNumber, this.state.type, uidPlace));
            this.props.dispatch(getProductsRedeemables(uidPlace));
          }
          this.props.dispatch(searchProgram(uidPlace, this.state.documentNumber, this.state.type));
          this.props.dispatch(addClientProgram({
            document_number: this.state.documentNumber,
            document_type: this.state.type,
          }));
        }
      }else{
        if(client.length===1 && this.state.type==="MOBILE_PHONE"){
          this.setState({document_number: client[0].document_number, document_type: client[0].document_type})
          this.props.dispatch(setDniClient(this.state.documentNumber));
          this.setState({
            dniUser: client[0].document_number,
          });
          if (uidPlace && uidPlace !== "" && client[0].document_number!==this.state.dniUser ) {
            this.props.dispatch(getPromotions(client[0].document_number, client[0].document_type, uidPlace));
            this.props.dispatch(getProductsRedeemables(uidPlace));
            this.props.dispatch(searchProgram(uidPlace, client[0].document_number, client[0].document_type));
            this.props.dispatch(addClientProgram({
              document_number: client[0].document_number,
              document_type: client[0].document_type,
            }));
          }
        }
        if(this.state.dniUser !== this.state.documentNumber && this.state.type==="QR"){
          this.props.dispatch(setDniClient(this.state.documentNumber));
          this.setState({
            dniUser: this.state.documentNumber,
          });
          if (uidPlace && uidPlace !== "" ) {
            if(client.type==="DIGITAL_PRODUCT" || client.type==="ORDER"){
              this.props.dispatch(retrieveOrder(client.data.order_uid));
            }
          }

        }
      }
    } else {
      this.props.dispatch(cleanPromotions());
      this.props.dispatch(cleanProgram());
      this.props.dispatch(setDniClient(null));
      this.props.dispatch(addClientProgram(null));
      this.setState({
        dniUser: '',
      });
    }
    if (uidPlace && uidPlace !== "" ) {
      if (this.state.placeId !== '' && this.state.placeId !== uidPlace) {
        this.props.dispatch(cleanClient());
        this.props.dispatch(cleanProgram());
        this.props.dispatch(cleanPromotions());
        this.setState({
          documentNumber: '',
        });
      }
      this.setState({
        placeId: uidPlace,
        error: '',
      });
    }

    if(searchUserBool){
      this.searchUser()
      this.props.setSearch(false)
    }
  }

  showError() {
    const { error } = this.props;
    if (error !== '' || this.state.error !== '') {
      return (
          <div className="col-lg-12" >
            <p className="field-error-message">{error} {this.state.error}</p>
          </div >
      );
    }
    return (<div />);
  }

  searchUser = () => {
    if (this.state.documentNumber !== '') {
      if(this.state.type==="MOBILE_PHONE"){
        this.props.dispatch(getClientByPhone(`${this.state.code}${this.state.documentNumber}`));
      }else{
        if(this.state.type==="QR"){
          this.props.dispatch(getDataByQR(`${this.state.documentNumber}`));
        }else{
          this.props.dispatch(getClient(this.state.documentNumber));
        }
      }
    }
  }

  handleSearch = async (event) => {
    event.preventDefault();
    this.searchUser()
  };

  handleSearchInput = (event) => {
    event.preventDefault();
    if (localStorage.getItem('placeIdRedeem') !== '' && localStorage.getItem('placeIdRedeem')!==null) {
      this.setState({
        documentNumber: event.target.value,
        error: '',
      });
    } else {
      this.setState({
        documentNumber: '',
        error: 'Debe seleccionar un local',
      });
    }
  };

  selectDocumentType = (e, document) => {
    e.preventDefault();
    this.setState({
      typeText: document.name,
      type: document.typeId,
    });
    setTimeout(() => {
      this.handleSearch(e)
    }, 500)

  };

  renderDocuments() {
    return this.state.documents.map((document) => (
      <a
        key={document.typeId}
        className="dropdown-item dropdown-type select-type"
        href="/"
        onClick={(e) =>
          this.selectDocumentType(
            e,
            document,
          )
        }
      >
        {document.name}
      </a >
    ));
  }

  renderMembersByPhone(clients) {
    return clients && clients.length!==0 && clients.map((c,index)=>(<div className="col-lg-12" key={`${index}`}>
            <p className="user-text" >
              {this.state.documents.filter(d => d.typeId ===`${c.document_type}`)[0].name} {clients.length!==1 && `#${index+1}`} <br /> <span >{c.document_number}</span > <br />
            </p >
          </div >)
      )
  }
  isArray(clients) {
    Array.isArray(clients);

  }

  renderDocumentType(documentType) {
    switch (documentType) {
      case 'NATIONAL_ID':
        return ("Documento de identidad");
      case 'PASSPORT':
        return ("PASAPORTE");
      case 'FOREIGNER_ID':
        return ("CARNET DE EXTRANJERÍA");
      case 'TAX_ID':
        return ("RUC");
      default:
        return ("OTRO");

    }

  }

  render() {
    const { client, clientQR, isFetchingClient, business,isStamping,
      dispatch, retrieveStatus, editStatus, order, promotionData, retrieveStatusFormInitial
    } = this.props;
    let listCountriesPhone = countriesPhone.countries
    let options = []
    let defaultValue = null
      listCountriesPhone.map(c=>
      {
        options.push({ value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}` })
        if(c.dial_code===`+${business.country.code}`){
          if(c.dial_code==="+1"){
            if(business.country.name.includes("Dominicana")){
              defaultValue = { value: `${c.dial_code}`, label: `${c.dial_code} ${business.country.name}` }
            }else{
              defaultValue = { value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}` }
            }
          }else{
            defaultValue = { value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}` }
          }
        }


        return true
      })
    return (
      <div className="container" >
        <div className="row search-section" >
          <form
            className="col-lg-12 "
            onSubmit={this.handleSearch}
          >
            <div className={this.state.type==="MOBILE_PHONE" ? "row justify-content-end" : "row align-items-center"}>
              <div className="col-lg-5" >
                <div className="menu-dropdown" >
                  <div
                    className="form-control"
                    id="dropdownDocumentType"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span >{this.state.typeText}</span >
                    <Arrow />
                  </div >
                  <div
                    className="dropdown-menu"
                    style={{ width: '100%' }}
                    aria-labelledby="dropdownDocumentType"
                  >
                    {this.renderDocuments()}
                  </div >
                </div >
              </div >
              {
                this.state.type==="MOBILE_PHONE" &&
                <div className="col-lg-4 mb-1" >
                  <Select options={options}
                          onChange={e => {
                            this.setState({
                              code: e.value
                            });
                          }}
                          defaultValue={defaultValue}
                          />
                </div>
              }
              <div className="col-lg-5" >
                <input
                  value={this.state.documentNumber}
                  onChange={this.handleSearchInput}
                  className="form-control input-search"
                  type="search"
                  placeholder={this.state.type==="MOBILE_PHONE" ? "Ingrese un teléfono" :  `Ingrese ${this.state.typeText==="RUC" ? this.state.typeText.toUpperCase() : this.state.typeText.toLowerCase()}`}
                  aria-label="Search"
                />
              </div >

              <div className="col-lg-2" style={{ marginBottom: 5 }} >
                <button
                  type="submit"
                  id={"searchUser"}
                  className="btn btn-whitelabel-secondary btn-lg btn-block btn-search-validator"
                >
                  {
                    isFetchingClient && 'Buscando'
                  }
                  {
                    isFetchingClient === false && 'Buscar'
                  }
                </button >
              </div >
              {/* {
                (program !== null && program.allow_member_signup === true) &&
                <div className="col-lg-2" style={{ marginBottom: 5 }} >
                  <Link to={'/createuser'} className="btn btn-whitelabel-secondary btn-lg btn-block btn-search-validator" >
                    Nuevo
                  </Link >
                </div >
              } */}
            </div >
            {
              (client === null || client === undefined) && this.state.type==="MOBILE_PHONE" ?
              <div className="row" >
                <VerifyClientStepsPhone />
              </div> :
                  (client === null || client === undefined || (Array.isArray(client) && client.length!==1) )  && this.state.type!=="MOBILE_PHONE"&&
                  <div className="row" >
                <VerifyClientSteps
                    typeText={this.state.typeText}/>
              </div>
            }
          </form >
          {
            this.showError()
          }
          <div className="user-details col-lg-12 not-sm-padding" >
            {
              client !== null &&
              <LevelProgram client={client} typeSearch={this.state.typeText} dataInput={this.state.documentNumber} />
            }
            {
              this.state.type==="MOBILE_PHONE" ?
                  client !== null && client.length!==0 &&  Array.isArray(client) &&
                  <Fragment>
                    <div className="col-lg-12" >
                    {
                      client.length>1 &&
                        <p className="user-text">Se ha encontrado más de un usuario con este celular, por favor digitar el numero de documento de identidad para proseguir, tiene las siguientes opciones:</p >
                    }
                    {this.renderMembersByPhone(client)}
                    </div>
                  </Fragment>
                  :
                  this.state.type==="QR" ?
                      client !== null &&
                      <Fragment>
                        {
                            client.type!=="MEMBER" ?

                                <div className="col-lg-12 not-sm-padding" >
                                  {
                                      client.type==="DIGITAL_PRODUCT" && client.data && client.data.order_uid &&
                                      <div>
                                        <OrderData
                                            typeOrder={client.type}
                                            getOrder={()=>dispatch(retrieveOrder(client.data.order_uid))}
                                            retrieveStatus={retrieveStatus}
                                            order={order}
                                            updateOrder={(status)=>dispatch(updateOrder({status:status}, client.data.order_uid))}
                                            cleanOrderStatus={()=>dispatch(cleanOrderStatus())}
                                            editStatus={editStatus}
                                            client={client}/>
                                      </div>
                                  }
                                  {
                                      client.type==="ORDER" && client.data && client.data.order_uid &&
                                      <div>
                                        <OrderData
                                            typeOrder={client.type}
                                            getOrder={()=>dispatch(retrieveOrder(client.data.order_uid))}
                                            retrieveStatus={retrieveStatus}
                                            order={order}
                                            updateOrder={(status)=>dispatch(updateOrder({status:status}, client.data.order_uid))}
                                            cleanOrderStatus={()=>dispatch(cleanOrderStatus())}
                                            editStatus={editStatus}
                                            client={client}/>
                                      </div>
                                  }
                                  {
                                      client.type==="PROGRAM_PROMOTION" && client.data && client.data.promotion_uid &&
                                      <div>
                                        <PromotionData
                                            dispatch={dispatch}
                                            getProgramPromotion={()=>dispatch(retrieveProgramPromotion(localStorage.getItem('placeIdRedeem'), client.data.promotion_uid, client.data.document_number, client.data.document_type))}
                                            getClient={()=>dispatch(getClientQR(client.data.document_number))}
                                            retrieveStatus={retrieveStatusFormInitial}
                                            promotion={promotionData}
                                            client={client}
                                            business={business}
                                            isStamping={isStamping}
                                            clientQR={clientQR}/>
                                      </div>
                                  }
                                </div>
                                :
                                client !== null && (!Array.isArray(client) || client.length===1) &&
                                <div className="col-lg-12" >
                                  <p className="user-text" >
                                    CLIENTE <br /> <span >{client.data.first_name}</span > <br />
                                    {this.renderDocumentType(client.data.document_type)} <br /> <span >{client.data.document_number}</span > <br />
                                  </p >
                                  <p className="user-text" > Tiene las siguientes promociones:</p >
                                </div >
                        }
                      </Fragment> :
                  client !== null && (!Array.isArray(client) || client.length===1) &&
                  <div className="col-lg-12 d-none" >
                    <p className="user-text" >
                      Cliente: <span >{client.first_name}</span > <br />
                      {this.state.typeText.toUpperCase()}: <span >{this.state.dniUser}</span > <br />
                    </p >
                    {/* <p className="user-text" > Tiene las siguientes promociones:</p > */}
                  </div >
            }
          </div >
        </div >
      </div >
    );
  }
}

SearchUser.propTypes = {
  dispatch: PropTypes.func,
  isFetchingClient: PropTypes.bool,
  error: PropTypes.string,
  place: PropTypes.object,
  program: PropTypes.object,
  orderDetail: PropTypes.object,
  business: PropTypes.object,
  isStamping: PropTypes.bool,
};
SearchUser.defaultProps = {
  isFetchingClient: false,
  client: null,
  clientQR: null,
  place: null,
  error: '',
  program: null,
  orderDetail: null,
  business: null,
  isStamping: false,
};
export default connect(({ places, client, program, user, orders, promotions }) => ({
  isFetchingClient: client.isFetching,
  client: client.client,
  clientQR: client.clientQR,
  place: places.place,
  error: client.error,
  program: program.dataLocal,
  orderDetail: orders.formInitial,
  business: user.business,
  retrieveStatus: orders.retrieveStatus,
  editStatus: orders.editStatus,
  order: orders.formInitial,
  promotionData: promotions.formInitial,
  retrieveStatusFormInitial: promotions.retrieveStatusFormInitial,
  isStamping: promotions.isStamping
}))(SearchUser);
