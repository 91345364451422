import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SearchHistory from './SearchHistory';
import HistoryListItem from './HistoryListItem';
import {
  getNumberPageHistory,
  setCompanyUidCurrentHistory,
  setFirstListHistory,
  setNumberPageHistory
} from './actions';
import {changeRootMenu} from '../Bederr/actions';
import {selectPlace} from "../ListPlaces/actions";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCurrent: 1,
      firstList: "",
      number: '',
      currentCompanyUid: '',
      place: null,
      uidPlace: "",
      date: null,
      allPlaces: false
    };
  }


  componentDidMount() {
    const { match } = this.props;


    let uidPlace = localStorage.getItem('placeIdRedeem')
    if (uidPlace !== null) {
      if (this.state.uidPlace !== uidPlace ) {
        this.setPlace(uidPlace, localStorage.getItem('placeAddressRedeem'));
      }
    }

    if (match == null) {
      //this.props.dispatch(getHistory( business.uid,'', null));
    } else if (match.params !== undefined && match.params !== null) {
      const pageUrl = match.params.page;
      if (pageUrl !== undefined && pageUrl !== null) {
        if (uidPlace && uidPlace!=="") {
          //this.props.dispatch(getHistory(business.uid, uidPlace, null));
        }
      } else {
        if (uidPlace && uidPlace!=="") {
          //this.props.dispatch(getHistory(business.uid, uidPlace, null));
        }
      }
    }
    this.props.dispatch(changeRootMenu('HISTORIAL'));

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match, listStatus, results,entities, firstList, currentPageNumber  } = this.props;
    let uidPlace = localStorage.getItem('placeIdRedeem')
    let currentCompanyUidHistory = localStorage.getItem("companyUid") ? localStorage.getItem("companyUid") : ''

    if (match == null) {
      //this.props.dispatch(getHistory( business.uid,'', null));
    } else if (match.params !== undefined && match.params !== null) {
      const pageUrl = match.params.page;
      if (pageUrl !== undefined && pageUrl !== null) {
        if (uidPlace && uidPlace!=="") {
          //this.props.dispatch(getHistory(business.uid, uidPlace, null));
        }
      } else {
        if (uidPlace && uidPlace!=="") {
          //this.props.dispatch(getHistory(business.uid, uidPlace, null));
        }
      }
    }
    if(currentCompanyUidHistory!==this.state.currentCompanyUid){
      this.props.dispatch(setNumberPageHistory(1))
      this.props.dispatch(setCompanyUidCurrentHistory(currentCompanyUidHistory))
    }

    let aux = []
    if(listStatus==="success"){
      let redemptions = results.map(pUid => entities[pUid])
      redemptions.map(c=>{
        aux.push(c.uid)
        return true
      })
    }

    if(listStatus==="success" && firstList!==JSON.stringify(aux) && currentPageNumber===1){
      this.setState({firstList: JSON.stringify(aux)})
      this.props.dispatch(setFirstListHistory(JSON.stringify(aux)))
      this.props.dispatch(setNumberPageHistory(1))
    }

    if(this.state.uidPlace!==localStorage.getItem("placeIdRedeem")){
      this.setState({pageCurrent: 1, uidPlace: localStorage.getItem("placeIdRedeem")})
      console.log("cambio")
    }

  }

  setPlace(uidPlace, address) {
    this.setState({uidPlace:uidPlace});
    window.localStorage.setItem('placeIdRedeem', uidPlace);
    if(address!==undefined)
      this.props.dispatch(selectPlace(uidPlace, address));
  }

  render() {
    const {
      count, history,
      next, previous,
      listStatus,
      results,
      entities,
      currentCompanyUid
    } = this.props;
    return (
      <div>
        <SearchHistory
            setPageCurrent={pageCurrent=> {
              this.setState({pageCurrent: pageCurrent})
              this.props.dispatch(setNumberPageHistory(pageCurrent))
            }}
            setDate={date=> {
              this.setState({date: date})
              this.setState({pageCurrent: 1})
              this.props.dispatch(setNumberPageHistory(1))
            }}
            setAllPlaces={bool=> {
              this.setState({allPlaces: bool})
              this.setState({pageCurrent: 1})
              this.props.dispatch(setNumberPageHistory(1))
            }}
            count={count}
        />
        <div className="container" >
          <HistoryListItem
              date={this.state.date}
              allPlaces={this.state.allPlaces}
              next={next}
              previous={previous}
              listOrderStatus={listStatus}
              currentCompanyUid={currentCompanyUid}
              getNumberPageHistory={(page) => {
                if (this.state.uidPlace && this.state.uidPlace!=="" && !this.state.allPlaces) {
                  this.props.dispatch(getNumberPageHistory(localStorage.getItem("companyUid"), this.state.uidPlace, this.state.date, page))
                }else{
                  this.props.dispatch(getNumberPageHistory(localStorage.getItem("companyUid"), null, this.state.date, page))
                }
              }}
              redemptions={results && entities && results.map(pUid => entities[pUid])}
              count={count}
              history={history}
              pageCurrent={this.state.pageCurrent}
              setPageCurrent={pageCurrent=> {
                this.setState({pageCurrent: pageCurrent})
                this.props.dispatch(setNumberPageHistory(pageCurrent))
              }}
          />
        </div>
      </div>
    );
  }
}
History.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  business: PropTypes.object
};
History.defaultProps = {
  match: null,
  business: null,
};
export default connect(({places, user, history}) => ({
  business : user.business,
  currentPlaceUid: places.currentPlaceUid,
  place: places.place,

  firstList: history.firstList,
  currentCompanyUid: history.currentCompanyUid,
  currentPageNumber: history.currentPageNumber,
  count: history.count,
  listStatus: history.listStatus,
  results: history.results,
  entities: history.entities,
  next: history.next,
  previous: history.previous,
}))(History);
