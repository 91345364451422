import {
    APPLIED,
    APPLIED_BOC,
    CLEAN_APPLIED,
    CLEAN_APPLIED_BOC,
    EDIT_PROMOTION,
    MESSAGE,
    MORE_PROMOTIONS,
    PROMOTIONS,
    PRODUCTS_REDEEMABLES,
    PROMOTIONS_CLEAN,
    SEARCH_PROGRAM_PROMOTION,
    SELECT_PROMOTION,
    STAMP_PROGRAM_PROMOTION,
    STAMP_PROGRAM_PROMOTION_QR,
    MORE_PRODUCTS_REDEEMABLES,
    SEARCH_PRODUCT_REDEEMABLE,
    PRODUCT_REDEEMABLE_CLEAN, REDEEM_PRODUCT, REDEEM_PRODUCT_REDEEMABLE_CLEAN
} from '../containers/Promotions/constants';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../containers/Bederr/constants";


const initialState = {
  data: null,
  formInitial: null,
  next: null,
  isFetching: false,
  isFetchingFormInitial: false,
  redemption: null,
  count: 0,
  error: null,
  promotion: null,
  message: '',
  errorApplied: null,
  isFetchingApplied: false,
  errorAppliedBoc: null,
  isFetchingAppliedBoc: false,
  isStamping: false,
  errorStamp: '',
  applyCode: 0,
  applyCodeBoc: 0,
  report: null,
  isFetchingMore: false
};

export default function reducer(state = initialState, action) {
    const {type, payload = {data: {}}} = action;
    if (type.endsWith('_FULFILLED') && payload.status === 401) {
        //localStorage.removeItem('token');
        localStorage.clear();
        return {
            ...state,
            token: null,
        };
    }
  switch (action.type) {
    case `${PROMOTIONS}_PENDING`:
      return { ...state, isFetching: true, next: null };
    case `${PROMOTIONS}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${PROMOTIONS}_FULFILLED`: {
      const newData = [];
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.results !== []) {
          for (let i = 0; i < action.payload.data.results.length; i++) {
            const obj = action.payload.data.results[i];
            obj.stamping = false;
            newData.push(obj);
          }
        }
      }
      return Object.assign({},
        state, {
          isFetching: false,
          data: newData,
          next: action.payload !== undefined && action.payload.data.next,
          count: action.payload !== undefined && action.payload.data.count
        }
      );
    }


    case `${PRODUCTS_REDEEMABLES}_PENDING`:
      return { ...state, isFetchingProductsRedeemable: true, nextProductsRedeemable: null };
    case `${PRODUCTS_REDEEMABLES}_REJECTED`:
      return { ...state, isFetchingProductsRedeemable: false, error: action.payload };
    case `${PRODUCTS_REDEEMABLES}_FULFILLED`: {
      const newDataProductsRedeemable = [];
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.results !== []) {
          for (let i = 0; i < action.payload.data.results.length; i++) {
            const obj = action.payload.data.results[i];
            obj.stamping = false;
            newDataProductsRedeemable.push(obj);
          }
        }
      }
      return Object.assign({},
        state, {
          isFetchingProductsRedeemable: false,
          dataProductsRedeemable: newDataProductsRedeemable,
          nextProductsRedeemable: action.payload !== undefined && action.payload.data.next,
          countProductsRedeemable: action.payload !== undefined && action.payload.data.count
        }
      );
    }


    case `${MORE_PROMOTIONS}_PENDING`:
      return { ...state, isFetchingMore: true };
    case `${MORE_PROMOTIONS}_REJECTED`:
      return { ...state, isFetchingMore: false, error: action.payload };
    case `${MORE_PROMOTIONS}_FULFILLED`:
      const moreData = state.data;
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.results !== []) {
          for (let i = 0; i < action.payload.data.results.length; i++) {
            const obj = action.payload.data.results[i];
            obj.stamping = false;
            moreData.push(obj);
          }
          return {
            ...state,
            isFetchingMore: false,
            data: moreData,
            next: action.payload.data.next,
            count: action.payload.data.count
          };
        }
        return {
          ...state,
          isFetchingMore: false,
          next: null,
        };
      }
      return {
        ...state,
        isFetchingMore: false,
        next: null,
      };

    case `${MORE_PRODUCTS_REDEEMABLES}_PENDING`:
      return { ...state, isFetchingMoreProductsRedeemable: true };
    case `${MORE_PRODUCTS_REDEEMABLES}_REJECTED`:
      return { ...state, isFetchingMoreProductsRedeemable: false, error: action.payload };
    case `${MORE_PRODUCTS_REDEEMABLES}_FULFILLED`:
      const moreDataProductsRedeemable = state.data;
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data.results !== []) {
          for (let i = 0; i < action.payload.data.results.length; i++) {
            const obj = action.payload.data.results[i];
            obj.stamping = false;
            moreDataProductsRedeemable.push(obj);
          }
          return {
            ...state,
            isFetchingMoreProductsRedeemable: false,
            dataProductsRedeemable: moreDataProductsRedeemable,
            nextProductsRedeemable: action.payload.data.next,
            countProductsRedeemable: action.payload.data.count
          };
        }
        return {
          ...state,
          isFetchingMoreProductsRedeemable: false,
          nextProductsRedeemable: null,
        };
      }
      return {
        ...state,
        isFetchingMoreProductsRedeemable: false,
        nextProductsRedeemable: null,
      };


      case `${SEARCH_PROGRAM_PROMOTION}_PENDING`:
          return {...state, isFetching: true, data: null};
      case `${SEARCH_PROGRAM_PROMOTION}_REJECTED`:
          return {...state, isFetching: false, error: action.payload};
      case `${SEARCH_PROGRAM_PROMOTION}_FULFILLED`: {
          return {
              ...state,
              retrieveStatusFormInitial: false,
              formInitial: action.payload && action.payload.data ? action.payload.data : {},
              error: null,
              isFetching: false
          };
      }

      case `${SEARCH_PRODUCT_REDEEMABLE}_PENDING`:
          return {...state, productsByPlaceCurrentStatus: STATUS_LOADING};
      case `${SEARCH_PRODUCT_REDEEMABLE}_REJECTED`:
          return {...state, productsByPlaceCurrentStatus: STATUS_ERROR, error: action.payload};
      case `${SEARCH_PRODUCT_REDEEMABLE}_FULFILLED`: {
          return {
              ...state,
              productsByPlaceCurrentStatus: STATUS_SUCCESS,
              productsByPlaceCurrent: action.payload && action.payload.data ? action.payload.data : {},
              error: null
          };
      }

    case `${APPLIED}_PENDING`:
      return { ...state, isFetchingApplied: true, applyCode: 0 };
    case `${APPLIED}_REJECTED`:

        return { ...state, isFetchingApplied: false, errorApplied: 'No se pudo completar la solicitud' };

    case `${APPLIED}_FULFILLED`: {
        if (action.payload !== undefined) {
            if (action.payload.data !== undefined) {
                if (action.payload.status !== undefined
                    && action.payload.status !== null
                    && (action.payload.status === 400)) {
                    console.log(action.payload.data)
                    return Object.assign({},
                        state, {
                            isFetchingApplied: false,
                            errorApplied: action.payload.data.program_promotion ? action.payload.data.program_promotion : 'No se pudo completar la solicitud' ,
                        }
                    );
                }
            }
        }
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {
            return Object.assign({},
              state, {
                isFetchingApplied: false,
                applyCode: action.payload.status,
                redemption: action.payload.data,
                errorApplied: '',
                message: 'La oferta fue validada correctamente'
              }
            );
          }
            if (action.payload.status !== undefined
                && action.payload.status !== null
                && (action.payload.status === 400 || action.payload.status === 401 || action.payload.status === 403)) {
                return Object.assign({},
                    state, {
                        isFetchingApplied: false,
                        applyCode: action.payload.status,
                        redemption: null,
                        errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
                    }
                );
            }
          return Object.assign({},
            state, {
              isFetchingApplied: false,
              applyCode: action.payload.status,
              redemption: null,
              errorApplied: ''
            }
          );
        }
        return Object.assign({},
          state, {
            isFetchingApplied: false,
            applyCode: action.payload.status,
            errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          applyCode: 400,
          errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }

    case `${APPLIED_BOC}_PENDING`:
      return { ...state, isFetchingAppliedBoc: true, applyCodeBoc: 0 };
    case `${APPLIED_BOC}_REJECTED`:
      return { ...state, isFetchingAppliedBoc: false, errorAppliedBoc: 'No se pudo completar la solicitud' };
    case `${APPLIED_BOC}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {
            return Object.assign({},
              state, {
                isFetchingAppliedBoc: false,
                applyCodeBoc: 200,
                errorAppliedBoc: '',
                messageBoc: 'La oferta fue validada correctamente'
              }
            );
          }
          return Object.assign({},
            state, {
              isFetchingAppliedBoc: false,
              applyCodeBoc: 400,
              errorAppliedBoc: 'Algo sucedió mal. Por favor intentarlo nuevamente'
            }
          );
        }
        return Object.assign({},
          state, {
            isFetchingAppliedBoc: false,
            applyCodeBoc: 400,
            errorAppliedBoc: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isFetchingAppliedBoc: false,
          applyCodeBoc: 400,
          errorAppliedBoc: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }
    case `${STAMP_PROGRAM_PROMOTION}_PENDING`:
      return { ...state, isStamping: true, errorStamp: '' };
    case `${STAMP_PROGRAM_PROMOTION}_REJECTED`:
      return { ...state, isStamping: false, errorStamp: 'No se pudo completar la solicitud' };
    case `${STAMP_PROGRAM_PROMOTION}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {
            const newPromotions = [];
            newPromotions.push(...state.data);
            for (let i = 0; i < newPromotions.length; i++) {
              if (newPromotions[i].stamping) {
                const p = newPromotions[i];
                p.stamping = false;
                p.available_stamps += 1;
                newPromotions[i] = p;
                break;
              }
            }
            return Object.assign({},
              state, {
                isStamping: false,
                data: newPromotions
              }
            );
          }
          return Object.assign({},
            state, {
              isFetchingApplied: false,
              applyCode: 400,
              errorStamp: 'Algo sucedió mal. Por favor intentarlo nuevamente'
            }
          );
        }
        return Object.assign({},
          state, {
            isFetchingApplied: false,
            applyCode: 400,
            errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          applyCode: 400,
          errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }

    case `${REDEEM_PRODUCT}_PENDING`:
      return { ...state, isRedeem: STATUS_LOADING, errorRedeem: '' };
    case `${REDEEM_PRODUCT}_REJECTED`:
      return { ...state, isRedeem: STATUS_ERROR, errorRedeem: 'No se pudo completar la solicitud' };
    case `${REDEEM_PRODUCT}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {

            return Object.assign({},
              state, {
                isRedeem: STATUS_SUCCESS,
                dataRedeem: action.payload ? action.payload.data : null
              }
            );
          }
          return Object.assign({},
            state, {
                  isRedeem: STATUS_ERROR,
                  errorRedeem: 'Algo sucedió mal. Por favor intentarlo nuevamente'
            }
          );
        }
        return Object.assign({},
          state, {
                isRedeem: STATUS_ERROR,
                errorRedeem: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isRedeem: STATUS_ERROR,
          errorRedeem: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }



    case `${STAMP_PROGRAM_PROMOTION_QR}_PENDING`:
      return { ...state, isStamping: true, errorStamp: '' };
    case `${STAMP_PROGRAM_PROMOTION_QR}_REJECTED`:
      return { ...state, isStamping: false, errorStamp: 'No se pudo completar la solicitud' };
    case `${STAMP_PROGRAM_PROMOTION_QR}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {
              let aux = state.formInitial
              aux.available_stamps += 1;
            return Object.assign({},
              state, {
                isStamping: false,
                formInitial: aux
              }
            );
          }
          return Object.assign({},
            state, {
              isFetchingApplied: false,
              applyCode: 400,
              errorStamp: 'Algo sucedió mal. Por favor intentarlo nuevamente'
            }
          );
        }
        return Object.assign({},
          state, {
            isFetchingApplied: false,
            applyCode: 400,
            errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          applyCode: 400,
          errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }


    case `${PROMOTIONS_CLEAN}`:
      return Object.assign({},
        state, {
          isFetching: false,
          data: null,
          next: null
        }
      );
    case `${PRODUCT_REDEEMABLE_CLEAN}`:
      return Object.assign({},
        state, {
          productsByPlaceCurrentStatus: null,
          productsByPlaceCurrent: null,
          dataRedeem: null
        }
      );
    case `${REDEEM_PRODUCT_REDEEMABLE_CLEAN}`:
      return Object.assign({},
        state, {
          productsByPlaceCurrentStatus: null,
          productsByPlaceCurrent: null,
          isRedeem: null,
          dataRedeem: null
        }
      );
    case `${SELECT_PROMOTION}`:
      return Object.assign({},
        state, {
          promotion: action.promotion
        }
      );
    case `${EDIT_PROMOTION}`:
      const promotions = [];
      promotions.push(...state.data);
      for (let i = 0; i < promotions.length; i++) {
        if (promotions[i].uid === action.promotion.uid) {
          promotions[i] = action.promotion;
          break;
        }
      }
      return Object.assign({},
        state, {
          data: promotions
        }
      );
    case `${MESSAGE}`:
      return Object.assign({},
        state, {
          message: action.message
        }
      );
    case `${CLEAN_APPLIED}`:
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          redemption: null,
          applyCode: 0,
          errorApplied: ''
        }
      );
    case `${CLEAN_APPLIED_BOC}`:
      return Object.assign({},
        state, {
          isFetchingAppliedBoc: false,
          applyCodeBoc: 0,
          errorAppliedBoc: ''
        }
      );
    default:
      return state;
  }
}
