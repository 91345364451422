/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducersapp accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const PROMOTIONS = 'PROMOTIONS';
export const PRODUCTS_REDEEMABLES = 'PRODUCTS_REDEEMABLES';
export const MORE_PROMOTIONS = 'MORE_PROMOTIONS';
export const MORE_PRODUCTS_REDEEMABLES = 'MORE_PRODUCTS_REDEEMABLES';
export const CLIENT = 'CLIENT';
export const CLIENT_QR = 'CLIENT_QR';
export const CLEAN_CLIENT = 'CLEAN_CLIENT';
export const DNI_CLIENT = 'DNI_CLIENT';
export const SELECT_PROMOTION = 'SELECT_PROMOTION';
export const EDIT_PROMOTION = 'EDIT_PROMOTION';
export const SET_CLIENT_PROGRAM = 'SET_CLIENT_PROGRAM';

export const USER_PROGRAM = 'USER_PROGRAM';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const PROMOTIONS_CLEAN = 'PROMOTIONS_CLEAN';
export const PRODUCT_REDEEMABLE_CLEAN = 'PRODUCT_REDEEMABLE_CLEAN';
export const REDEEM_PRODUCT_REDEEMABLE_CLEAN = 'REDEEM_PRODUCT_REDEEMABLE_CLEAN';
export const APPLIED = 'APPLIED';
export const APPLIED_BOC = 'APPLIED_BOC';
export const APPLIED_POINTS = 'APPLIED_POINTS';
export const STAMP_PROGRAM_PROMOTION = 'STAMP_PROGRAM_PROMOTION';
export const REDEEM_PRODUCT = 'REDEEM_PRODUCT';
export const SEARCH_PROGRAM = 'SEARCH_PROGRAM';
export const SEARCH_PROGRAM_PROMOTION = 'SEARCH_PROGRAM_PROMOTION';
export const SEARCH_PRODUCT_REDEEMABLE = 'SEARCH_PRODUCT_REDEEMABLE';
export const STAMP_PROGRAM_PROMOTION_QR = 'STAMP_PROGRAM_PROMOTION_QR';
export const CLEAN_PROGRAM = 'SEARCH_PROGRAM';
export const MESSAGE = 'MESSAGE';
export const CLEAN_APPLIED = 'CLEAN_APPLIED';
export const CLEAN_APPLIED_BOC = 'CLEAN_APPLIED_BOC';

