import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {AlertList} from 'react-bs-notifier';
import SearchUser from './SearchUser';
import PromotionsListItem from './PromotionsListItem';
import {sendMessage} from './actions';
import {changeRootMenu} from '../Bederr/actions';
//import SelectCurrentProgram from "./SelectCurrentProgram";


class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 'top-right',
      alerts: [],
      timeout: 0,
      newMessage: '',
      documentNumber: null,
      type: null,
      searchUserBool: false
    };
  }


  componentDidMount() {
    this.props.dispatch(changeRootMenu('VALIDADOR'));
  }

  componentWillReceiveProps(nextProps) {
    const { message } = nextProps;
    this.generate(message);
  }
  onAlertDismissed(alert) {
    const alertsList = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alertsList.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alertsList.slice(0, idx), ...alertsList.slice(idx + 1)]
      });
    }
  }
  generate(message) {
    if (message !== this.state.newMessage && message !== '') {
      const newAlert = {
        id: (new Date()).getTime(),
        type: 'success',
        headline: '',
        message
      };

      this.setState({
        newMessage: message,
        alerts: [...this.state.alerts, newAlert]
      });
      this.props.dispatch(sendMessage(''));
    }else{
      setTimeout(() => {
        this.setState({
          newMessage: "",
          alerts: []
        });
      }, 2200)

    }
  }
  render() {
    let {client} = this.props;
    return (
      <div>
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
        {/* <SelectCurrentProgram /> */}
        <SearchUser
            searchUserBool={this.state.searchUserBool}
            setSearch={(s)=>this.setState({searchUserBool: s})}
            setUserData={(documentNumber, type)=>this.setState({documentNumber: documentNumber, type: type})}
        />
        {
          client && client.type!=="DIGITAL_PRODUCT" && client.type!=="ORDER" && client.type!=="PROGRAM_PROMOTION" &&
            <div className="container" >
              <div className="row" >
                <PromotionsListItem
                    documentNumber={this.state.documentNumber}
                    type={this.state.type}
                    setSearch={(s)=>this.setState({searchUserBool: s})}
                />
              </div>
            </div>
        }
      </div>
    );
  }
}
Promotions.propTypes = {
  dispatch: PropTypes.func,
  message: PropTypes.string,
};
export default connect(({ promotions, client }) => ({
  message: promotions.message,
    client: client.client,
}))(Promotions);
