import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  applied,
  cleanApplied,
  getPromotions,
  selectPromotion,
  appliedRedemptionBOC,
  cleanAppliedBoc,
} from "./actions";
import Validator from "../../utils/validator";
import LoadingIndicator from "../../components/LoadingIndicator";
import { VerifyClientModalMessage } from "../../components/VeryfyClientMessage";
import Stamps from "./components/Stamps";

const $ = require("jquery");

class ModalValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: 0.0,
      discount: 0.0,
      total: 0.0,
      textMount: "",
      gift_card_max: 0.0,
      gift_card_total_full: 0,
      number_gift_cards: 0,
      comments: "",
      error: "",
      textValidateButton: "Registrar",
    };
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  componentDidMount() {
    $(this.modal).modal("show");
    $(this.modal).on("hidden.bs.modal");
    this.validator = new Validator([
      {
        id: "amount",
        ref: this.amountInput,
        rules: ["required", "numeric"],
      },
      {
        id: "amount",
        ref: this.commentsInput,
        rules: ["text"],
      },
    ]);
    const { offer } = this.props;
    if (offer.promotion.type === "GIFT_CARD") {
      //GIFT_CARD LOGIC
      if (offer.purchase != null) {
        if (offer.purchase.quantity > 0 && offer.purchase.total_balance > 0) {
          let number_gift_cards = Math.trunc(
            offer.purchase.total_balance / offer.promotion.original_price
          );
          let extra =
            offer.purchase.total_balance % offer.promotion.original_price;
          if (extra > 0) {
            this.setState({
              gift_card_max: extra,
              gift_card_extra: extra,
              number_gift_cards: number_gift_cards,
              gift_card_total_full: number_gift_cards,
            });
          } else {
            this.setState({
              gift_card_max: offer.promotion.original_price,
              gift_card_extra: extra,
              number_gift_cards: number_gift_cards,
              gift_card_total_full: number_gift_cards,
            });
          }
        } else {
        }
      }
    }
  }

  handleCloseClick() {
    this.props.dispatch(selectPromotion(null));
    $(this.modal).modal("hide");
  }

  componentWillReceiveProps(nextProps) {
    const { errorApplied, code, client, codeBoc, errorAppliedBoc, redemption } =
      nextProps;
    if (code === 200 || code === 201) {
      if (
        redemption &&
        redemption.program_promotion &&
        redemption.program_promotion.program_name === "Banco de Comercio"
      ) {
        this.props.dispatch(
          appliedRedemptionBOC({
            member: redemption.member.uid,
            redemption: redemption.uid,
          })
        );
      }

      this.props.dispatch(cleanApplied());
      this.setState({
        error: "",
        textValidateButton: "Registrar",
      });
      this.props.dispatch(
        getPromotions(
          client.document_number,
          client.document_type,
          localStorage.getItem("placeIdRedeem")
        )
      );
      this.handleCloseClick();
    } else if (errorApplied !== "") {
      console.log(errorApplied, "errorApplied");
      this.setState({
        error: errorApplied,
        textValidateButton: "Registrar",
      });
    }
    if (codeBoc === 200 || codeBoc === 201) {
      this.props.dispatch(cleanAppliedBoc());
      this.setState({
        error: "",
        textValidateButton: "Registrar",
      });
      this.props.dispatch(
        getPromotions(
          client.document_number,
          client.document_type,
          localStorage.getItem("placeIdRedeem")
        )
      );
      this.handleCloseClick();
    } else {
      this.setState({
        error: errorAppliedBoc,
        textValidateButton: "Registrar",
      });
    }
  }

  handleMount = async (event) => {
    const { offer, business } = this.props;
    let amount = event.target.value;
    let currencySymbol = "S/";
    if (business && business.currency)
      currencySymbol = business.currency.symbol;
    amount = amount.replace(currencySymbol, "").replace(" ", "");

    if (offer.promotion.type === "GIFT_CARD") {
      //GIFT_CARD LOGIC
      let amountPrice = 0.0;
      try {
        amountPrice = Math.round(amount * 100) / 100;
      } catch (e) {}

      if (amountPrice > this.state.gift_card_max) {
        this.setState({
          error: "El monto supera el máximo",
          textMount: `${currencySymbol} ${amountPrice}`,
          mount: amountPrice,
        });
      } else {
        this.setState({
          //error: '',
          textMount: `${currencySymbol} ${amountPrice}`,
          mount: amountPrice,
        });
      }
    } else {
      if (amount !== "" && amount !== this.state.mount) {
        let d = 0;
        if (offer.promotion.rate_discount != null) {
          d = (amount * offer.promotion.rate_discount) / 100;
        }
        if (
          offer.promotion.maximum_discount_amount != null &&
          offer.promotion.rate_discount !== 100
        ) {
          if (d > offer.promotion.maximum_discount_amount && offer.promotion.maximum_discount_amount!=="0.00") {
            d = offer.promotion.maximum_discount_amount;
          }
        }

        const t = amount - d;
        this.setState({
          mount: Math.round(amount * 100) / 100,
          textMount: `${currencySymbol} ${amount}`,
          discount: Math.round(d * 100) / 100,
          total: Math.round(t * 100) / 100,
          //error: ''
        });
      } else {
        this.setState({
          mount: 0.0,
          discount: 0.0,
          total: 0.0,
          textMount: "",
          //error: ''
        });
      }
    }
  };

  handleComments = async (event) => {
    this.setState({ comments: event.target.value });
  };

  onSubmitValidate(e) {
    e.preventDefault();
    const { offer } = this.props;
    if (offer.promotion.type === "FIXED") {
      if (this.state.textValidateButton === "Registrar") {
        this.setState({ error: "" });
        this.props.dispatch(
          applied({
            place: localStorage.getItem("placeIdRedeem"),
            member: offer.member_uid,
            program_promotion: offer.uid,
            comments: this.state.comments,
          })
        );
      }
    } else if (offer.promotion.type === "GIFT_CARD") {
      if (
        this.state.textValidateButton === "Registrar" &&
        this.state.error.length === 0
      ) {
        this.setState({ error: "" });
        this.props.dispatch(
          applied({
            amount: this.state.mount,
            place: localStorage.getItem("placeIdRedeem"),
            member: offer.member_uid,
            promotion_purchased: offer.purchase.uid,
            program_promotion: offer.uid,
            comments: this.state.comments,
          })
        );
      }
    } else if (
      this.state.textValidateButton === "Registrar" &&
      this.validator.validate({ amount: this.state.mount })
    ) {
      this.setState({ error: "" });
      this.props.dispatch(
        applied({
          amount: this.state.mount,
          place: localStorage.getItem("placeIdRedeem"),
          member: offer.member_uid,
          program_promotion: offer.uid,
          comments: this.state.comments,
        })
      );
    }
  }

  renderError(errors){
    let aux = ""
    if(Object.values(errors).length!==0){
      Object.values(errors).map(e => {
        aux += e
        return true
      })
    }
    return aux
  }

  render() {
    const { offer, isFetchingApplied, clientUser, dni, business } = this.props;
    //console.log(this.props, "modal");
    if (offer != null) {
      if (offer.promotion.type === "FIXED") {
        const price = offer.promotion.fixed_discount;
        const color = offer.color==="" ?"#1abc9c" : offer.color;
        return (
          <div>
            <div
              className="modal fade"
              ref={(modal) => (this.modal = modal)}
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div onClick={this.handleCloseClick} className="close">
                      <span aria-hidden="true">x</span>
                    </div>
                  </div>
                  <div className="modal-container-promotions ">
                    <h5>Canjear promocion</h5>
                    <br />
                    <div className="client-modal">
                      <h4>
                        Cliente: <span>{clientUser.first_name}</span>
                      </h4>
                      <h4>
                        DNI: <span>{dni}</span>
                      </h4>
                    </div>
                    <div className="discount-box">
                      <div
                        className="box-detail-promo"
                        style={{
                          background: `${
                            color.includes("#")
                              ? color
                              : `#${color}`
                          }`,
                        }}
                      >
                        {offer.promotion.type === "FIXED" && (
                          <>
                            {offer.promotion.fixed_discount && (
                              <>
                                {business.currency.symbol && (
                                  <h1>{business.currency.symbol}</h1>
                                )}
                                <h1>
                                  {offer.promotion.fixed_discount.replace(
                                    ".",
                                    ","
                                  )}
                                </h1>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="discount-dateBox">
                        <h1>{offer?.promotion.title}</h1>
                        <p>{offer?.promotion.content}</p>
                        <div
                          className="discount-date"
                          style={{
                            background: `${
                              color.includes("#")
                                ? color
                                : `#${color}`
                            }`,
                          }}
                        ></div>
                      </div>
                    </div>

                    <label>Total a cobrar</label>
                    <div className="box-total">
                      <p className="form-control input-search input-amount">
                        {business &&
                          business.currency &&
                          business.currency.symbol}
                        {price}
                      </p>
                    </div>
                    <label>Adicionar comentario</label>
                    <div
                      className="form-group"
                      ref={(input) => {
                        if (input) {
                          this.commentsInput = input.querySelector("input");
                        }
                      }}
                    >
                      <textarea
                        id="comments"
                        value={this.state.comments}
                        onChange={this.handleComments}
                        className="form-control input-search input-amount"
                        type="text"
                        placeholder={`Comentario`}
                        rows="5"
                        cols="33"
                      />
                    </div>
                    {/* <VerifyClientModalMessage client={clientUser} dni={dni} /> */}
                    {isFetchingApplied && <LoadingIndicator></LoadingIndicator>}
                    {this.props.errorApplied && this.props.errorApplied !== "" && (
                      <div
                        className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>
                          {this.props.errorApplied.program_promotion !==null && "La promocion no esta disponible"}
                        </strong>
                      </div>
                    )}
                    {isFetchingApplied === false &&
                      this.state.error &&
                      this.state.error !== "" && (
                        <div
                          className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>{this.state.error}</strong>
                        </div>
                      )}
                  </div>
                  <div className="modal-footer justify-content-center">
                    {/* <button
                      type="button"
                      className="btn btn-whitelabel-auxiliar col-lg-3"
                      onClick={this.handleCloseClick}
                    >
                      Cancelar
                    </button> */}
                    <button
                      type="submit"
                      className={!isFetchingApplied ? "btn-register-modal" : "btn-register-modal btn-load"}
                      onClick={(e) => this.onSubmitValidate(e)}
                    >
                      {this.state.textValidateButton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      // if (offer.promotion.type === "GIFT_CARD") {
      //   return (
      //     <div>
      //       <div
      //         className="modal fade"
      //         ref={(modal) => (this.modal = modal)}
      //         id="exampleModal"
      //         tabIndex="-1"
      //         role="dialog"
      //         aria-labelledby="exampleModalLabel"
      //         aria-hidden="true"
      //       >
      //         <div className="modal-dialog  modal-lg" role="document">
      //           <div className="modal-content">
      //             <div className="modal-header">
      //               <button
      //                 onClick={this.handleCloseClick}
      //                 type="button"
      //                 className="close"
      //               >
      //                 <span aria-hidden="true">&times;</span>
      //               </button>
      //             </div>
      //             <div className="modal-body ">
      //               <div className="col-lg-12 justify-content-center data">
      //                 <h4>{offer.title}</h4>
      //                 <h5>Ingrese el monto total de la cuenta</h5>
      //                 <div
      //                   className="form-group"
      //                   ref={(input) => {
      //                     if (input) {
      //                       this.amountInput = input.querySelector("input");
      //                     }
      //                   }}
      //                 >
      //                   <input
      //                     id="amount"
      //                     value={this.state.textMount}
      //                     onChange={this.handleMount}
      //                     className="form-control col-lg-5 input-search input-amount"
      //                     type="text"
      //                     placeholder={`${
      //                       business &&
      //                       business.currency &&
      //                       business.currency.symbol
      //                     } 0.0`}
      //                   />
      //                 </div>
      //                 <p className="description" style={{ paddingTop: 5 }}>
      //                   Recuerda solo redimir una promoción a la vez, si el
      //                   consumo es superior, introducir lo restante en otra
      //                   redención. <br />
      //                   Puedes gastar parte o el total de la promoción
      //                 </p>

      //                 <p className="description">
      //                   Actualmente el usuario tiene
      //                 </p>
      //                 {this.state.gift_card_total_full > 0 && (
      //                   <p className="description" style={{ paddingTop: 5 }}>
      //                     <span>{this.state.gift_card_total_full}</span>{" "}
      //                     {this.state.gift_card_total_full > 1
      //                       ? "promociones"
      //                       : "promoción"}
      //                     de <span>{offer.promotion.original_price}</span>
      //                   </p>
      //                 )}
      //                 {this.state.gift_card_extra > 0 && (
      //                   <p className="description" style={{ paddingTop: 5 }}>
      //                     {this.state.gift_card_total_full > 0 ? "Y" : ""}{" "}
      //                     <span>1</span> promoción de{" "}
      //                     <span>{this.state.gift_card_extra}</span>
      //                   </p>
      //                 )}
      //                 <p className="description" style={{ paddingTop: 5 }}>
      //                   Por lo tanto puedes gastar hasta
      //                   {business &&
      //                     business.currency &&
      //                     business.currency.symbol}{" "}
      //                   <span>{this.state.gift_card_max}</span> en esta
      //                   redencion
      //                 </p>
      //               </div>
      //               <div className="col-lg-12 justify-content-center data">
      //                 <h5>Ingrese un comentario</h5>
      //                 <div
      //                   className="form-group"
      //                   ref={(input) => {
      //                     if (input) {
      //                       this.commentsInput = input.querySelector("input");
      //                     }
      //                   }}
      //                 >
      //                   <input
      //                     id="comments"
      //                     value={this.state.comments}
      //                     onChange={this.handleComments}
      //                     className="form-control col-lg-5 input-search input-amount"
      //                     type="text"
      //                     placeholder={`Comentario`}
      //                   />
      //                 </div>
      //               </div>
      //               <VerifyClientModalMessage client={clientUser} dni={dni} />
      //               {isFetchingApplied && <LoadingIndicator></LoadingIndicator>}
      //               {this.props.errorApplied && this.props.errorApplied !== "" && (
      //                 <div
      //                   className=" col-lg-12 alert alert-warning alert-dismissible fade show"
      //                   role="alert"
      //                 >
      //                   <strong>{this.props.errorApplied}</strong>
      //                 </div>
      //               )}
      //               {isFetchingApplied === false &&
      //                 this.state.error &&
      //                 this.state.error !== "" && (
      //                   <div
      //                     className=" col-lg-12 alert alert-warning alert-dismissible fade show"
      //                     role="alert"
      //                   >
      //                     <strong>{this.state.error}</strong>
      //                   </div>
      //                 )}
      //             </div>
      //             <div className="modal-footer justify-content-center">
      //               <button
      //                 type="button"
      //                 className="btn btn-whitelabel-auxiliar col-lg-3"
      //                 onClick={this.handleCloseClick}
      //               >
      //                 Cancelar
      //               </button>
      //               <button
      //                 type="submit"
      //                 className="btn btn-whitelabel-secondary col-lg-3"
      //                 onClick={(e) => this.onSubmitValidate(e)}
      //               >
      //                 {this.state.textValidateButton}
      //               </button>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   );
      // }
      if (offer.promotion.type === "RATE" || offer.promotion.type === "SPECIAL_PRICE" || offer.promotion.type === "GIFT") {
        const color = offer.color==="" ?"#1abc9c" : offer.color;
        return (
          <div
            className="modal fade"
            ref={(modal) => (this.modal = modal)}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div onClick={this.handleCloseClick} className="close">
                    <span aria-hidden="true">x</span>
                  </div>
                </div>
                <div className="modal-container-promotions">
                  <h5>Canjear promocion</h5>
                  <br />
                  <div className="client-modal">
                    <h4>
                      Cliente: <span>{clientUser.first_name}</span>
                    </h4>
                    <h4>
                      DNI: <span>{dni}</span>
                    </h4>
                  </div>

                  <div className="discount-box">
                    <div
                      className="box-detail-promo"
                      style={{
                        background: `${
                          color.includes("#")
                            ? color
                            : `#${color}`
                        }`,
                      }}
                    >
                      {offer.promotion.type === "RATE" && (
                        <>
                          {offer.promotion.rate_discount === 100 ? (
                            <>
                              <h1>GRA</h1>
                              <h1>TIS</h1>
                            </>
                          ) : (
                            <>
                              <h1>{offer.promotion.rate_discount}%</h1>
                              <h2>de&nbsp;dscto.</h2>
                            </>
                          )}
                        </>
                      )}
                      {(offer.promotion.type === "GIFT" || offer.promotion.type === "SPECIAL_PRICE") && (
                        <>
                           <h1>GRA</h1>
                           <h1>TIS</h1>
                        </>
                      )}
                    </div>
                    <div className="discount-dateBox">
                      <h1>{offer?.promotion.title}</h1>
                      <p>{offer?.promotion.content}</p>
                      <div
                        className="discount-date"
                        style={{
                          background: `${
                            color.includes("#")
                              ? color
                              : `#${color}`
                          }`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <label className={((offer.promotion.type === "RATE" && offer.promotion.rate_discount === 100) || offer.promotion.type === "SPECIAL_PRICE" || offer.promotion.type === "GIFT") ? "d-none" : ""}>Monto de la cuenta</label>
                  <div
                    className={((offer.promotion.type === "RATE" && offer.promotion.rate_discount === 100) || offer.promotion.type === "SPECIAL_PRICE" || offer.promotion.type === "GIFT") ? "d-none" : "form-group"}
                    ref={(input) => {
                      if (input) {
                        this.amountInput = input.querySelector("input");
                      }
                    }}
                  >
                    <input
                      id="amount"
                      value={this.state.textMount}
                      onChange={this.handleMount}
                      className="form-control input-search input-amount"
                      type="text"
                      placeholder={`${
                        business &&
                        business.currency &&
                        business.currency.symbol
                      } 0.0`}
                    />
                  </div>
                  <label className={((offer.promotion.type === "RATE" && offer.promotion.rate_discount === 100) || offer.promotion.type === "SPECIAL_PRICE" || offer.promotion.type === "GIFT") ? "d-none" : ""}>Total a cobrar</label>
                  <div className={((offer.promotion.type === "RATE" && offer.promotion.rate_discount === 100) || offer.promotion.type === "SPECIAL_PRICE" || offer.promotion.type === "GIFT") ? "d-none" : "box-total"}>
                    <p className="form-control input-search input-amount">
                      {business &&
                        business.currency &&
                        business.currency.symbol}
                      {this.state.total}
                    </p>
                  </div>
                  <label>Adicionar comentario</label>
                  <div
                    className="form-group"
                    ref={(input) => {
                      if (input) {
                        this.commentsInput = input.querySelector("input");
                      }
                    }}
                  >
                    <textarea
                      id="comments"
                      value={this.state.comments}
                      onChange={this.handleComments}
                      className="form-control input-search input-amount"
                      type="text"
                      placeholder={`Comentario`}
                      rows="5"
                      cols="33"
                    />
                  </div>
                </div>
                {isFetchingApplied && <LoadingIndicator></LoadingIndicator>}
                {this.props.errorApplied && this.props.errorApplied !== "" && (
                  <div
                    className=" col-lg-12 alert alert-warning alert-dismissible fade show text-center"
                    role="alert"
                  >
                    <strong>{this.renderError(this.props.errorApplied)}</strong>
                  </div>
                )}
                {isFetchingApplied === false &&
                  this.state.error &&
                  this.state.error !== "" && (
                    <div
                      className=" col-lg-12 alert alert-warning alert-dismissible fade show text-center"
                      role="alert"
                    >
                      <strong>{this.state.error}</strong>
                    </div>
                  )}
                <div className="modal-footer justify-content-center">
                  {/* <button
                    type="button"
                    className="btn btn-whitelabel-auxiliar col-lg-3"
                    onClick={this.handleCloseClick}
                  >
                    Cancelar
                  </button> */}
                  <button
                    onClick={(e) => this.onSubmitValidate(e)}
                    type="submit"
                    className={!isFetchingApplied ? "btn-register-modal" : "btn-register-modal btn-load"}
                    placeholder="Registrar"
                  >
                    {this.state.textValidateButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (offer.promotion.type === "STAMPS") {
        return (
          <div
            className="modal fade"
            ref={(modal) => (this.modal = modal)}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div onClick={this.handleCloseClick} className="close">
                    <span aria-hidden="true">x</span>
                  </div>
                </div>
                <div className="modal-container-promotions">
                  <h5>Canjear promocion</h5>
                  <br />
                  <div className="client-modal">
                    <h4>
                      Cliente: <span>{clientUser.first_name}</span>
                    </h4>
                    <h4>
                      DNI: <span>{dni}</span>
                    </h4>
                  </div>

                  <Stamps promotion={offer} imageIcon={false} />

                  <label className="d-none">Monto de la cuenta</label>
                  <div
                    className="form-group d-none"
                    ref={(input) => {
                      if (input) {
                        this.amountInput = input.querySelector("input");
                      }
                    }}
                  >
                    <input
                      id="amount"
                      value={this.state.textMount}
                      onChange={this.handleMount}
                      className="form-control input-search input-amount d-none"
                      type="text"
                      placeholder={`${
                        business &&
                        business.currency &&
                        business.currency.symbol
                      } 0.0`}
                    />
                  </div>
                  <label className="d-none">Total a cobrar</label>
                  <div className="box-total d-none">
                    <p className="form-control input-search input-amount">
                      {business &&
                        business.currency &&
                        business.currency.symbol}
                      {this.state.total}
                    </p>
                  </div>
                  <label>Adicionar comentario</label>
                  <div
                    className="form-group"
                    ref={(input) => {
                      if (input) {
                        this.commentsInput = input.querySelector("input");
                      }
                    }}
                  >
                    <textarea
                      id="comments"
                      value={this.state.comments}
                      onChange={this.handleComments}
                      className="form-control input-search input-amount"
                      type="text"
                      placeholder={`Comentario`}
                      rows="5"
                      cols="33"
                    />
                  </div>
                </div>
                {isFetchingApplied && <LoadingIndicator></LoadingIndicator>}
                {this.props.errorApplied && this.props.errorApplied !== "" && (
                  <div
                    className=" col-lg-12 alert alert-warning alert-dismissible fade show text-center"
                    role="alert"
                  >
                    <strong>{this.props.errorApplied}</strong>
                  </div>
                )}
                {isFetchingApplied === false &&
                  this.state.error &&
                  this.state.error !== "" && (
                    <div
                      className=" col-lg-12 alert alert-warning alert-dismissible fade show text-center"
                      role="alert"
                    >
                      <strong>{this.state.error}</strong>
                    </div>
                  )}
                <div className="modal-footer justify-content-center">
                  {/* <button
                     type="button"
                     className="btn btn-whitelabel-auxiliar col-lg-3"
                     onClick={this.handleCloseClick}
                   >
                     Cancelar
                   </button> */}
                  <button
                    onClick={(e) => this.onSubmitValidate(e)}
                    type="submit"
                    className={!isFetchingApplied ? "btn-register-modal" : "btn-register-modal btn-load"}
                    placeholder="Registrar"
                  >
                    {this.state.textValidateButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            className="modal fade"
            ref={(modal) => (this.modal = modal)}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div onClick={this.handleCloseClick} className="close">
                    <span aria-hidden="true">x</span>
                  </div>
                </div>
                <div className="modal-body ">
                  <div className="col-lg-12">
                    <h5>Canjear promocion</h5>
                    <br />
                    <div className="client-modal">
                      <h4>Cliente: {clientUser.first_name} </h4>
                      <h4>DNI: {dni} </h4>
                    </div>
                    <div
                      className="form-group"
                      ref={(input) => {
                        if (input) {
                          this.amountInput = input.querySelector("input");
                        }
                      }}
                    >
                      <input
                        id="amount"
                        value={this.state.textMount}
                        onChange={this.handleMount}
                        className="form-control col-lg-5 input-search input-amount"
                        type="text"
                        placeholder={`${
                          business &&
                          business.currency &&
                          business.currency.symbol
                        } 0.0`}
                      />
                    </div>
                    <p className="title-discount">Descuento</p>
                    <p className="discount">
                      {" "}
                      {business &&
                        business.currency &&
                        business.currency.symbol}{" "}
                      {this.state.discount}
                    </p>
                    <p className="title-total">Total a Cobrar</p>
                    <p className="total">
                      {" "}
                      {business &&
                        business.currency &&
                        business.currency.symbol}{" "}
                      {this.state.total}
                    </p>
                    {false && (
                      <p className="description">
                        El cliente tiene <span>{offer.available_uses}</span>{" "}
                        descuentos Disponibles
                      </p>
                    )}
                  </div>

                  <div className="col-lg-12 justify-content-center data">
                    <h5>Ingrese un comentario</h5>
                    <div
                      className="form-group"
                      ref={(input) => {
                        if (input) {
                          this.commentsInput = input.querySelector("input");
                        }
                      }}
                    >
                      <input
                        id="comments"
                        value={this.state.comments}
                        onChange={this.handleComments}
                        className="form-control col-lg-5 input-search input-amount"
                        type="text"
                        placeholder={`Comentario`}
                      />
                    </div>
                  </div>
                  <VerifyClientModalMessage client={clientUser} dni={dni} />

                  {isFetchingApplied && <LoadingIndicator></LoadingIndicator>}
                  {this.props.errorApplied && this.props.errorApplied !== "" && (
                    <div
                      className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>{this.props.errorApplied}</strong>
                    </div>
                  )}
                  {isFetchingApplied === false &&
                    this.state.error &&
                    this.state.error !== "" && (
                      <div
                        className=" col-lg-12 alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>{this.state.error}</strong>
                      </div>
                    )}
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-whitelabel-auxiliar col-lg-3"
                    onClick={this.handleCloseClick}
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={(e) => this.onSubmitValidate(e)}
                    type="submit"
                    className={!isFetchingApplied ? "btn btn-whitelabel-secondary col-lg-3" : "btn btn-whitelabel-secondary col-lg-3 btn-load"}
                  >
                    {this.state.textValidateButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div></div>;
  }
}

ModalValidator.propTypes = {
  dispatch: PropTypes.func,
  offer: PropTypes.object,
  dni: PropTypes.string,
  place: PropTypes.object,
  errorApplied: PropTypes.string,
  clientUser: PropTypes.object,
  isFetchingApplied: PropTypes.bool,
  code: PropTypes.number,
  client: PropTypes.object,
  business: PropTypes.object,
};
export default connect(({ promotions, places, client, program, user }) => ({
  offer: promotions.promotion,
  code: promotions.applyCode,
  redemption: promotions.redemption,
  isFetchingApplied: promotions.isFetchingApplied,
  errorApplied: promotions.errorApplied,
  isFetchingAppliedBoc: promotions.isFetchingAppliedBoc,
  errorAppliedBoc: promotions.errorAppliedBoc,
  dni: client.dni,
  clientUser: client.client,
  place: places.place,
  client: program.client,
  business: user.business,
}))(ModalValidator);
