import {BUSINESS_LOGIN, LOGIN, RESET_BUSINESS, RESET_USER} from "../containers/Login/constants";


const initialState = {
   error: null,
   isFetching: false,
   user: null,
   business: null,
   errorBusiness: null,
   isFetchingBusiness: false,
};

export default function reducer(state = initialState, action) {
   switch (action.type) {

      case `${BUSINESS_LOGIN}_PENDING`:
         return {
            ...state,
            isFetchingBusiness: true,
            errorBusiness: null,
         };
      case `${BUSINESS_LOGIN}_REJECTED`:
         return {
            ...state,
            isFetchingBusiness: false,
            business:null,
            errorBusiness: {detail:'Algo salió mal, inténtelo nuevamente'},
         };
      case `${BUSINESS_LOGIN}_FULFILLED`: {
         if (action.payload === undefined) {
            return Object.assign({},
               state, {
                  isFetchingBusiness: false,
                  business: null,
                  errorBusiness: {detail:'Algo salió mal, inténtelo nuevamente'}
               },
            );
         } else if (action.payload.status === 200 || action.payload.status === 201) {
            window.localStorage.setItem('companyIdRedeem', action.payload.data);
            return Object.assign({},
               state, {
                  isFetchingBusiness: false,
                  business: action.payload.data,
                  errorBusiness: null,
               },
            );
         } else if (action.payload.data !== undefined && action.payload.data !== null) {
            let errorObject = {}

            if(action.payload.code === 404){
               errorObject.detail = 'Negocio no encontrado';
            }else{
               try{
                  let error = '';

                  for (let key of Object.keys(action.payload.data)) {
                     if(typeof action.payload.data[key] == 'string'){
                        if(error.length>0)
                           error += ', ';
                        error += `${action.payload.data[key]}`;
                     }else{
                        for ( let j = 0; j <action.payload.data[key].length; j++){
                           if(error.length>0)
                              error += ', ';
                           error += `${action.payload.data[key][j]}`;
                        }
                     }

                  }
                  if(error.length>0)
                     errorObject.detail = error;
                  else
                     errorObject.detail = 'Negocio no encontrado';
               }catch (e) {
                  errorObject.detail = 'Negocio no encontrado';
               }

               return Object.assign({},
                  state, {
                     isFetchingBusiness: false,
                     business: null,
                     errorBusiness:errorObject,
                  },
               );
            }

         }

         return Object.assign({},
            state, {
               isFetchingBusiness: false,
               business: null,
               errorBusiness: {detail:'Algo salió mal, inténtelo nuevamente'}
            },
         );
      }
      case `${LOGIN}_PENDING`:
         return {
            ...state,
            isFetching: true,
            error: null,
         };
      case `${LOGIN}_REJECTED`:
         return {
            ...state,
            isFetching: false,
            error: {detail:'Algo salió mal, inténtelo nuevamente'},
         };
      case `${LOGIN}_FULFILLED`: {
         if (action.payload === undefined) {
            return Object.assign({},
               state, {
                  isFetching: false,
                  user: null,
                  error: {detail:'Algo salió mal, inténtelo nuevamente'}
               },
            );
         } else if (action.payload.status === 200 || action.payload.status === 201) {
            return Object.assign({},
               state, {
                  isFetching: false,
                  user: action.payload.data,
                  error: null,
               },
            );
         } else if (action.payload.data !== undefined && action.payload.data !== null) {
            let errorObject = {}
            for (let key of Object.keys(action.payload.data)) {
               let error = ''
               if(typeof action.payload.data[key] == 'string'){
                  if(error.length>0)
                     error += ', ';
                  error += `${action.payload.data[key]}`;
               }else{
                  for ( let j = 0; j <action.payload.data[key].length; j++){
                     if(error.length>0)
                        error += ', ';
                     error += `${action.payload.data[key][j]}`;
                  }
               }
               errorObject[`${key}`] = error
            }


            return Object.assign({},
               state, {
                  isFetching: false,
                  user: null,
                  error:errorObject,
               },
            );
         }

         return Object.assign({},
            state, {
               isFetching: false,
               user: null,
               error: {detail:'Algo salió mal, inténtelo nuevamente'}
            },
         );
      }

      case `${RESET_BUSINESS}`: {
         return {
            isFetchingBusiness: false,
            business: null,
            errorBusiness: null,
         };
      }
      case `${RESET_USER}`: {
         return {
            isFetching: false,
            user: null,
            error: null,
         };
      }
      default:
         return state;
   }
}
