/* eslint-disable prefer-destructuring */

export default class DateUtils {
  static getFormatDate(date) {
    const currentDatetime = new Date(date)
    const minutes = currentDatetime.getMinutes() <= 9 ? `0${currentDatetime.getMinutes()}` : `${currentDatetime.getMinutes()}`;
    const days = (currentDatetime.getDate()) <= 9 ? `0${currentDatetime.getDate()}` : `${currentDatetime.getDate()}`;
    const month = (currentDatetime.getMonth() + 1) <= 9 ? `0${currentDatetime.getMonth() + 1}` : `${currentDatetime.getMonth() + 1}`;
    return `${days}/${month}/${currentDatetime.getFullYear()} a las ${currentDatetime.getHours()}:${minutes}`;
  }
}
