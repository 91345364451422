import {CLEAN_PROGRAM, SEARCH_PROGRAM, SET_CLIENT_PROGRAM, USER_PROGRAM} from '../containers/Promotions/constants';
import {GET_PROGRAMS} from '../containers/Bederr/constants';
import {SET_DATA_LOCAL} from "../containers/MainMenu/constants";


const initialState = {
  data: null,
  userProgram: null,
  error: null,
  isFetching: false,
  client: null,
  dataLocal: null,
  dataLocalArray: null,
  isFetchingLocal: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${SEARCH_PROGRAM}_PENDING`:
      return {...state, isFetching: true, data: null};
    case `${SEARCH_PROGRAM}_REJECTED`:
      return {...state, isFetching: false, error: action.payload};
    case `${SEARCH_PROGRAM}_FULFILLED`: {

      let newData = null;
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data !== null && action.payload.data.length > 0) {
          if (state.dataLocal !== undefined && state.dataLocal !== null) {
            const arrayData = action.payload.data;
            for (let i = 0; i < arrayData.length; i++) {
              if (state.dataLocal.uid === arrayData[i].uid) {
                newData = arrayData[i];
              }
            }
          }
        }
      }
      return Object.assign({},
        state, {
          isFetching: false,
          data: newData,
          error: null,
        }
      );
    }
    case `${GET_PROGRAMS}_PENDING`:
      return {...state, isFetchingLocal: true, data: null};
    case `${GET_PROGRAMS}_REJECTED`:
      return {...state, isFetchingLocal: false, error: action.payload};
    case `${GET_PROGRAMS}_FULFILLED`: {

      let newData = null;
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined && action.payload.data !== null && action.payload.data.length > 0) {
          newData = action.payload.data;
        }
      }
      return Object.assign({},
        state, {
          isFetchingLocal: false,
          dataLocalArray: newData,
          error: null,
        }
      );
    }
    case `${CLEAN_PROGRAM}`:
      return Object.assign({},
        state, {
          isFetching: false,
          data: null,
          error: null,
          client: null
        }
      );
    case `${USER_PROGRAM}`:
      return Object.assign({},
        state, {
          userProgram: action.program
        }
      );
    case `${SET_CLIENT_PROGRAM}`:
      return Object.assign({},
        state, {
          client: action.client
        }
      );
    case `${SET_DATA_LOCAL}`:

      return Object.assign({},
        state, {
          dataLocal: action.dataLocal
        }
      );

    default:
      return state;
  }
}
