import {CLEAN_ORDER_STATUS, CLEAN_ORDERS, ORDER_DETAIL, ORDERS, PATCH_ORDER} from "../containers/Orders/constants";
import {ERROR_STATUS, LOADING_STATUS, SUCCESS_STATUS} from "../containers/Bederr/constants";
import {
   SET_CURRENT_PLACE_UID_ORDERS,
   SET_FIRST_PAGE_ORDERS,
   SET_NUMBER_PAGE_ORDERS
} from "../containers/Orders/constants";

import {normalize, schema} from "normalizr";

const ordersSchema = new schema.Entity('orders', {}, {idAttribute: 'uid'});
const ordersListSchema = new schema.Array(ordersSchema);

const initialState = {
   data: null,
   next: null,
   previous: null,
   isFetching: false,
   retrieveStatus: '',
   editStatus: '',
   formInitial: null,
   count: 0,
   error: null,
   exportFile: null,
   errorExportFile: null,
   isFetchingFile: false
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case SET_CURRENT_PLACE_UID_ORDERS:
         return {
            ...state,
            currentPlaceUid: action.uid
         }
      case SET_FIRST_PAGE_ORDERS:
         return {
            ...state,
            firstList: action.list
         }
      case SET_NUMBER_PAGE_ORDERS:
         console.log(action.currentPageNumber)
         return {
            ...state,
            currentPageNumber: action.currentPageNumber
         }
      case `${ORDERS}_PENDING`:
         return {...state, isFetching: true, next: null};
      case `${ORDERS}_REJECTED`:
         return {...state, isFetching: false, error: action.payload};
      case `${ORDERS}_FULFILLED`: {
         let newData = [];
         let data = [];
         if (action.payload !== undefined) {
            if (action.payload.data !== undefined && action.payload.data.results !== []) {
               newData = action.payload.data.results;
               data = normalize(action.payload.data.results, ordersListSchema)
            }
         }
         return Object.assign({},
            state, {
               isFetching: false,
               data: newData,
               entities: data.entities ? data.entities.orders : [],
               results: data.result ? data.result : [],
               next: action.payload.data.next,
               previous: action.payload.data.previous,
               count: action.payload.data.count
            }
         );
      }

      case `${ORDER_DETAIL}_PENDING`:
         return {
            ...state,
            formInitial: null,
            retrieveStatus:  LOADING_STATUS
         };
      case `${PATCH_ORDER}_PENDING`:
         return {
            ...state,
            editStatus:  LOADING_STATUS
         };
      case `${ORDER_DETAIL}_FULFILLED`:
         let data = action.payload && action.payload.status === 200 ? action.payload.data : null;
         return {
            ...state,
            retrieveStatus: action.payload && action.payload.status === 200? SUCCESS_STATUS : ERROR_STATUS,
            formInitial: data
         };
      case `${PATCH_ORDER}_FULFILLED`:
         return {
            ...state,
            editStatus: action.payload.status === 200? SUCCESS_STATUS : ERROR_STATUS,
         };
      case `${CLEAN_ORDERS}`:
         return Object.assign({},
            state, {
               isFetching: false,
               data: null,
               next: null,
               previous: null,
            }
         );
      case `${CLEAN_ORDER_STATUS}`:
         return Object.assign({},
            state, {
               editStatus: '',
               retrieveStatus: '',
            }
         );

      default:
         return state;
   }
}
