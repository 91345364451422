import React, {Component} from 'react';
import './style.scss';


export default class StagingLabel extends Component {

  render() {
    return (
      <div className={"staging-label"}>
        <div className="ribbon-content">
          <div className="ribbon base"><span>TEST</span></div>
        </div>
      </div>
    )
  }
}