import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {SingleDatePicker} from 'react-dates';
import PropTypes from 'prop-types';
import {cleanExport, exportHistory, getHistory} from './actions';
import Switch from "react-switch";

class SearchHistory extends Component {
   constructor(props) {
      super(props);
      this.state = {
         date: null,
         focused: null,
         error: '',
         place: null,
         placeUid: '',
         all_places: false
      };
   }
   componentDidMount() {
      const {currentPlaceUid}= this.props
      this.setState({placeUid: currentPlaceUid })
      this.setState({date: null})
      this.props.setDate(null)
   }

   componentWillReceiveProps(nextProps, nextContext) {
      const {exportFile, place} = nextProps;
      if (exportFile !== null && exportFile !== '') {
         window.open(exportFile);
         this.props.dispatch(cleanExport());
      }
      if (place !== null) {
         if (this.state.place == null) {
            this.setPlace(place);
         }
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      let {business, currentPlaceUid} = this.props
      if(this.state.all_places && this.state.placeUid !== ''){
         this.setState({placeUid: ''})
         this.props.dispatch(getHistory(business.uid, null, this.state.date));
      }
      if(!this.state.all_places  && this.state.placeUid!==currentPlaceUid){
         this.setState({placeUid: currentPlaceUid})
         this.props.dispatch(getHistory(business.uid, currentPlaceUid, this.state.date));
      }
   }

   setPlace(place) {
      this.setState({place});
   }

   showError() {
      const {error} = this.props;
      if (error !== '' || this.state.error !== '') return <div className="col-lg-12"><p
         className="field-error-message">{error} {this.state.error}</p></div>;
   }

   handleSearch = async (event) => {
      event.preventDefault();
      let {business} = this.props
      if (!this.props.isFetching) {
         this.props.dispatch(getHistory(business.uid, this.state.placeUid, this.state.date));
      }
   };

   handleExport(event) {
      event.preventDefault();
      //let {place} = this.props
      if (!this.props.isFetching) {
         this.props.dispatch(exportHistory(this.state.placeUid, this.state.date));
      }
   }

   handleDateChange(date) {
      this.setState({date});
      this.props.setDate(date)
   }
   validateDate() {
      let dateSelect = this.state.date
      if(dateSelect){
         let aux = dateSelect._d
         if((aux.getFullYear()!==null || aux.getFullYear()!=="") ||
             (aux.getMonth()!==null || aux.getMonth()!=="") ||
             (aux.getDate()!==null || aux.getDate()!=="")){
            return true
         }
      }
      return false
   }

   render() {
      const {isFetching, isFetchingFile} = this.props;
      console.log(this.state.date, "this.state.date")
      return (
         <div>
            <div className="container">
               <div className="row search-section">
                  <form
                     className="col-lg-12 "
                     onSubmit={this.handleSearch}
                  >
                     <div className="row align-content-center">
                        <div className={"col-lg-4"} style={{marginBottom: 5}}>
                           <SingleDatePicker
                              // showClearDate={true}
                              showDefaultInputIcon
                              small
                              block
                              placeholder={'Todas las fechas'}
                              numberOfMonths={1}
                              date={this.state.date}
                              onDateChange={(date) => this.handleDateChange(date)}
                              focused={this.state.focused}
                              onFocusChange={({focused}) =>
                                 this.setState({focused})
                              }
                              isOutsideRange={() => false}
                              openDirection="down"
                              hideKeyboardShortcutsPanel
                              displayFormat="DD/MM/YYYY"
                           />
                           <div className="form-check">
                              <input type="checkbox"  checked={this.state.date===null} className="form-check-input" onChange={e=>{
                                 if(e.target.value){
                                    this.setState({date:null})
                                    this.props.setDate(null)
                                    this.props.setPageCurrent(1)
                                 }
                              }} />
                              <label className="form-check-label" >Todas las fechas</label>
                           </div>
                        </div>
                        <div className="col-lg-5" style={{marginBottom: 5}}>
                           <div className="checkbox d-flex flex-row align-items-center mt-2 mb-2">
                              <label htmlFor="places" className="form-check-label mr-3" style={{marginLeft: 25}}>
                                 Todos los lugares
                              </label>
                              <Switch id="places" onChange={(checked) => {
                                 this.setState({all_places: !this.state.all_places})
                                 this.props.setAllPlaces(!this.state.all_places)
                              }} checked={this.state.all_places}/>
                           </div>
                        </div>
                        <div className="col-lg-3">
                           <button
                              type="submit"
                              className="btn btn-whitelabel-secondary btn-lg btn-block"
                           >
                              {
                                 isFetching && 'Buscando'
                              }
                              {
                                 isFetching === false && 'Buscar'
                              }
                           </button>
                        </div>
                     </div>
                  </form>
                  {
                     this.showError()
                  }
               </div>
            </div>
            <div className="line"></div>
            <div className="container">
               <div className="row">

                     <div className="col-lg-12" style={{paddingTop: 10, paddingBottom: 10}}>
                            <p style={{textAlign: "right"}}>Escoger una fecha y solo escoger un local para poder descargar</p>
                        {
                           this.validateDate() && !this.state.all_places && this.props.count<=50 &&
                           <button
                               onClick={(e) => this.handleExport(e)}
                               className="btn btn-whitelabel-auxiliar btn-sm float-right"
                           >
                              {
                                 isFetchingFile && 'Cargando...'
                              }
                              {
                                 isFetchingFile === false && 'Descargar Registros'
                              }
                           </button>
                        }

                     </div>

               </div>
            </div>
         </div>
      );
   }
}

SearchHistory.propTypes = {
   dispatch: PropTypes.func,
   isFetching: PropTypes.bool,
   isFetchingFile: PropTypes.bool,
   places: PropTypes.array,
   error: PropTypes.string,
   business: PropTypes.object,
   exportFile: PropTypes.string,
};
SearchHistory.defaultProps = {
   isFetching: false,
   places: null,
   business: null,
   error: '',
   exportFile: null
};
export default connect(({places, history, user}) => ({
   places: places.data,
   business: user.business,
   isFetching: history.isFetching,
   isFetchingFile: history.isFetchingFile,
   exportFile: history.exportFile,
   currentPlaceUid: places.currentPlaceUid,
   place: places.place,
}))(SearchHistory);
