import React, { Component } from "react";
import { Fragment } from "react";

export default class Stamps extends Component {
  render() {
    const { promotion, imageIcon } = this.props;
    //console.log(this.props);
    let stamps = [];
    let required_stamps = promotion.promotion.required_stamps
      ? promotion.promotion.required_stamps
      : 0;
    if (required_stamps > 0) {
      for (let i = 0; i < required_stamps; i++) {
        stamps.push(promotion.available_stamps > i);
      }
    }
    //console.log(stamps);
    return (
      <Fragment>
        {imageIcon && (
          <div
            className={
              required_stamps < 7
                ? "logo-promotions logo-promotions-stamps"
                : "logo-promotions"
            }
          >
            <h6>{promotion.program.name} </h6>
            <img src={promotion.program?.company_logo} alt="logo-promotions" />
          </div>
        )}

        <div className="discount-box col-12 col-lg-9">
          {required_stamps > 0 && required_stamps < 7 && (
            <div className="stamps-container">
              <div
                className="stamps-box"
                style={{
                  background: `${
                    promotion.color.includes("#")
                      ? promotion.color
                      : `#${promotion.color}`
                  }`,
                }}
              >
                <div className="row stamps-box_1 p-0">
                  {stamps.map(function (item, i) {
                    return (
                      <div
                        className={item ? "stamps-use" : {}}
                        style={
                          item
                            ? {
                                background: `${
                                  promotion.color.includes("#")
                                    ? promotion.color
                                    : `#${promotion.color}`
                                }`,
                              }
                            : {}
                        }
                        key={`stamp${promotion.promotion.uid}${i}`}
                      >
                        {i + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row stamps-data-box">
                <div className="col stamps-data">
                  <h4 className="title">{promotion?.promotion.title} </h4>
                  <p className="subtitle">{promotion?.promotion.content}</p>
                </div>
                <div
                  className="stamps-data-color"
                  style={{
                    background: `${
                      promotion.color.includes("#")
                        ? promotion.color
                        : `#${promotion.color}`
                    }`,
                  }}
                ></div>
              </div>
            </div>
          )}
          {required_stamps > 6 && required_stamps < 11 && (
            <div className="stamps-container-2">
              <div
                className="stamps-box"
                style={{
                  background: `${
                    promotion.color.includes("#")
                      ? promotion.color
                      : `#${promotion.color}`
                  }`,
                }}
              >
                <div className= {imageIcon ? "row stamps-box-2 p-0" : "row stamps-box-2 stamps-box-2-modal p-0"}>
                  {stamps.map(function (item, i) {
                    return (
                      <div
                        className={item ? "stamps-use" : {}}
                        style={
                          item
                            ? {
                                background: `${
                                  promotion.color.includes("#")
                                    ? promotion.color
                                    : `#${promotion.color}`
                                }`,
                              }
                            : {}
                        }
                        key={`stamp${promotion.promotion.uid}${i}`}
                      >
                        {i + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row stamps-data-box">
                <div className="col stamps-data">
                  <h4 className="title">{promotion?.promotion.title} </h4>
                  <p className="subtitle">{promotion?.promotion.content}</p>
                </div>
                <div
                  className="stamps-data-color"
                  style={{
                    background: `${
                      promotion.color.includes("#")
                        ? promotion.color
                        : `#${promotion.color}`
                    }`,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
