import * as React from "react"

const Arrow = () => (
  <svg
    width={10}
    height={7}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5 7 5-7H0l5 7Z"
      fill="#040404"
    />
  </svg>
)

export default Arrow
