/* eslint-disable react/sort-comp */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../images/logo.png";
import { cleanBusiness, loginUser, searchBusiness } from "./actions";
import { Whitelabel } from "../../utils/generic";
import Validator from "../../utils/validator";
import { BTN_LOGIN, BTN_LOGIN_LOADING } from "./constants";
import "./style.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      business: "",
      apiKey: "",
      password: "",
      error: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(cleanBusiness());
    window.localStorage.setItem(`apiKey`, "");
    this.validator = new Validator([
      {
        id: "email",
        ref: this.emailInput,
        rules: ["required", "email"],
      },
      {
        id: "password",
        ref: this.passwordInput,
        rules: ["required"],
      },
    ]);
  }

  handlePassword = (event) =>
    this.setFormValues({ password: event.target.value });
  handleEmail = (event) => this.setFormValues({ email: event.target.value });

  handleBusiness = (event) => {
    this.props.dispatch(cleanBusiness());
    this.setFormValues({ business: event.target.value });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { business, user } = nextProps;
    if (business != null) {
      if (this.state.apiKey === "") {
        this.setState({ apiKey: business.api_key });
        window.localStorage.setItem(`apiKey`, business.api_key);
        window.localStorage.setItem(`subdomain`, business.domain.subdomain);
        window.localStorage.setItem(`companyUid`, business.uid);
        this.loginUser();
      }
    } else {
      if (this.state.apiKey !== "") {
        this.setState({ apiKey: "" });
        window.localStorage.setItem(`apiKey`, "");
        window.localStorage.setItem(`subdomain`, "");
        window.localStorage.setItem(`companyUid`, "");
      }
    }
    if (user != null) {
      this.openSession(user);
    }
  }

  openSession = (user) => {
    const { token } = user;
    const NAME = Whitelabel.name;
    window.localStorage.setItem(`token${NAME}`, token);
    setTimeout(() => {
      document.location.href = "/";
      return true;
    }, 1000);
  };

  loginUser = () => {
    this.props.dispatch(
      loginUser({
        email: this.state.email,
        password: this.state.password,
      })
    );
  };

  onSubmitForm = async (event) => {
    event.preventDefault();
    let { isFetchingBusiness } = this.props;
    if (
      !isFetchingBusiness &&
      this.validator.validate({
        business: this.state.business,
        email: this.state.email,
        password: this.state.password,
      })
    ) {
      this.setState({ apiKey: "" });
      this.props.dispatch(searchBusiness(this.state.business));
    }
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState({ error: "" });
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  };

  render() {
    let { isFetchingBusiness, errorBusiness, isFetching, error } = this.props;
    return (
      <div className="container-login">
        <div className="box-login">
          <div className="form-login">
            <div className="hello">
              <img src={logo} className="logo" alt="logo" />
              <h2>Validador de beneficios</h2>
            </div>
            <form className="form" onSubmit={this.onSubmitForm}>
              <div>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.businessInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className="form-control bussines"
                    id="business"
                    type="string"
                    placeholder="empresa"
                    value={this.state.business}
                    onChange={this.handleBusiness}
                    disabled={isFetchingBusiness || isFetching}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">.bederr.com</span>
                  </div>
                </div>
                {errorBusiness && (
                  <span className="field-error-message">
                    {errorBusiness.detail === "No encontrado."
                      ? errorBusiness.detail
                      : "No valido"}
                  </span>
                )}
              </div>
              <div>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.emailInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className="form-control"
                    id="username"
                    type="email"
                    placeholder="Correo Electrónico*"
                    value={this.state.username}
                    onChange={this.handleEmail}
                    disabled={isFetchingBusiness || isFetching}
                  />
                </div>
                {error != null && error.email != null && (
                  <span className="field-error-message">{error.email}</span>
                )}
              </div>

              <div>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.passwordInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="Contraseña*"
                    value={this.state.password}
                    onChange={this.handlePassword}
                    disabled={isFetchingBusiness || isFetching}
                  />
                </div>
                {error != null && error.password != null && (
                  <span className="field-error-message">{error.password}</span>
                )}
              </div>
              {error != null && error.non_field_errors != null && (
                <div
                  className="error-login-alert"
                  role="alert"
                >
                  <strong>{error.non_field_errors}</strong>
                </div>
              )}

              <div className="btn-login">
                <button type="submit">
                  {isFetchingBusiness || isFetching
                    ? BTN_LOGIN_LOADING
                    : BTN_LOGIN}
                </button>
              </div>
            </form>
          </div>
          <div className="publicity"></div>
        </div>
        <div className="footer-login">
          <h2>© 2022 Powered by Bederr</h2>
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  business: null,
  errorBusiness: null,
  isFetchingBusiness: false,
  user: null,
  error: null,
  isFetching: false,
};

Login.propTypes = {
  dispatch: PropTypes.func,
  business: PropTypes.object,
  errorBusiness: PropTypes.object,
  isFetchingBusiness: PropTypes.bool,
  user: PropTypes.object,
  error: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default connect(({ user }) => ({
  business: user.business,
  errorBusiness: user.errorBusiness,
  isFetchingBusiness: user.isFetchingBusiness,
  user: user.user,
  error: user.error,
  isFetching: user.isFetching,
}))(Login);
