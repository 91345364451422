import {APPLIED_POINTS, CLEAN_APPLIED, SELECT_CLIENT} from '../containers/Promotions/constants';

const initialState = {
  data: null,
  isFetching: false,
  error: null,
  client: null,
  errorApplied: null,
  isFetchingApplied: false,
  applyCode: 0,
  report: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${APPLIED_POINTS}_PENDING`:
      return { ...state, isFetchingApplied: true, applyCode: 0 };
    case `${APPLIED_POINTS}_REJECTED`:
      return { ...state, isFetchingApplied: false, errorApplied: 'No se pudo completar la solicitud' };
    case `${APPLIED_POINTS}_FULFILLED`: {
      if (action.payload !== undefined) {
        if (action.payload.data !== undefined) {
          if (action.payload.status !== undefined
            && action.payload.status !== null
            && (action.payload.status === 200 || action.payload.status === 201)) {
            return Object.assign({},
              state, {
                isFetchingApplied: false,
                applyCode: 200,
                errorApplied: '',
                message: 'Se agregaron los puntos correctamente'
              }
            );
          }
          return Object.assign({},
            state, {
              isFetchingApplied: false,
              applyCode: 400,
              errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
            }
          );
        }
        return Object.assign({},
          state, {
            isFetchingApplied: false,
            applyCode: 400,
            errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
          }
        );
      }
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          applyCode: 400,
          errorApplied: 'Algo sucedió mal. Por favor intentarlo nuevamente'
        }
      );
    }
    case `${SELECT_CLIENT}`:
      return Object.assign({},
        state, {
          client: action.client,
          errorApplied: '',
          applyCode: 0
        }
      );

    case `${CLEAN_APPLIED}`:
      return Object.assign({},
        state, {
          isFetchingApplied: false,
          applyCode: 0,
          errorApplied: ''
        }
      );
    default:
      return state;
  }
}
