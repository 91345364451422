import React, {Fragment} from 'react';
import {Provider} from 'react-redux';
import {Redirect, Router, Switch} from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import {applyMiddleware, createStore} from 'redux';
import reducer from "./reducersapp";
import promise from 'redux-promise-middleware';

import 'jquery';
import 'bootstrap/dist/js/bootstrap';
import './styles/theme.scss';
import history from "./utils/history";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import Login from "./containers/Login/Login";
import Bederr from "./containers/Bederr/Bederr";
import AuthorizedRoute from "./components/AuthorizedRoute/AuthorizedRoute";
import StagingLabel from "./components/StagingLabel/StagingLabel";


const middlewares =
   [];

const store = createStore(
   reducer,
   applyMiddleware(promise, thunkMiddleware, ...middlewares),
);


function App() {
    let maintenance_mode = false

   return (
       <Fragment>
          {
             process.env.REACT_APP_URL_REST && process.env.REACT_APP_URL_REST==="https://api.bemaven.xyz/" &&
             <StagingLabel/>
          }
           {
               maintenance_mode ?
                   <div className="maintenance_mode">
                       <div>
                           <h1>¡Estamos en mantenimiento!</h1>
                           <p>Lo sentimos, el 5 y 6 de noviembre estaremos en mantenimiento.</p>
                       </div>
                   </div>
                :
                   <Provider store={store}>
                       <Router history={history}>
                           <Switch>
                               <AuthRoute exact path="/login" component={Login} />
                               <AuthorizedRoute path="/" component={Bederr}/>
                               <Redirect to="login" />
                           </Switch>
                       </Router>
                   </Provider>
           }
       </Fragment>
   );
}

export default App;
