import {combineReducers} from 'redux';
import places from './places';
import promotions from './promotions';
import client from './client';
import history from './history';
import orders from './orders';
import menu from './menu';
import program from './program';
import points from './points';
import user from './user';
import promotionTransferred from './promotionTransferred';

export default combineReducers({
  places,
  history,
  promotions,
  client,
  menu,
  program,
  points,
  user,
  orders,
  promotionTransferred
});
