import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  getPlaces, getNumberPagePlaces, selectPlace,
  setNumberPage, setFirstList, setCompanyUidCurrentPlaces, setSearchTermCurrentPlaces
} from './actions';
import {changeRootMenu} from '../Bederr/actions';
import SearchIcon from "../../components/Icons/SearchIcon";
import PlaceTable from "./PlaceTable";
import "./style.scss";


class ListPlaces extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      typing: false,
      typingTimeout: 0,
      pageCurrent: 1,
      firstList: ""
    };
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(changeRootMenu('VALIDADOR'));
    const {dispatch, searchTerm, currentPageNumber, firstList, places, listPlacesStatus} = this.props;

    if(searchTerm && this.state.searchTerm !== searchTerm){
      this.setState({searchTerm: searchTerm})
    }

    let aux = []
    if(listPlacesStatus==="success"){
      places.map(c=>{
        aux.push(c.uid)
        return true
      })
    }

    if(listPlacesStatus===null){
      dispatch(getPlaces([{search: ""}]));
      dispatch(setNumberPage(1))
    }

    if(listPlacesStatus==="success" && firstList!==JSON.stringify(aux) && currentPageNumber===1 && firstList.length!==0){
      dispatch(getPlaces([{search: ""}]));
      dispatch(setNumberPage(1))
    }

  }

  componentDidUpdate() {
    const {dispatch, firstList, places, currentPageNumber, listPlacesStatus, currentCompanyUid} = this.props

    let currentCompanyUidPlaces = localStorage.getItem("companyUid") ? localStorage.getItem("companyUid") : ''

    if(this.state.pageCurrent !== currentPageNumber){
      this.setState({pageCurrent: currentPageNumber})
    }


    if(currentCompanyUidPlaces!==currentCompanyUid){
      dispatch(setNumberPage(1))
      dispatch(setCompanyUidCurrentPlaces(currentCompanyUidPlaces))
    }

    let aux = []
    if(listPlacesStatus==="success"){
      places.map(c=>{
        aux.push(c.uid)
        return true
      })
    }

    if(listPlacesStatus==="success" && firstList!==JSON.stringify(aux) && currentPageNumber===1){
      this.setState({firstList: JSON.stringify(aux)})
      dispatch(setFirstList(JSON.stringify(aux)))
      dispatch(setNumberPage(1))
    }
  }

  search = event => {
    const {dispatch} = this.props
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    let aux = event.target.value
    self.setState({
      searchTerm: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
          dispatch(getPlaces([{search: aux}]));
          dispatch(setSearchTermCurrentPlaces(aux))
          dispatch(setNumberPage(1))
      }, 1000),
      pageCurrent: 1
    });
  };

  render() {

    const {
      count, history,
      nextPage, previousPage,
      listStatus,
      results,
      entities,
      currentPlaceUid
    } = this.props;
    const {searchTerm} = this.state;

    return (
        <div className="row justify-content-center">
          <main className={'col-md-10 col-sm-12 col-12'}>
            <div className={"header-fixed col-12 mt-4"}>
              <h2 className={"title"}>Escoja un local</h2>
              <div className="content-body">
                <div className='row'>
                  <div className="col-12 d-flex justify-content-between">
                    <div className="flex-nowrap col-6 col-sm-5 pl-0">
                      <div className="d-flex controls">
                        <span className={"m-0 p-0 "}>
                          <SearchIcon  className={"ml-0 mr-3"}/>
                        </span>
                            <input type="text" className="form-control" placeholder="Buscar" aria-label="Buscar"
                                   value={searchTerm} onChange={e => this.search(e)}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                    <PlaceTable
                        nextPage={nextPage}
                        previousPage={previousPage}
                        listPlacesStatus={listStatus}
                        getNumberPagePlaces={uid => this.props.dispatch(getNumberPagePlaces(uid, [{search: searchTerm}]))}
                        selectPlace={(uid, address)=> this.props.dispatch(selectPlace(uid, address))}
                        currentPlaceUid={currentPlaceUid}
                        places={results && entities && results.map(pUid => entities[pUid])}
                        count={count}
                        history={history}
                        pageCurrent={this.state.pageCurrent}
                        setPageCurrent={pageCurrent=> {
                          this.setState({pageCurrent: pageCurrent})
                          this.props.dispatch(setNumberPage(pageCurrent))
                        }}
                    />
                </div>
              </div>
            </div>
          </main>
        </div>
    );
  }
}
ListPlaces.propTypes = {
  dispatch: PropTypes.func,
  message: PropTypes.string,
  history: PropTypes.object,
};

export default connect(({places}) => ({
  nextPage: places.nextPage,
  previousPage: places.previousPage,
  listStatus: places.listStatus,
  results: places.results,
  entities: places.entities,
  count: places.count,
  currentPlaceUid: places.currentPlaceUid,
  currentPageNumber: places.currentPageNumber,
  currentCompanyUid: places.currentCompanyUid,
  firstList: places.firstList,
  searchTerm: places.searchTerm,
}))(ListPlaces);
