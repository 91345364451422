import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PlaceItem from "./PlaceItem";
import PropTypes from "prop-types";

class PlaceTable extends Component {

  previous(){
    const {getNumberPagePlaces, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent-1)
    getNumberPagePlaces(pageCurrent-1)
  }

  next(){
    const {getNumberPagePlaces, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent+1)
    getNumberPagePlaces(pageCurrent+1)
  }

  renderPlaces() {
    const {places, listPlacesStatus, count, currentPlaceUid, selectPlace} = this.props;

    if (count === 0 && listPlacesStatus === "loading") {
      return <Fragment>
        <PlaceItem loading={true}/>
      </Fragment>;
    }
    if (count === 0 && listPlacesStatus === "success") {
      return <Fragment>
        <PlaceItem noItems={true}/>
      </Fragment>;
    }

    return places && places.map((place, index) => (
      <PlaceItem key={`place${index}`} count={count} place={place}
                 selectPlace={selectPlace}
                 currentPlaceUid={currentPlaceUid}
                 loading={false}
      />
    ));
  }

  renderPagination() {
    const {count, getNumberPagePlaces, pageCurrent, setPageCurrent} = this.props
    let nPage = Math.ceil(count/25)
    let pages = [];

    for (let i = 1; i < nPage+1; i++) {

      if(nPage+1>10){

        if(pageCurrent===i){
          if(pageCurrent===1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===3){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===4){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===5){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push(7)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent>5 && pageCurrent<nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
          }
          if(pageCurrent===nPage-1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
          }
          if(pageCurrent===nPage){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
          }
        }

      }else{
        pages.push(i)
      }
    }

    for(let i = pages.length -1; i >=0; i--){
      if(pages.indexOf(pages[i]) !== i){
        if(pages[i]!=="..."){
          pages.splice(i,1);
        }
      }
    }

    return pages.map((page, index) => (
        <li key={`page${index}`}><a href={"/#"}
         className={page===pageCurrent ? "active" : ""}
         onClick={(e)=>{
           e.preventDefault()
           if(page!==pageCurrent && page!=="..."){
             setPageCurrent(page)
             getNumberPagePlaces(page)
           }
         }}
      >{page}</a></li>
    ));

  }


  render() {
    const {previousPage, nextPage, pageCurrent, count} = this.props
    return (
      <Fragment>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Lista de locales</h4>
              <div className="heading-elements">
                <ul className="list-inline mb-0">
                  <li><a href={"/#"} data-action="collapse" onClick={e => e.preventDefault()}><i className="ft-minus"></i></a></li>
                  <li><a href={"/#"} data-action="reload" onClick={e => e.preventDefault()}><i className="ft-rotate-cw"></i></a></li>
                  <li><a href={"/#"} data-action="expand" onClick={e => e.preventDefault()}><i className="ft-maximize"></i></a></li>
                  <li><a href={"/#"} data-action="close" onClick={e => e.preventDefault()}><i className="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="card-content collapse show">
              <div className="card-body">
                <span className='float-left pt-1 pb-3' style={{fontWeight:"bold"}}>Resultados {count}</span>
                <span className='float-right pt-1 pb-3' style={{fontWeight:"bold"}}>Página {pageCurrent}</span>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>NOMBRE</th>
                    <th style={{textAlign:'center'}}>DIRECCIÓN</th>
                    <th style={{textAlign:'center'}}>DISTRITO</th>
                    <th style={{textAlign:'center'}}>SELECCIONAR</th>
                  </tr>
                  </thead>
                  <tbody>
                    {this.renderPlaces()}
                  </tbody>
                </table>
              </div>
              <div className={"d-flex justify-content-center mt-1 mb-1"}>
                <ul className="pagination">
                  <li>
                    <button className={previousPage===null ? "is-disabled": ""} disabled={previousPage===null} onClick={()=>this.previous()}> {"<"} </button>
                  </li>
                  {this.renderPagination()}
                  <li>
                    <button className={nextPage===null ? "is-disabled": ""} disabled={nextPage===null} onClick={()=>this.next()}> {">"} </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

PlaceTable.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  places: PropTypes.array,
  next: PropTypes.string,
  previous: PropTypes.string,
  count: PropTypes.number
};
PlaceTable.defaultProps = {
  places: [],
  isFetching: false,
  next: null,
  previous: null,
  count: 0
};
export default connect(({places}) => ({
}))(PlaceTable);
