import React from 'react';
import './style.scss';

const VerifyClientSteps = (props) => (
  <div className="col-lg-12 justify-content-center verify-client-steps">
    <h5>Paso 1: {props.typeText==="RUC" ? props.typeText.toUpperCase(): props.typeText }</h5>
    <p>Solicite el {props.typeText==="RUC" ? props.typeText.toUpperCase(): props.typeText } del cliente antes de hacer la búsqueda para verificar su identidad</p>
    <h5>Paso 2: Búsqueda</h5>
    <p>Con el {props.typeText==="RUC" ? props.typeText.toUpperCase(): props.typeText }{props.typeText==="Documento de Identidad" && " físico en la mano"}, ingrese en la barra de arriba el {props.typeText} para comenzar la búsqueda</p>
  </div>
);

export default VerifyClientSteps;
